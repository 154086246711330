import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const refundIdFilterModel = new FilterModel({
  id: 11,
  type: "text",
  title: t("refundIdFilterModel.title"),
  name: "REFUND_ID",
  options: {
    maxLength: 36,
  },
  langPath: "refundIdFilterModel.title",
});
