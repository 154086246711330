import { toastContainerController } from "service/shared/singletones/toastContainerService/toastContainer.controller";
import { toastContainerState } from "service/shared/singletones/toastContainerService/toastContainer.state";

class Service {
  public readonly state = toastContainerState;

  public readonly controller = toastContainerController;
}

export const toastContainerService = new Service();
