import { passwordRegExp } from "appConstants/regexp";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class UsersChangePasswordInputState {
  public readonly placeholder: string;

  public readonly value: BehaviorSubjectString;

  public readonly touched: BehaviorSubjectBoolean;

  public readonly focused: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  private readonly valid: BehaviorSubjectBoolean;

  public constructor(placeholder: string) {
    this.placeholder = placeholder;
    this.value = new BehaviorSubjectString("");
    this.touched = new BehaviorSubjectBoolean(false);
    this.focused = new BehaviorSubjectBoolean(false);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
    this.showError = setCombinedBehaviorSubject(this.setShowError, this.focused, this.touched, this.valid);
  }

  private readonly setShowError = (focused: boolean, touched: boolean, valid: boolean): boolean => {
    return focused ? false : touched ? !valid : false;
  };

  private readonly setValid = (value: string): boolean => {
    return this.validate(value);
  };

  private readonly validate = (value: string): boolean => {
    return passwordRegExp.test(value);
  };
}
