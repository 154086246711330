import { ControlCellModel } from "model/ControlCell.model";
import { useDefaultObservableControlCellModelArray } from "observables/ControlCellModelArrayObservable";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { ControlCell } from "./ControlCell/ControlCell.component";
import { ControlsListProps } from "./ControlsList.props";
import { SControlsList } from "./ControlsListStyled/SControlsList.styled";

export const ControlsList = createComponent<ControlsListProps>("ControlsList", (props) => {
  const { service } = props;

  const controlCellList = useDefaultObservableControlCellModelArray(service.state.controlCellList);

  const renderControlCell: RenderFunctionType<ControlCellModel> = (cell) => <ControlCell service={service} cell={cell} key={cell.id} />;

  return <SControlsList>{controlCellList.map(renderControlCell)}</SControlsList>;
});
