import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";

class State {
  public readonly open: BehaviorSubjectBoolean;

  public readonly email: BehaviorSubjectString;

  public constructor() {
    this.open = new BehaviorSubjectBoolean(false);
    this.email = new BehaviorSubjectString("");
  }
}

export const menuState = new State();
