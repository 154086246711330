import { setBackgroundColor_ffffff, styled } from "theme";

export const SFilterButtonHider = styled.div`
  position: absolute;
  bottom: -10px;
  left: 0;
  height: 10px;
  right: 0;

  ${setBackgroundColor_ffffff};
`;
