import { SvgImage } from "components/SvgImage/SvgImage.component";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SActionCloseButton } from "./ActionCloseButtonStyled/SActionCloseButton.styled";

export const ActionCloseButton = createComponent("ActionCloseButton", () => {
  return (
    <SActionCloseButton onClick={paymentDetailsPageService.controller.close}>
      <SvgImage name="Close" />
    </SActionCloseButton>
  );
});
