import { resetScrollbar } from "theme/default/resetScrollbar";
import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_15px } from "theme/setter/setBorderRadiusValues/setBorderRadius_15px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SCreateUserForm = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: auto;
  width: 400px;
  padding: 24px 12px;
  transform: translate(-50%, -50%);
  gap: 24px;

  ${setFlexDFFDC};
  ${resetScrollbar};
  ${setBorderRadius_15px};
  ${setBackgroundColor_ffffff};
`;
