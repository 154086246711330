import { setZero } from "./setZero";

export const convertFetchedDateToTableShowDate = (dateValue: string): string => {
  const value = new Date(dateValue);

  const year = setZero(value.getFullYear());
  const month = setZero(value.getMonth() + 1);
  const date = setZero(value.getDate());

  const hours = setZero(value.getHours());
  const minutes = setZero(value.getMinutes());
  const seconds = setZero(value.getSeconds());

  return `${date}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};
