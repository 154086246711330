import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { invoicesPageService } from "service/shared/singletones/invoicesPageService/invoicesPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInvoicesTable } from "./InvoicesTableStyled/SInvoicesTable.styled";
import { SInvoicesTableContainer } from "./InvoicesTableStyled/SInvoicesTableContainer.styled";

export const InvoicesTable = createComponent("InvoicesTable", () => {
  const loaded = useDefaultObservableBoolean(invoicesPageService.state.loaded);
  const tableService = useDefaultObservableTableService(invoicesPageService.tableService);

  return (
    <SInvoicesTable>
      <SInvoicesTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SInvoicesTableContainer>
    </SInvoicesTable>
  );
});
