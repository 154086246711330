import React from "react";
import { css, setColor_rgba_0_0_0_87, setFontSize_20px, setFontWeight700, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SDynamicPageTitle = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;

  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
  ${isArabicLang};
`;
