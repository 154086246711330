import { styled } from "theme/default/styled";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const STerminalsListTableHeaderTitle = styled.div`
  ${setFlexF1};
  ${setFontSize_14px};
  ${setFontWeight600};
`;
