import React from "react";
import { css, setColor_25476d, setFontSize_13px, setFontWeight600, setProps, styled } from "theme";

interface SFetchedFilterMultipleSelectTitleProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterMultipleSelectTitleProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

export const SFetchedFilterMultipleSelectTitle = styled.div<SFetchedFilterMultipleSelectTitleProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 8px;
  margin-bottom: 8px;

  ${isArabicLang};
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_25476d};
`;
