import { t } from "i18n";
import { OptionModel } from "model/Option.model";

export const initialOptionList = [
  new OptionModel("compareMonthService.januaryTitle", t("compareMonthService.januaryTitle")),
  new OptionModel("compareMonthService.februaryTitle", t("compareMonthService.februaryTitle")),
  new OptionModel("compareMonthService.marchTitle", t("compareMonthService.marchTitle")),
  new OptionModel("compareMonthService.aprilTitle", t("compareMonthService.aprilTitle")),
  new OptionModel("compareMonthService.mayTitle", t("compareMonthService.mayTitle")),
  new OptionModel("compareMonthService.juneTitle", t("compareMonthService.juneTitle")),
  new OptionModel("compareMonthService.julyTitle", t("compareMonthService.julyTitle")),
  new OptionModel("compareMonthService.augustTitle", t("compareMonthService.augustTitle")),
  new OptionModel("compareMonthService.septemberTitle", t("compareMonthService.septemberTitle")),
  new OptionModel("compareMonthService.octoberTitle", t("compareMonthService.octoberTitle")),
  new OptionModel("compareMonthService.novemberTitle", t("compareMonthService.novemberTitle")),
  new OptionModel("compareMonthService.decemberTitle", t("compareMonthService.decemberTitle")),
];
