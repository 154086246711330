import i18next, { InitOptions } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";

import { translationAr } from "./locales/ar/translation";
import { translationEn } from "./locales/en/translation";
import { translationRu } from "./locales/ru/translation";

const initOptions: InitOptions = {
  resources: {
    en: {
      translation: translationEn,
    },
    ru: {
      translation: translationRu,
    },
    ar: {
      translation: translationAr,
    },
  },
  fallbackLng: isFinOn || isQi ? "en" : "ru",
};

if (isFinOn) {
  initOptions.lng = "en";
} else if (isQi) {
  initOptions.lng = "ar";
} else {
  initOptions.lng = "ru";
}

i18next.use(initReactI18next).use(LanguageDetector).init(initOptions);

const t = i18next.t.bind(i18next);
export { t };
