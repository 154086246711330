import React from "react";
import {
  borderRadius_8px,
  color_ffffff,
  css,
  setBackgroundColor_b7c8dc,
  setBackgroundColor_c4edff,
  setBackgroundColor_ffffff,
  setFontSize_12px,
  setProps,
  styled,
} from "theme";

type SCustomSelectOptionProps = {
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  isArabic: boolean;
};

const isActiveSet = setProps<SCustomSelectOptionProps>(({ isActive }) => isActive)(css`
  font-weight: 600;
  ${setBackgroundColor_c4edff};
`);

const isActiveNotSet = setProps<SCustomSelectOptionProps>(({ isActive }) => !isActive)(css`
  font-weight: 400;
  ${setBackgroundColor_ffffff};
`);

const isFirstSet = setProps<SCustomSelectOptionProps>(({ isFirst }) => isFirst)(css`
  border-top-left-radius: ${borderRadius_8px};
  border-top-right-radius: ${borderRadius_8px};
`);

const isLastSet = setProps<SCustomSelectOptionProps>(({ isLast }) => isLast)(css`
  border-bottom-right-radius: ${borderRadius_8px};
  border-bottom-left-radius: ${borderRadius_8px};
`);

const isArabicLang = setProps<SCustomSelectOptionProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<SCustomSelectOptionProps>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SCustomSelectOption = styled.button<SCustomSelectOptionProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 7px 8px;
  white-space: nowrap;

  border: 1px solid ${color_ffffff};

  ${setFontSize_12px};
  ${setBackgroundColor_ffffff};
  ${isActiveSet};
  ${isActiveNotSet};
  ${isFirstSet};
  ${isLastSet};
  ${isNotArabicLang};
  ${isArabicLang};

  :hover {
    ${setBackgroundColor_b7c8dc};
  }
`;
