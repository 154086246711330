/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const LogoQi = createIcon("LogoQi", (props) => {
  const { width = 244, height = 50, color = "#00346E" } = props;

  return (
    <svg width="50" height="50" viewBox="0 0 92 92" fill="#F2CD00" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.6726 78.3204C92.7622 81.4016 92.7622 86.3978 89.6726 89.479C86.5827 92.5605 81.5733 92.5605 78.4837 89.479L55.2436 66.3017C52.1538 63.2205 52.1538 58.2244 55.2436 55.1431C58.3332 52.0611 63.3421 52.0611 66.4325 55.1431L89.6726 78.3204ZM37.185 45.9866C37.185 50.8726 41.1566 54.8335 46.0558 54.8335C50.9558 54.8335 54.9275 50.8726 54.9275 45.9866C54.9275 41.1007 50.9558 37.1397 46.0558 37.1397C41.1566 37.1397 37.185 41.1007 37.185 45.9866ZM46.0563 76.0204C29.424 76.0204 15.9404 62.574 15.9404 45.9866C15.9404 29.3993 29.424 15.9521 46.0563 15.9521C62.6884 15.9521 76.1715 29.3993 76.1715 45.9866C76.1715 50.1846 75.3054 54.1798 73.7449 57.8091L85.4779 69.5103C89.6125 62.6339 91.994 54.5886 91.994 45.9866C91.994 20.6839 71.4274 0.172363 46.0556 0.172363C20.685 0.172363 0.117676 20.6839 0.117676 45.9866C0.117676 71.2886 20.685 91.8004 46.0556 91.8004C54.6844 91.8004 62.7537 89.424 69.6512 85.2977L57.9189 73.5971C54.278 75.1549 50.2691 76.0204 46.0563 76.0204Z"
      />
    </svg>
  );
});
