import React from "react";
import { css, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-right: 24px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-left: 24px;
`);

export const SSupportPageFeedbackFormContent = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isArabicLang};
  ${isNotArabicLang};
`;
