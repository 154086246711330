import React from "react";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCustomDateTimeValue = styled.button.attrs((props) => ({
  type: "button", // Set a default button type
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 28px;
  padding: 0 4px;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${setBackgroundColor_ffffff};
`;
