import React from "react";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SApplyCompareButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: auto;
  margin-left: auto;
  padding: 12px 24px;

  border: 1px solid ${color_a0b9d2};

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${setBorderRadius_8px};
`;
