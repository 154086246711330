import { userInfoPageController } from "service/shared/singletones/userInfoPageService/userInfoPage.controller";
import { userInfoPageState } from "service/shared/singletones/userInfoPageService/userInfoPage.state";

class Service {
  public readonly state = userInfoPageState;

  public readonly controller = userInfoPageController;
}

export const userInfoPageService = new Service();
