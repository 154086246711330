import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";

export class InvoiceSelectState {
  public readonly type: string;

  public readonly id: number;

  public readonly title: string;

  public readonly errorMessage: string;

  public readonly name: string;

  public readonly options: OptionModel[];

  public readonly isSelected: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly titleOption: OptionModel;

  public readonly value: BehaviorSubjectOptionModel;

  private readonly needUpdate: boolean;

  public readonly langPath: string;

  public readonly langPathError: string;

  public constructor(
    id: number,
    title: string,
    errorMessage: string,
    name: string,
    langPath: string,
    langPathError: string,
    options: OptionModel[],
    needUpdate?: boolean
  ) {
    this.type = "select";
    this.errorMessage = errorMessage;
    this.id = id;
    this.needUpdate = !!needUpdate;
    this.title = title;
    this.name = name;
    this.options = options;
    this.isSelected = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
    this.valid = this.isSelected;
    this.titleOption = new OptionModel("", "");
    this.value = new BehaviorSubjectOptionModel(this.titleOption);
    this.langPath = langPath;
    this.langPathError = langPathError;
  }
}
