import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupUserCellProps } from "./GroupUserCell.props";
import { SGroupUserCellTitle } from "./GroupUserCellStyled/SGroupUserCellTitle.styled";
import { SGroupUserCellTitleButton } from "./GroupUserCellStyled/SGroupUserCellTitleButton.styled";

export const GroupUserCell = createComponent<GroupUserCellProps>("GroupUserCell", (props) => {
  const { isFirst, groupUserModel, groupUserCellModel } = props;

  const style = {
    width: groupUserCellModel.width,
  };

  const isDetailsCell = groupUserCellModel.name === "details";

  if (isFirst) {
    return <div style={style} />;
  }

  if (isDetailsCell) {
    const to = replacePath(routerPaths.usersDetailsPage, {
      userId: groupUserModel.id,
    });

    return (
      <SGroupUserCellTitleButton style={style}>
        <DetailsCell to={to} />
      </SGroupUserCellTitleButton>
    );
  }

  return <SGroupUserCellTitle style={style}>{(groupUserModel as any)[groupUserCellModel.name]}</SGroupUserCellTitle>;
});
