import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

type SUsersChangeRowGroupInputInputProps = {
  showError: boolean;
};

const showErrorSet = setProps<SUsersChangeRowGroupInputInputProps>(({ showError }) => showError)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SUsersChangeRowGroupInputInput = styled(CustomSelect)<SUsersChangeRowGroupInputInputProps>`
  width: 200px;
  height: 26px;
  padding: 5px 25px 5px 5px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_rgba_0_0_0_87};
  ${setBorderRadius_4px};
  ${showErrorSet};
`;
