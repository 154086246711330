import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { InvoicesDetailsCellProps } from "detailsCell/InvoicesDetailsCell/InvoicesDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

export const InvoicesDetailsCell = createComponent<InvoicesDetailsCellProps>("InvoicesDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.invoiceDetails, {
    invoiceId: cellData.invoiceId,
  });

  return <DetailsCell to={to} />;
});
