import { activateInvitePageController } from "service/shared/singletones/activateInvitePageService/activateInvitePage.controller";
import { activateInvitePageState } from "service/shared/singletones/activateInvitePageService/activateInvitePage.state";

class Service {
  public readonly state = activateInvitePageState;

  public readonly controller = activateInvitePageController;
}

export const activateInvitePageService = new Service();
