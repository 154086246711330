import React from "react";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";

export const SCreateUserSelectInput = styled.input<React.InputHTMLAttributes<HTMLInputElement>>`
  height: 26px;
  padding: 5px 25px 5px 5px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
`;
