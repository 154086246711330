import { userRolesConfig } from "appConstants/userRolesConfig";
import { UserModel } from "model/User.model";
import { useDefaultObservableUserModelNull } from "observables/UserModelNullObservable";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetPasswordButton } from "./ResetPasswordButtonStyled/SResetPasswordButton.styled";
import { UserResetPasswordForm } from "./UserResetPasswordForm/UserResetPasswordForm.component";

export const ResetPasswordButton = createComponent("ResetPasswordButton", () => {
  const { t, i18n } = useTranslation();
  const user: UserModel = useDefaultObservableUserModelNull(usersDetailsPageService.state.user)!;

  const isSystemUser = user.roles.includes(userRolesConfig.SYSTEM);
  const isArabic = i18n.language === "ar";

  if (isSystemUser) {
    return null;
  }

  return (
    <>
      <SResetPasswordButton isArabic={isArabic} onClick={usersDetailsPageService.controller.onClickResetPasswordButton}>
        {t("usersDetailsPage.resetPasswordButtonTitle")}
      </SResetPasswordButton>
      <UserResetPasswordForm />
    </>
  );
});
