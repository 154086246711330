import { useTableContext } from "context/Table.context";
import { useDefaultObservableBodyCellWithIdArray } from "observables/BodyCellWithIdArrayObservable";
import { BodyCellWithId } from "types/business/BodyCellWithId";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { BodyDataRow } from "./BodyDataRow/BodyDataRow.component";
import { STableBody } from "./BodyStyled/STableBody.styled";
import { STableBodyContainer } from "./BodyStyled/STableBodyContainer.styled";

export const Body = createComponent("Body", () => {
  const { service } = useTableContext();

  const bodyRowList = useDefaultObservableBodyCellWithIdArray(service.state.bodyRowList);

  const renderRowData: RenderFunctionType<BodyCellWithId> = (cellData, index: number) => (
    <BodyDataRow cellData={cellData} isFirst={index === 0} key={cellData.id} />
  );

  return (
    <STableBody>
      <STableBodyContainer>{bodyRowList.map(renderRowData)}</STableBodyContainer>
    </STableBody>
  );
});
