import { useTranslation } from "react-i18next";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRolesFormBackButton } from "./RolesFormBackButtonStyled/SRolesFormBackButton.styled";

export const RolesFormBackButton = createComponent("RolesFormBackButton", () => {
  const { t } = useTranslation();
  return (
    <SRolesFormBackButton onClick={createUserRolesSelectFormService.controller.onClickBackButton}>
      {t("usersDetailsPage.rolesFormBackButton")}
    </SRolesFormBackButton>
  );
});
