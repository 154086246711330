import { transferDetailsPageController } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.controller";
import { transferDetailsPageState } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.state";

class Service {
  public readonly state = transferDetailsPageState;

  public readonly controller = transferDetailsPageController;
}

export const transferDetailsPageService = new Service();
