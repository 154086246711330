import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ControlsProps } from "./Controls.props";
import { ControlsList } from "./ControlsList/ControlsList.component";
import { SControls } from "./ControlsStyled/SControls.styled";
import { LeftControls } from "./LeftControls/LeftControls.component";
import { RightControls } from "./RightControls/RightControls.component";

export const Controls = createComponent<ControlsProps>("Controls", (props) => {
  const { service } = props;
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);
  return (
    <SControls isArabic={isArabic}>
      <LeftControls service={service} />
      <ControlsList service={service} />
      <RightControls service={service} />
    </SControls>
  );
});
