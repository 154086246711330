import React from "react";
import { css, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-left: 20px;
  flex-direction: row-reverse;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-right: 20px;
`);

export const STableNavigationTotalPages = styled.div<Props & React.HTMLAttributes<HTMLDivElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFlexDFAIC};
  ${setFlexJCC};
`;
