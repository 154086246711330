import { OptionModel } from "model/Option.model";
import { MultipleCustomSelectController } from "service/shared/others/MultipleCustomSelectService/MultipleCustomSelect.controller";
import { MultipleCustomSelectState } from "service/shared/others/MultipleCustomSelectService/MultipleCustomSelect.state";

export class MultipleCustomSelectService {
  public readonly state: MultipleCustomSelectState;

  public readonly controller: MultipleCustomSelectController;

  public constructor(optionList: OptionModel[], onChange: (value: OptionModel) => void, onClickAll: () => void) {
    this.state = new MultipleCustomSelectState(optionList, onChange, onClickAll);
    this.controller = new MultipleCustomSelectController(this.state);
  }
}
