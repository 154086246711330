import { useRolesFormContext } from "context/RolesForm.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SUsersChangeRowRolesCommon } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommon.styled";
import { SUsersChangeRowRolesCommonTitle } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommonTitle.styled";
import { SUsersChangeRowRolesCommonValue } from "./UsersChangeRowRolesCommonStyled/SUsersChangeRowRolesCommonValue.styled";

export const UsersChangeRowRolesCommon = createComponent("UsersChangeRowRolesCommon", () => {
  const { t, i18n } = useTranslation();
  const { service } = useRolesFormContext();
  const isArabic = i18n.language === "ar";

  return (
    <SUsersChangeRowRolesCommon isArabic={isArabic}>
      <SUsersChangeRowRolesCommonTitle>{t("usersDetailsPage.roles")}</SUsersChangeRowRolesCommonTitle>
      <SUsersChangeRowRolesCommonValue>{service.state.titledValue.value}</SUsersChangeRowRolesCommonValue>
    </SUsersChangeRowRolesCommon>
  );
});
