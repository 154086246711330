import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { ComparisonSection } from "./ComparisonSection/ComparisonSection.component";
import { SDynamicPage } from "./DynamicPageStyled/SDynamicPage.styled";
import { SDynamicPageTitle } from "./DynamicPageStyled/SDynamicPageTitle.styled";
import { GraphSection } from "./GraphSection/GraphSection.component";

export const DynamicPage = createComponent("DynamicPage", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <SDynamicPage>
      <SDynamicPageTitle isArabic={isArabic}>{t("dynamicPage.pageTitle")}</SDynamicPageTitle>
      <ComparisonSection />
      <GraphSection />
    </SDynamicPage>
  );
});
