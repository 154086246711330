import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { CreateUserForm } from "./CreateUserForm/CreateUserForm.component";
import { SOpenUserFormButton } from "./OpenUserFormButtonStyled/SOpenUserFormButton.styled";
import { RolesSelectForm } from "./RolesSelectForm/RolesSelectForm.component";

export const OpenUserFormButton = createComponent("OpenUserFormButton", () => {
  const { t } = useTranslation();
  return (
    <>
      <SOpenUserFormButton onClick={createUserServiceFormService.controller.onClickOpenFormButton}>
        <SvgImage height={18} width={18} name="FileUpload" />
        {t("usersInfoPage.openUserFormButton")}
      </SOpenUserFormButton>
      <CreateUserForm />
      <RolesSelectForm />
    </>
  );
});
