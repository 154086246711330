import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_e7ecf2 } from "theme/setter/colorsValues/color_e7ecf2";
import { setColor_e8e8e8 } from "theme/setter/setColorsValues/setColor_e8e8e8";
import { setColor_f5f5f5 } from "theme/setter/setColorsValues/setColor_f5f5f5";
import { setColor_ffffff } from "theme/setter/setColorsValues/setColor_ffffff";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

type SGroupProps = {
  loaded: boolean;
  isFirst: boolean;
};

const loadedSet = setProps<SGroupProps>(({ loaded }) => loaded)(css`
  position: relative;
  overflow: hidden;
  background-size: 0;

  background-image: linear-gradient(to right, ${setColor_ffffff} calc(50% - 100px), ${setColor_f5f5f5} 50%, ${setColor_e8e8e8} calc(50% + 100px));
`);

const isFirstSet = setProps<SGroupProps>(({ isFirst }) => isFirst)(css`
  border-top: none;
`);

export const SGroup = styled.div<SGroupProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 4px 0;

  border-top: 1px solid ${color_e7ecf2};

  ${setFlexDFFDC};
  ${setFlexJCC};
  ${loadedSet};
  ${isFirstSet};
`;
