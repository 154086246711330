import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-right: 24px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-left: 24px;
`);

export const SSupportPageQuestionListList = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-left: 24px;
  gap: 8px;

  ${setFlexDFFDC};
  ${isNotArabicLang};
  ${isArabicLang};
`;
