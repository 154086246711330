import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentDetailsPageConfirm = (): HeaderCellModel[] => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.creationDate, t("paymentDetailsPageConfirmConfig.creationDate"), "paymentDetailsPageConfirmConfig.creationDate", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageConfirmConfig.status"), "paymentDetailsPageConfirmConfig.status", {
      width: tableWidths.width150,
    }),
    getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageConfirmConfig.amount"), "paymentDetailsPageConfirmConfig.amount", {
      width: tableWidths.width150,
    }),
  ];
};
