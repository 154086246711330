import React from "react";
import { setColor_rgba_0_0_0_87, setFontSize_20px, setFontWeight700, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SComparisonSectionTitle = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;

  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
  ${setTextRightArabic()};
`;
