import { Row } from "components/Row/Row.component";
import { RefundDetailsModel } from "model/RefundDetails.model";
import { useDefaultObservableRefundDetailsModelNull } from "observables/RefundDetailsModelNullObservable";
import { useTranslation } from "react-i18next";
import { paymentRefundDetailsPageService } from "service/shared/singletones/paymentRefundDetailsPageService/paymentRefundDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPaymentRefundDetailsContent } from "./PaymentRefundDetailsPageContentStyled/SPaymentRefundDetailsContent.styled";
import { SPaymentRefundDetailsContentColumn } from "./PaymentRefundDetailsPageContentStyled/SPaymentRefundDetailsContentColumn.styled";
import { SPaymentRefundDetailsContentRow } from "./PaymentRefundDetailsPageContentStyled/SPaymentRefundDetailsContentRow.styled";

export const PaymentRefundDetailsPageContent = createComponent("PaymentRefundDetailsPageContent", () => {
  const { t } = useTranslation();
  const refund: RefundDetailsModel = useDefaultObservableRefundDetailsModelNull(paymentRefundDetailsPageService.state.refund)!;

  return (
    <SPaymentRefundDetailsContent>
      <SPaymentRefundDetailsContentRow>
        <SPaymentRefundDetailsContentColumn>
          <Row title={t("paymentRefundDetailsPageContent.amount")} value={refund.amount} />
          <Row title={t("paymentRefundDetailsPageContent.currency")} value={refund.currency} />
          <Row title={t("paymentRefundDetailsPageContent.creationDate")} value={refund.creationDate} />
          <Row title={t("paymentRefundDetailsPageContent.resultCode")} value={refund.resultCode} />
          <Row title={t("paymentRefundDetailsPageContent.authId")} value={refund.authId} />
          <Row title={t("paymentRefundDetailsPageContent.rrn")} value={refund.rrn} />
        </SPaymentRefundDetailsContentColumn>
        <div>
          <Row title={t("paymentRefundDetailsPageContent.terminalId")} value={refund.terminalId} />
          <Row title={t("paymentRefundDetailsPageContent.maskedPan")} value={refund.maskedPan} />
          <Row title={t("paymentRefundDetailsPageContent.paymentSystem")} value={refund.paymentSystem} />
          <Row title={t("paymentRefundDetailsPageContent.status")} value={refund.status} />
          <Row title={t("paymentRefundDetailsPageContent.externalId")} value={refund.externalId} />
        </div>
      </SPaymentRefundDetailsContentRow>
    </SPaymentRefundDetailsContent>
  );
});
