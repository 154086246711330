import { HeaderCellModel } from "model/HeaderCell.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { TableService } from "service/shared/others/TableService/Table.service";
import { paymentDetailsTableState } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.state";
import { CurrentDataType } from "types/business/CurrentDataType";
import { PaymentCancel } from "types/business/PaymentCancel";
import { PaymentConfirm } from "types/business/PaymentConfirm";
import { PaymentData } from "types/business/PaymentData";
import { PaymentRefund } from "types/business/PaymentRefund";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Controller {
  public readonly state = paymentDetailsTableState;

  public readonly mount = (cancels: PaymentCancel[], confirms: PaymentConfirm[], refunds: PaymentRefund[]): void => {
    this.state.cancels = cancels;
    this.state.confirms = confirms;
    this.state.refunds = refunds;

    this.setAvailableData();

    this.setPaymentDataType();

    this.state.paymentData = setCombinedBehaviorSubject(this.setPaymentData, this.state.currentDataType);

    this.state.tableService = setCombinedBehaviorSubject(this.setTableService, this.state.paymentData, this.state.initialHeaderCellList);

    this.state.loaded.next(true);
  };

  public readonly unMount = (): void => {
    this.state.loaded.next(false);
  };

  public readonly setDataType = (dataType: CurrentDataType): void => {
    this.state.currentDataType.next(dataType);
  };

  private readonly setAvailableData = (): void => {
    this.state.cancelsIsAvailable = new BehaviorSubjectBoolean(this.state.cancels.length !== 0);
    this.state.confirmsIsAvailable = new BehaviorSubjectBoolean(this.state.confirms.length !== 0);
    this.state.refundsIsAvailable = new BehaviorSubjectBoolean(this.state.refunds.length !== 0);
  };

  private readonly setPaymentDataType = (): void => {
    let currentDataType: CurrentDataType;

    if (this.state.cancelsIsAvailable.value) {
      currentDataType = "cancel";
    } else if (this.state.confirmsIsAvailable.value) {
      currentDataType = "confirm";
    } else if (this.state.refundsIsAvailable.value) {
      currentDataType = "refund";
    } else {
      currentDataType = "none";
    }

    this.state.currentDataType.next(currentDataType);
  };

  private readonly setPaymentData = (currentDataType: CurrentDataType): PaymentData[] => {
    if (currentDataType === "cancel") {
      return this.state.cancels;
    }
    if (currentDataType === "confirm") {
      return this.state.confirms;
    }
    if (currentDataType === "refund") {
      return this.state.refunds;
    }
    return [];
  };

  private readonly setTableService = (paymentData: PaymentData[], initialHeaderCellList: HeaderCellModel[]): TableService => {
    return new TableService(paymentData, initialHeaderCellList);
  };
}

export const paymentDetailsTableController = new Controller();
