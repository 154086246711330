import { CompareYearController } from "service/shared/others/CompareYearService/CompareYear.controller";
import { CompareYearState } from "service/shared/others/CompareYearService/CompareYear.state";

export class CompareYearService {
  public readonly state: CompareYearState;

  public readonly controller: CompareYearController;

  public constructor() {
    this.state = new CompareYearState();
    this.controller = new CompareYearController(this.state);
  }
}
