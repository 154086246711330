import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";

import { createComponent } from "../utils";
import { SSwitchLangApp, SSwitchLangAppBtn } from "./AppStyled/SwitchLangApp.styled";

interface Native {
  nativeName: "Русский" | "English" | "العربية";
}
interface Langs {
  ru: Native;
  en: Native;
  ar: Native;
}
const langs: Langs = {
  ru: { nativeName: "Русский" },
  en: { nativeName: "English" },
  ar: { nativeName: "العربية" },
};

const langsKeys: Array<keyof typeof langs> = ["ru", "en", "ar"];

export const AppSwitchLang = createComponent("AppSwitchLang", () => {
  const { i18n } = useTranslation();

  if (isFinOn || isQi) {
    const handlerChangeLang = (lng: "en" | "ru" | "ar"): void => {
      i18n.changeLanguage(lng);
    };

    const renderLangs = (lng: keyof typeof langs): ReactNode => {
      return (
        <SSwitchLangAppBtn
          type="submit"
          key={lng}
          onClick={(): void => handlerChangeLang(lng)}
          disabled={i18n.resolvedLanguage === lng}
          hidden={isQi && lng === "ru"}
        >
          {langs[lng].nativeName}
        </SSwitchLangAppBtn>
      );
    };

    return <SSwitchLangApp>{langsKeys.map(renderLangs)}</SSwitchLangApp>;
  }
  return null;
});
