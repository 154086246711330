import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";

export type TransferCancel = {
  creationDate: string;
  successfully: string;
};

export class TransferDetailsModel {
  public readonly requestId: string;

  public readonly amount: string;

  public readonly canceled: boolean | null;

  public readonly sourcePaymentSystem: string;

  public readonly sourceMaskedPan: string;

  public readonly sourceAuthId: string;

  public readonly fee: string;

  public readonly sourceResultCode: string;

  public readonly sourceRrn: string;

  public readonly currency: string;

  public readonly terminalId: string;

  public readonly creationDate: string;

  public readonly sourceExternalRrn: string;

  public readonly destinationExternalRrn: string;

  public readonly type: string;

  public readonly destinationPaymentSystem: string;

  public readonly destinationMaskedPan: string;

  public readonly destinationAuthId: string;

  public readonly sourceCardHolder: string;

  public readonly destinationResultCode: string;

  public readonly destinationRrn: string;

  public readonly status: string;

  public readonly hasSourceDetails: boolean;

  public readonly hasDestinationDetails: boolean;

  public readonly cancels: TransferCancel[];

  public readonly hasCancelAction: boolean;

  public constructor(dataItem: any) {
    this.requestId = dataItem?.requestId || "";
    this.fee = dataItem?.fee ? dataItem?.fee.toString() : "";
    this.amount = dataItem?.amount || "";
    this.hasSourceDetails = !!dataItem?.sourcePaymentDetails;
    this.hasDestinationDetails = !!dataItem?.destinationPaymentDetails;
    this.sourcePaymentSystem = dataItem?.sourcePaymentDetails?.paymentSystem || "";
    this.sourceCardHolder = dataItem?.sourcePaymentDetails?.cardHolder || "";
    this.sourceMaskedPan = dataItem?.sourcePaymentDetails?.maskedPan || "";
    this.sourceAuthId = dataItem?.sourcePaymentDetails?.authId || "";
    this.sourceResultCode = dataItem?.sourcePaymentDetails?.resultCode || "";
    this.sourceRrn = dataItem?.sourcePaymentDetails?.rrn || "";
    this.sourceExternalRrn = dataItem?.sourcePaymentDetails?.externalRrn || "";
    this.currency = dataItem?.currency || "";
    this.canceled = dataItem?.canceled === true || dataItem?.canceled === false ? dataItem.canceled : null;
    this.terminalId = dataItem?.terminalId || "";
    this.creationDate = dataItem?.creationDate ? convertFetchedDateToTableShowDate(dataItem?.creationDate) : "";
    this.type = dataItem?.type || "";
    this.destinationPaymentSystem = dataItem?.destinationPaymentDetails?.paymentSystem || "";
    this.destinationMaskedPan = dataItem?.destinationPaymentDetails?.maskedPan || "";
    this.destinationAuthId = dataItem?.destinationPaymentDetails?.authId || "";
    this.destinationResultCode = dataItem?.destinationPaymentDetails?.resultCode || "";
    this.destinationRrn = dataItem?.destinationPaymentDetails?.rrn || "";
    this.destinationExternalRrn = dataItem?.destinationPaymentDetails?.externalRrn || "";
    this.status = dataItem?.status || "";
    this.cancels = dataItem?.cancels ? this.produceCancels(dataItem?.cancels) : [];
    this.hasCancelAction = dataItem?.operationAvailability.CANCEL || false;
  }

  private readonly produceCancels = (cancelData: any): TransferCancel[] => {
    return cancelData.map((cancel: any): TransferCancel => {
      return {
        creationDate: cancel?.created ? convertFetchedDateToTableShowDate(cancel.created) : "",
        successfully: cancel?.successfully ? "SUCCESS" : "FAIL",
      };
    });
  };
}
