import { amountFilterModel } from "config/filterModels/amountFilterModel";
import { authCodeFilterModel } from "config/filterModels/authCodeFilterModel";
import { cardHolderFilterModel } from "config/filterModels/cardHolderFilterModel";
import { cardNumberFilterModel } from "config/filterModels/cardNumberFilterModel";
import { dateFilterModel } from "config/filterModels/dateFilterModel";
import { merchantFilterModel } from "config/filterModels/merchantFilterModel";
import { operationStatusFilterModel } from "config/filterModels/operationStatusFilterModel";
import { paymentSystemTypeFilterModel } from "config/filterModels/paymentSystemTypeFilterModel";
import { requestIdFilterModel } from "config/filterModels/requestIdFilterModel";
import { resultCodeFilterModel } from "config/filterModels/resultCodeFilterModel";
import { rrnFilterModel } from "config/filterModels/rrnFilterModel";
import { terminalFilterModel } from "config/filterModels/terminalFilterModel";
import { transferIdFilterModel } from "config/filterModels/transferIdFilterModel";
import { transferTypeFilterModel } from "config/filterModels/transferTypeFilterModel";
import { FilterModel } from "model/Filter.model";

export const transfersFilterConfig: FilterModel[] = [
  dateFilterModel,
  requestIdFilterModel,
  transferIdFilterModel,
  operationStatusFilterModel,
  terminalFilterModel,
  merchantFilterModel,
  transferTypeFilterModel,
  rrnFilterModel,
  authCodeFilterModel,
  cardNumberFilterModel,
  cardHolderFilterModel,
  paymentSystemTypeFilterModel,
  amountFilterModel,
  resultCodeFilterModel,
];
