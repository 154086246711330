import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectMenuIconModelArray } from "observables/MenuIconModelArrayObservable";

class State {
  public readonly menuIconList: BehaviorSubjectMenuIconModelArray;

  public open!: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public constructor() {
    this.menuIconList = new BehaviorSubjectMenuIconModelArray([]);
    this.loaded = new BehaviorSubjectBoolean(false);
  }
}

export const navigationMenuState = new State();
