import React from "react";
import { css, setBackgroundColor_0083f5, setBorderRadius_8px, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";

type SNavigationMenuIconLabelProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SNavigationMenuIconLabelProps>(({ isArabic }) => isArabic)(css`
  right: 30px;
`);
const isNotArabicLang = setProps<SNavigationMenuIconLabelProps>(({ isArabic }) => !isArabic)(css`
  left: 30px;
`);
export const SNavigationMenuIconLabel = styled.div<SNavigationMenuIconLabelProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  z-index: 2;
  top: 30px;
  padding: 8px;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_0083f5};
  ${isArabicLang};
  ${isNotArabicLang};
`;
