import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInvitePasswordInput } from "./InvitePasswordInputStyled/SInvitePasswordInput.styled";

export const InvitePasswordInput = createComponent("InvitePasswordInput", () => {
  const { t } = useTranslation();
  return (
    <SInvitePasswordInput
      type="password"
      name="password"
      placeholder={t("activateInvitePage.password")}
      service={activateInvitePageService.state.passwordService}
    />
  );
});
