import React from "react";
import { css, setProps, styled } from "theme";

interface SFetchedFilterMultipleSelectDeleteProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFetchedFilterMultipleSelectDeleteProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFetchedFilterMultipleSelectDeleteProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFetchedFilterMultipleSelectDelete = styled.button<SFetchedFilterMultipleSelectDeleteProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 20px;
  ${isArabicLang};
  ${isNotArabicLang};
`;
