import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useState } from "utils/libReplace/useState";

import { SupportPageQuestionProps } from "./SupportPageQuestion.props";
import { SSupportPageQuestion } from "./SupportPageQuestionStyled/SSupportPageQuestion.styled";
import { SSupportPageQuestionDescription } from "./SupportPageQuestionStyled/SSupportPageQuestionDescription.styled";
import { SSupportPageQuestionHeader } from "./SupportPageQuestionStyled/SSupportPageQuestionHeader.styled";
import { SSupportPageQuestionIconRotated } from "./SupportPageQuestionStyled/SSupportPageQuestionIconRotated.styled";
import { SSupportPageQuestionTitle } from "./SupportPageQuestionStyled/SSupportPageQuestionTitle.styled";

export const SupportPageQuestion = createComponent<SupportPageQuestionProps>("SupportPageQuestion", (props) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { questionModel } = props;

  const { answer, question } = questionModel;
  const [open, setOpen] = useState<boolean>(false);

  const onClick = (): void => {
    setOpen(!open);
  };

  return (
    <SSupportPageQuestion isArabic={isArabic}>
      <SSupportPageQuestionHeader isArabic={isArabic} onClick={onClick}>
        <SSupportPageQuestionTitle isArabic={isArabic}>{question}</SSupportPageQuestionTitle>
        <SSupportPageQuestionIconRotated>
          <SvgImage width={21} height={12} name="ArrowDown" />
        </SSupportPageQuestionIconRotated>
      </SSupportPageQuestionHeader>

      {open && <SSupportPageQuestionDescription dangerouslySetInnerHTML={{ __html: answer }} />}
    </SSupportPageQuestion>
  );
});
