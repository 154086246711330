import { SvgImage } from "components/SvgImage/SvgImage.component";
import { DetailsCellProps } from "detailsCell/DetailsCell/DetailsCell.props";
import { SDetailsCell } from "detailsCell/DetailsCell/DetailsCellStyled/SDetailsCell.styled";
import { createComponent } from "utils/libExtend/createComponent";
import { useState } from "utils/libReplace/useState";

export const DetailsCell = createComponent<DetailsCellProps>("DetailsCell", (props) => {
  const { to } = props;

  const [hovered, setHovered] = useState<boolean>(false);

  const onMouseOut = (): void => {
    setHovered(false);
  };

  const onMouseOver = (): void => {
    setHovered(true);
  };

  const name = hovered ? "DetailsIconActive" : "DetailsIcon";

  return (
    <SDetailsCell to={to} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
      <SvgImage name={name} />
    </SDetailsCell>
  );
});
