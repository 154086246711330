import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_15px } from "theme/setter/setFontSizeValues/setFontSize_15px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SFeedbackFormSendButtonProps = {
  sendButtonValid: boolean;
};

const sendButtonValidSet = setProps<SFeedbackFormSendButtonProps>(({ sendButtonValid }) => !sendButtonValid)(css`
  cursor: default;
  opacity: 0.45;
`);

export const SFeedbackFormSendButton = styled.button<SFeedbackFormSendButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 24px;

  ${setFontSize_15px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${sendButtonValidSet};
`;
