import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { invoicesPageService } from "service/shared/singletones/invoicesPageService/invoicesPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { InvoicesHeader } from "./InvoicesHeader/InvoicesHeader.component";
import { SInvoicesPage } from "./InvoicesPageStyled/SInvoicesPage.styled";
import { InvoicesTable } from "./InvoicesTable/InvoicesTable.component";

export const InvoicesPage = createComponent("InvoicesPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    invoicesPageService.controller.mounted();

    return (): void => {
      invoicesPageService.controller.unMounted();
    };
  }, []);

  return (
    <SInvoicesPage isArabic={i18n.language === "ar"}>
      <InvoicesHeader />
      <InvoicesTable />
      <TableNavigation service={invoicesPageService.state.filterNavigationService} />
    </SInvoicesPage>
  );
});
