import React from "react";
import { setFlexDF, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SComparisonSectionContainer = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 24px;

  ${setFlexDF};
  ${setRowReverse()};
`;
