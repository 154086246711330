import { css } from "packages/styled-components";
import { Input } from "pages/SignInPage/Input/Input.component";
import { styled } from "theme/default/styled";
import { isFinOn, isQi } from "utils/business/environment";

const finOn = css`
  margin-top: 20px;
  margin-bottom: 5px;
  font-size: 18px;
`;
const sngb = css`
  margin-top: 20px;
  margin-bottom: 5px;
`;
export const SSignInEmailInput = styled(Input) <{ $isArabic?: boolean }>`
  ${isFinOn || isQi ? finOn : sngb};
  text-align: ${({ $isArabic }) => $isArabic && "right"};
`;
