import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { RefObject } from "types/libReplace/RefObject";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";
import { createRef } from "utils/libReplace/createRef";

export class FilterSelectDataModel {
  public readonly value: BehaviorSubjectOptionModel;

  public readonly filterModel: FilterModel;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly inputRef: RefObject<{ clearForm: () => void }>;

  private readonly startValue: OptionModel;

  private readonly removeActiveFilter: (filterModel: FilterModel) => void;

  public constructor(filterModel: FilterModel, removeActiveFilter: (filterModelParam: FilterModel) => void) {
    this.filterModel = filterModel;

    this.removeActiveFilter = removeActiveFilter;

    this.inputRef = createRef<{
      clearForm: () => void;
    }>();

    this.startValue = this.filterModel?.initialOptionValue || new OptionModel("", "");
    this.value = new BehaviorSubjectOptionModel(this.startValue);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  public readonly onChange = (value: OptionModel): void => {
    if (this.filterModel.optionList) {
      this.value.next(value);
    }
  };

  public readonly onClickTrash = (): void => {
    this.removeActiveFilter(this.filterModel);
  };

  public readonly onClickClear = (): void => {
    this.resetValue();
    this.inputRef.current?.clearForm();
  };

  private readonly resetValue = (): void => {
    this.value.next(this.startValue);
  };

  private readonly setValid = (value: OptionModel): boolean => {
    return this.filterModel.selectTitle !== value.value;
  };
}
