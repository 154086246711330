import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly open: BehaviorSubjectBoolean;

  public readonly isFormValid: BehaviorSubjectBoolean;

  public readonly descriptionValue: BehaviorSubjectString;

  public readonly emailValue: BehaviorSubjectString;

  public readonly typeValue: BehaviorSubjectString;

  public constructor() {
    this.open = new BehaviorSubjectBoolean(false);

    this.descriptionValue = new BehaviorSubjectString("");
    this.emailValue = new BehaviorSubjectString("");
    this.typeValue = new BehaviorSubjectString("");

    this.isFormValid = setCombinedBehaviorSubject(this.setIsFormValid, this.descriptionValue, this.emailValue, this.typeValue);
  }

  private readonly setIsFormValid = (descriptionValue: string, emailValue: string, typeValue: string): boolean => {
    return descriptionValue.trim() !== "" && emailValue.trim() !== "" && typeValue.trim() !== "";
  };
}

export const createArticleFormState = new State();
