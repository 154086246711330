import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFJCC } from "theme/setter/setFlexValues/setFlexDFJCC";

export const SCheckboxFilterDelete = styled.button.attrs((props) => ({
  type: "submit",
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  align-content: center;
  margin-left: 8px;

  ${setFlexDFJCC};
`;
