import { dynamicPageController } from "service/shared/singletones/dynamicPageService/dynamicPage.controller";
import { dynamicPageState } from "service/shared/singletones/dynamicPageService/dynamicPage.state";

class Service {
  public readonly state = dynamicPageState;

  public readonly controller = dynamicPageController;
}

export const dynamicPageService = new Service();
