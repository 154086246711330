import { styled } from "theme/default/styled";
import { setColor_2d3033 } from "theme/setter/setColorsValues/setColor_2d3033";
import { setFlexDFJCC } from "theme/setter/setFlexValues/setFlexDFJCC";
import { setFontSize_18px } from "theme/setter/setFontSizeValues/setFontSize_18px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";

export const SSelectedActionStepTitle = styled.div`
  margin-bottom: auto;

  ${setFlexDFJCC};
  ${setFontSize_18px};
  ${setFontWeight700};
  ${setColor_2d3033};
`;
