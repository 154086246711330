import { styled } from "theme/default/styled";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SMainLayout = styled.div`
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  padding-bottom: 50px;

  ${setFlexDFFDC};
  ${setBorderRadius_8px};
  ${setBackgroundColor_f7faff};
`;
