import { userService } from "api/userService";
import { emailRegExp } from "appConstants/regexp";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectStringArray } from "observables/StringArrayObservable";
import { SignInInputService } from "service/shared/others/SignInInputService/SignInInput.service";
import { initialReadRoles } from "service/shared/singletones/signInFormService/initialReadRoles";
import { initialRolesRedirectArray } from "service/shared/singletones/signInFormService/initialRolesRedirectArray";
import { PasswordConfigType } from "types/business/PasswordConfigType";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly passwordConfig: PasswordConfigType;

  public readonly showErrors: BehaviorSubjectBoolean;

  public readonly readRoles: string[];

  public readonly rolesRedirectArray: Record<string, string>;

  public readonly isLoaded: BehaviorSubjectBoolean;

  public readonly emailService: SignInInputService;

  public readonly passwordService: SignInInputService;

  public readonly showDigitsError: BehaviorSubjectBoolean;

  public readonly showLengthError: BehaviorSubjectBoolean;

  public readonly showLowercaseLettersError: BehaviorSubjectBoolean;

  public readonly showRussianLetterError: BehaviorSubjectBoolean;

  public readonly showSpecialSymbolsError: BehaviorSubjectBoolean;

  public readonly showUppercaseLettersError: BehaviorSubjectBoolean;

  public readonly showCapsLockError: BehaviorSubjectBoolean;

  public readonly errorTextArray: BehaviorSubjectStringArray;

  public constructor() {
    this.passwordConfig = userService.getPasswordConfig();

    this.showErrors = new BehaviorSubjectBoolean(false);

    this.readRoles = initialReadRoles;
    this.rolesRedirectArray = initialRolesRedirectArray;
    this.isLoaded = new BehaviorSubjectBoolean(false);
    this.emailService = new SignInInputService({
      regexp: emailRegExp,
      required: true,
    });
    this.passwordService = new SignInInputService();

    this.showDigitsError = setCombinedBehaviorSubject(this.setShowDigitsError, this.passwordService.state.digitsState);
    this.showLengthError = setCombinedBehaviorSubject(this.setShowLengthError, this.passwordService.state.lengthState);
    this.showLowercaseLettersError = setCombinedBehaviorSubject(this.setShowLowercaseLettersError, this.passwordService.state.lowercaseLettersState);
    this.showRussianLetterError = setCombinedBehaviorSubject(this.setShowRussianLetterError, this.passwordService.state.russianLetterState);
    this.showSpecialSymbolsError = setCombinedBehaviorSubject(this.setShowSpecialSymbolsError, this.passwordService.state.specialSymbolsState);
    this.showUppercaseLettersError = setCombinedBehaviorSubject(this.setShowUppercaseLettersError, this.passwordService.state.uppercaseLettersState);
    this.showCapsLockError = setCombinedBehaviorSubject(this.setShowCapsLockError, this.passwordService.state.capsLockState);

    this.errorTextArray = setCombinedBehaviorSubject(
      this.setErrorTextArray,
      this.showDigitsError,
      this.showLengthError,
      this.showLowercaseLettersError,
      this.showSpecialSymbolsError,
      this.showUppercaseLettersError
    );
  }

  public readonly setShowDigitsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLengthError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLowercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowRussianLetterError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowSpecialSymbolsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowUppercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setErrorTextArray = (
    showDigitsError: boolean,
    showLengthError: boolean,
    showLowercaseLettersError: boolean,
    showSpecialSymbolsError: boolean,
    showUppercaseLettersError: boolean
  ): string[] => {
    const errorTextArray: string[] = [];

    if (showDigitsError) errorTextArray.push("signInPage.digitsError");
    if (showLengthError) errorTextArray.push("signInPage.lengthError");
    if (showLowercaseLettersError) errorTextArray.push("signInPage.lowercaseLettersError");
    if (showSpecialSymbolsError) errorTextArray.push("signInPage.specialSymbolsError");
    if (showUppercaseLettersError) errorTextArray.push("signInPage.uppercaseLettersError");

    return errorTextArray;
  };

  private readonly setShowCapsLockError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };
}

export const signInFormState = new State();
