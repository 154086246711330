import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SCustomSelect = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  position: relative;
  height: 26px;

  ${setFlexDFAIC};
  ${setFlexJCC};
`;
