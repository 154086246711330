import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";

type SImgButtonProps = {
  active: boolean;
};

const activeSet = setProps<SImgButtonProps>(({ active }) => active)(css`
  cursor: pointer;
  opacity: 1;
`);

export const SImgButton = styled.button<SImgButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  cursor: default;
  opacity: 0.45;

  ${activeSet};
`;
