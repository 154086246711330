import { getOffset } from "./getOffset";
import { setZero } from "./setZero";

export const formatDate = (value: Date): string => {
  const offset = getOffset(value.getTimezoneOffset());
  const year = setZero(value.getFullYear());
  const month = setZero(value.getMonth() + 1);
  const date = setZero(value.getDate());
  const hours = setZero(value.getHours());
  const minutes = setZero(value.getMinutes());
  const seconds = setZero(value.getSeconds());

  return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}${offset}`;
};
