import { styled } from "theme/default/styled";
import { setColor_2d3033 } from "theme/setter/setColorsValues/setColor_2d3033";
import { setFontSize_20px } from "theme/setter/setFontSizeValues/setFontSize_20px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";

export const SChangePasswordSectionTitle = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;

  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_2d3033};
`;
