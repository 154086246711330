import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { TerminalsFilters } from "./TerminalsFilters/TerminalsFilters.component";
import { TerminalsList } from "./TerminalsList/TerminalsList.component";
import { STerminalsPage } from "./TerminalsPageStyled/STerminalsPage.styled";
import { STerminalsPageContainer } from "./TerminalsPageStyled/STerminalsPageContainer.styled";

export const TerminalsPage = createComponent("TerminalsPage", () => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  useEffect(async () => {
    await terminalsPageService.controller.onMount();
  }, []);

  return (
    <STerminalsPage isArabic={isArabic}>
      <STerminalsPageContainer>
        <TerminalsFilters />
        <TerminalsList />
      </STerminalsPageContainer>
    </STerminalsPage>
  );
});
