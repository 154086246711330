import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowProps } from "./UsersChangeRow.props";
import { UsersChangeRowCommon } from "./UsersChangeRowCommon/UsersChangeRowCommon.component";
import { UsersChangeRowInput } from "./UsersChangeRowInput/UsersChangeRowInput.component";

export const UsersChangeRow = createComponent<UsersChangeRowProps>("UsersChangeRow", (props) => {
  if (props.hasChange) {
    return <UsersChangeRowInput service={props.service!} title={props.title} />;
  }

  return <UsersChangeRowCommon value={props.value!} title={props.title} />;
});
