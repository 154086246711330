import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_e7ecf2 } from "theme/setter/colorsValues/color_e7ecf2";
import { setBackgroundColor_rgba_196_237_255_40 } from "theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_196_237_255_40";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

type SBodyDataRowProps = {
  isFirst: boolean;
};

const isFirstSet = setProps<SBodyDataRowProps>(({ isFirst }) => isFirst)(css`
  border-top: none;
`);

export const SBodyDataRow = styled.div<SBodyDataRowProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 40px;

  border-top: 1px solid ${color_e7ecf2};

  ${setFlexDFAIC};
  ${isFirstSet};

  &:hover {
    ${setBackgroundColor_rgba_196_237_255_40};
  }
`;
