import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ActiveFilterList } from "./ActiveFilterList/ActiveFilterList.component";
import { SFilterList } from "./FilterListStyled/SFilterList.styled";
import { FilterSelector } from "./FilterSelector/FilterSelector.component";

export const FilterList = createComponent("FilterList", () => {
  const { service } = useFiltersContext();
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return (
    <SFilterList isArabic={isArabic}>
      <ActiveFilterList />
      <FilterSelector />
    </SFilterList>
  );
});
