import { styled } from "theme/default/styled";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexFDC } from "theme/setter/setFlexValues/setFlexFDC";

export const SFormLayout = styled.div`
  width: 100vw;
  height: 100vh;

  ${setFlexDFAIC};
  ${setFlexFDC};
  ${setBackgroundColor_f7faff};
`;
