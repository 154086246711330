import { ToastService } from "service/shared/others/ToastService/Toast.service";
import { ToastState } from "service/shared/others/ToastService/Toast.state";

export class ToastController {
  public readonly state: ToastState;

  public readonly service: ToastService;

  public constructor(service: ToastService, state: ToastState) {
    this.state = state;
    this.service = service;
  }

  public readonly onClick = (): void => {
    this.state.show.next(false);
    if (this.state.timeOut) {
      clearTimeout(this.state.timeOut);
    }
    this.state.animationTimeOut = setTimeout(() => {
      this.state.show.next(false);
      this.state.deleteToastCallBack(this.service);
    }, this.state.animationDelay);
  };
}
