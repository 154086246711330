import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteActivationCodeInput } from "./InviteActivationCodeInputStyled/SInviteActivationCodeInput.styled";

export const InviteActivationCodeInput = createComponent("InviteActivationCodeInput", () => {
  const { t } = useTranslation();
  return (
    <SInviteActivationCodeInput
      type="text"
      name="confirmPassword"
      placeholder={t("activateInvitePage.activationCode")}
      service={activateInvitePageService.state.activationCodeService}
    />
  );
});
