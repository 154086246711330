import { TableEntityController } from "service/shared/others/TableEntityService/TableEntity.controller";
import { TableEntityState } from "service/shared/others/TableEntityService/TableEntity.state";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { Dictionary } from "types/commonExtend/Dictionary";

export class TableEntityService<T extends Dictionary<string>> {
  public readonly state;

  public readonly controller: TableEntityController<T>;

  public constructor(getInitialHeaderCell: SetPageTableHandlerType, params?: { showCountElements?: boolean; disableMount?: boolean }) {
    this.state = new TableEntityState(getInitialHeaderCell, params);
    this.controller = new TableEntityController(this.state);
  }
}
