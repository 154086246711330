import React from "react";
import { styled } from "theme/default/styled";

export const SPopUpContainerEmitter = styled.div<React.HTMLAttributes<HTMLDivElement> & React.RefAttributes<HTMLDivElement>>`
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
