import { useState } from "packages/react";
import { CSVLink } from "packages/react-csv";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useRef } from "utils/libReplace/useRef";

export const DownloadLink = createComponent("DownloadLink", (props: { data: any[]; csvName: string | undefined; currentDate: string }) => {
  const { t } = useTranslation();
  const [downloaded, setDownloaded] = useState<boolean>(false);

  const linkRef = useRef<any>();

  useEffect(() => {
    if (!downloaded) {
      linkRef.current.link.click();
      setDownloaded(true);
    }
  }, []);

  return <CSVLink ref={linkRef} filename={`${t(props.csvName || "")}_${props.currentDate}.csv`} data={props.data} />;
});
