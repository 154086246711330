import { Filters } from "components/Filters/Filters.component";
import { paymentsPageService } from "service/shared/singletones/paymentsPageService/paymentsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const PaymentsPageFilters = createComponent("PaymentsPageFilters", () => {
  return (
    <Filters
      totalElements={paymentsPageService.state.filterNavigationService.state.totalElements}
      service={paymentsPageService.filterService}
      csvCallback={paymentsPageService.getTotalData}
      csvName={paymentsPageService.csvName}
    />
  );
});
