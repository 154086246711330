import React from "react";
import { color_b7c8dc, css, setBackgroundColor_ffffff, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const STableNavigation = styled.div<Props & React.HTMLAttributes<HTMLDivElement>>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  height: 50px;

  box-shadow: 0 0 11px ${color_b7c8dc};

  ${isArabicLang};
  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;
