import { RolesSelectOptionModel } from "model/RolesSelectOption.model";

import { createUserServiceFormService } from "../createUserServiceFormService/createUserServiceForm.service";
import { createUserRolesSelectFormState } from "./createUserRolesSelectForm.state";

class Controller {
  public readonly state = createUserRolesSelectFormState;

  public readonly onClickOption = (rolesModel: RolesSelectOptionModel) => (): void => {
    if (this.state.selectedOptions.value.includes(rolesModel)) {
      const newSelectedOptions = this.state.selectedOptions.value.filter((model) => model !== rolesModel);
      this.state.selectedOptions.next(newSelectedOptions);
    } else {
      this.state.selectedOptions.value.push(rolesModel);
      this.state.selectedOptions.next([...this.state.selectedOptions.value]);
    }
  };

  public readonly onClickBackButton = (): void => {
    this.state.open.next(false);
    this.state.selectedOptions.next(createUserServiceFormService.state.roles.value);
  };

  public readonly onClickApplyButton = (): void => {
    this.state.open.next(false);
    createUserServiceFormService.state.roles.next([...this.state.selectedOptions.value]);
  };

  public readonly onClickContainer = (): void => {
    this.state.open.next(true);
  };

  public readonly onMount = (): void => {};

  public readonly closeCallBack = (): void => {
    this.state.open.next(false);
    this.state.selectedOptions.next(createUserServiceFormService.state.roles.value);
  };
}

export const createUserRolesSelectFormController = new Controller();
