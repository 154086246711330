import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { InvoiceDetailsPageContent } from "./InvoiceDetailsPageContent/InvoiceDetailsPageContent.component";
import { SInvoiceDetailsPageLoader } from "./InvoiceDetailsPageStyled/SInvoiceDetailsPageLoader.styled";

export const InvoiceDetailsPage = createComponent("InvoiceDetailsPage", () => {
  const { t } = useTranslation();
  const { invoiceId } = useParams<{ invoiceId: string }>();

  useEffect(async () => {
    invoiceDetailsPageService.controller.setInvoiceId(invoiceId);
    await invoiceDetailsPageService.controller.getInvoice();
    return (): void => {
      invoiceDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(invoiceDetailsPageService.state.loaded);

  return (
    <DetailsLayout header={[{ title: t("invoiceDetailsPage.headerTitle"), src: routerPaths.invoices }]} title={`# ${invoiceId}`}>
      {loaded ? (
        <InvoiceDetailsPageContent />
      ) : (
        <SInvoiceDetailsPageLoader>
          <Loader />
        </SInvoiceDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
