import { css } from "packages/styled-components";
import React from "react";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";
import { isFinOn, isQi } from "utils/business/environment";

const finOn = css`
  padding: 4px 12px;
  border-radius: 14px;
  border: solid;
`;

const sngb = css`
  margin-top: 15px;
  padding: 4px 0;
`;
export const SSignInSendButton = styled.button<React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isFinOn || isQi ? finOn : sngb};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;
