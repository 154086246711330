import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useEffect, useState } from "packages/react";
import { useTranslation } from "react-i18next";
import { statisticPageService } from "service/shared/singletones/statisticPageService/statisticPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { StatisticConversionsContent } from "./StatisticConversionsContent/StatisticConversionsContent.component";

export const StatisticConversions = createComponent("StatisticConversions", () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  const loaded = useDefaultObservableBoolean(statisticPageService.state.loaded);
  const fetched = useDefaultObservableBoolean(statisticPageService.state.fetched);

  useEffect(() => {
    if (i18n.language !== currentLang) {
      setCurrentLang(i18n.language);
      statisticPageService.state.loaded.next(false);
      setTimeout(() => {
        statisticPageService.state.loaded.next(true);
      }, 100);
    }
  }, [i18n.language]);

  return loaded && fetched && <StatisticConversionsContent />;
});
