import { borderRadiusValues } from "theme/values/borderRadiusValues";
import { colorsOpacityValues } from "theme/values/colorsOpacityValues";
import { fontSizeValues } from "theme/values/fontSizeValues";
import { fontWeightValues } from "theme/values/fontWeightValues";
import { colorsValuesQi } from "theme/valuesQi/colorsValuesQi";

export const themeValuesQi = {
  colors: {
    ...colorsValuesQi,
    ...colorsOpacityValues,
  },
  fontSize: {
    ...fontSizeValues,
  },
  borderRadius: {
    ...borderRadiusValues,
  },
  fontWeightValues: {
    ...fontWeightValues,
  },
};
