import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { authenticatedService } from "service/common/authenticatedService/authenticated.service";
import { menuState } from "service/shared/singletones/menuService/menu.state";

class Controller {
  public readonly state = menuState;

  public readonly onMount = async (): Promise<void> => {
    await this.getUserEmail();
  };

  public readonly logout = async (): Promise<void> => {
    this.state.open.next(false);
    await authenticatedService.controller.logout();
  };

  public readonly toggleList = (): void => {
    this.state.open.next(!this.state.open.value);
  };

  public readonly closeMenu = (): void => {
    this.state.open.next(false);
  };

  private readonly getUserEmail = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.userInfo);
    this.state.email.next(data.email);
  };
}

export const menuController = new Controller();
