import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const paymentSystemTypeFilterModel = new FilterModel({
  id: 12,
  type: "select",
  title: t("paymentSystemTypeFilterModel.title"),
  name: "PAYMENT_SYSTEM",
  options: {
    optionList: [
      new OptionModel("paymentSystemTypeFilterModel.visa", "VISA"),
      new OptionModel("paymentSystemTypeFilterModel.mastercard", "MASTER_CARD"),
      new OptionModel("paymentSystemTypeFilterModel.mir", "MIR"),
    ],
  },
  langPath: "paymentSystemTypeFilterModel.title",
});
