import { TerminalModel } from "model/Terminal.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectStartNumberEndNumber } from "observables/StartNumberEndNumberObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { BehaviorSubjectTerminalModelArray } from "observables/TerminalModelArrayObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly filterValue: BehaviorSubjectString;

  public readonly appliedFilterValue: BehaviorSubjectString;

  public readonly terminalsList: BehaviorSubjectTerminalModelArray;

  public readonly filteredTerminalsList: BehaviorSubjectTerminalModelArray;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly terminalsCount: BehaviorSubjectStartNumberEndNumber;

  public constructor() {
    this.filterValue = new BehaviorSubjectString("");
    this.appliedFilterValue = new BehaviorSubjectString("");
    this.terminalsList = new BehaviorSubjectTerminalModelArray([]);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.filteredTerminalsList = setCombinedBehaviorSubject(this.setFilteredTerminalsList, this.terminalsList, this.appliedFilterValue);
    this.terminalsCount = setCombinedBehaviorSubject(this.setTerminalsCount, this.filteredTerminalsList);
  }

  private readonly setFilteredTerminalsList = (terminalsList: TerminalModel[], appliedFilterValue: string): TerminalModel[] => {
    return terminalsList.filter((terminal) => {
      const terminalIdIncludes = terminal.terminalId.toString().toLocaleLowerCase().includes(appliedFilterValue.toLocaleLowerCase());
      const merchantNameIncludes = terminal.merchantName.toString().toLocaleLowerCase().includes(appliedFilterValue.toLocaleLowerCase());
      return terminalIdIncludes || merchantNameIncludes;
    });
  };

  private readonly setTerminalsCount = (
    filteredTerminalsList: TerminalModel[]
  ): {
    start: number;
    end: number;
  } => {
    return {
      start: 0,
      end: filteredTerminalsList.length,
    };
  };
}

export const terminalsPageState = new State();
