import { userService } from "api/userService";
import { Table } from "components/Table/Table.component";
import { TransferDetailsModel } from "model/TransferDetails.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useDefaultObservableTransferDetailsModelNull } from "observables/TransferDetailsModelNullObservable";
import { useTranslation } from "react-i18next";
import { TableService } from "service/shared/others/TableService/Table.service";
import { transferDetailsPageService } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { CancelTransferButton } from "./CancelTransferButton/CancelTransferButton.component";
import { STransferDetailsPageContent } from "./TransferDetailsPageContentStyled/STransferDetailsPageContent.styled";
import { STransferDetailsPageContentCard } from "./TransferDetailsPageContentStyled/STransferDetailsPageContentCard.styled";
import { STransferDetailsPageContentHeader } from "./TransferDetailsPageContentStyled/STransferDetailsPageContentHeader.styled";
import { STransferDetailsPageContentTable } from "./TransferDetailsPageContentStyled/STransferDetailsPageContentTable.styled";
import { STransferDetailsPageContentTableHeader } from "./TransferDetailsPageContentStyled/STransferDetailsPageContentTableHeader.styled";
import { TransferRow } from "./TransferRow/TransferRow.component";
import { TransferSwitchedRow } from "./TransferSwitchedRow/TransferSwitchedRow.component";

export const TransferDetailsPageContent = createComponent("TransferDetailsPageContent", () => {
  const { t } = useTranslation();
  const transfer: TransferDetailsModel = useDefaultObservableTransferDetailsModelNull(transferDetailsPageService.state.transfer)!;
  const canExecuteTransfer = userService.canExecuteTransfer();
  const tableService: TableService = useDefaultObservableTableService(transferDetailsPageService.state.tableService)!;
  const cancelsIsEmpty: boolean = useDefaultObservableBoolean(transferDetailsPageService.state.cancelsIsEmpty)!;

  return (
    <STransferDetailsPageContent>
      <TransferRow title={t("transferDetailsPageContent.terminalIdTitle")} value={transfer.terminalId} />
      <TransferRow title={t("transferDetailsPageContent.creationDateTitle")} value={transfer.creationDate} />
      <TransferRow title={t("transferDetailsPageContent.fee")} value={transfer.fee} />
      <TransferRow title={t("transferDetailsPageContent.amountTitle")} value={transfer.amount} />
      <TransferRow title={t("transferDetailsPageContent.currencyTitle")} value={transfer.currency} />
      <TransferRow title={t("transferDetailsPageContent.statusTitle")} value={`${t(`operationStatusFilterModel.${transfer.status}`)} (${transfer.status})`} />
      <TransferRow title={t("transferDetailsPageContent.typeTitle")} value={transfer.type} />
      <TransferRow title={t("transferDetailsPageContent.requestIdTitle")} value={transfer.requestId} />
      {transfer.canceled !== null ? <TransferSwitchedRow title={t("transferDetailsPageContent.canceledTitle")} value={transfer.canceled} /> : null}

      {canExecuteTransfer && transfer.hasCancelAction ? <CancelTransferButton /> : null}

      {transfer.hasSourceDetails ? (
        <STransferDetailsPageContentCard>
          <STransferDetailsPageContentHeader>{t("transferDetailsPageContent.sourceTitle")}</STransferDetailsPageContentHeader>
          <TransferRow title={t("transferDetailsPageContent.sourceMaskedPan")} value={transfer.sourceMaskedPan} />
          <TransferRow title={t("transferDetailsPageContent.sourceExternalRrn")} value={transfer.sourceExternalRrn} />
          <TransferRow title={t("transferDetailsPageContent.sourceCardHolder")} value={transfer.sourceCardHolder} />
          <TransferRow title={t("transferDetailsPageContent.sourcePaymentSystem")} value={transfer.sourcePaymentSystem} />
          <TransferRow title={t("transferDetailsPageContent.sourceAuthId")} value={transfer.sourceAuthId} />
          <TransferRow title={t("transferDetailsPageContent.sourceResultCode")} value={transfer.sourceResultCode} />
          <TransferRow title={t("transferDetailsPageContent.sourceRrn")} value={transfer.sourceRrn} />
        </STransferDetailsPageContentCard>
      ) : null}

      {transfer.hasDestinationDetails ? (
        <div>
          <span>{t("transferDetailsPageContent.destinationTitle")}</span>
          <TransferRow title={t("transferDetailsPageContent.destinationMaskedPan")} value={transfer.destinationMaskedPan} />
          <TransferRow title={t("transferDetailsPageContent.destinationExternalRrn")} value={transfer.destinationExternalRrn} />
          <TransferRow title={t("transferDetailsPageContent.destinationPaymentSystem")} value={transfer.destinationPaymentSystem} />
          <TransferRow title={t("transferDetailsPageContent.destinationAuthId")} value={transfer.destinationAuthId} />
          <TransferRow title={t("transferDetailsPageContent.destinationResultCode")} value={transfer.destinationResultCode} />
          <TransferRow title={t("transferDetailsPageContent.destinationRrn")} value={transfer.destinationRrn} />
        </div>
      ) : null}

      {cancelsIsEmpty ? null : (
        <STransferDetailsPageContentTable>
          <STransferDetailsPageContentTableHeader>{t("transferDetailsPageContent.cancelButtonTitle")}</STransferDetailsPageContentTableHeader>
          <Table service={tableService} />
        </STransferDetailsPageContentTable>
      )}
    </STransferDetailsPageContent>
  );
});
