import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { useTranslation } from "react-i18next";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { SCreateUserFetchedMultipleSelect } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelect.styled";
import { SCreateUserFetchedMultipleSelectContainer } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectContainer.styled";
import { SCreateUserFetchedMultipleSelectMessage } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectMessage.styled";
import { SCreateUserFetchedMultipleSelectTitle } from "./CreateUserFetchedMultipleSelectStyled/SCreateUserFetchedMultipleSelectTitle.styled";

export const CreateUserFetchedMultipleSelect = createComponent("CreateUserFetchedMultipleSelect", () => {
  const { t } = useTranslation();
  const showError = useDefaultObservable<boolean>(createUserRolesSelectFormService.state.showError);
  const selectedOptions = useDefaultObservable<RolesSelectOptionModel[]>(createUserServiceFormService.state.roles);

  return (
    <SCreateUserFetchedMultipleSelect>
      <SCreateUserFetchedMultipleSelectTitle>{t("usersInfoPage.createMultipleSelectTitle")}</SCreateUserFetchedMultipleSelectTitle>
      <SCreateUserFetchedMultipleSelectContainer onClick={createUserRolesSelectFormService.controller.onClickContainer}>
        {`${t("usersInfoPage.createMultipleSelectContainer")} ${selectedOptions.length}`}
      </SCreateUserFetchedMultipleSelectContainer>
      {showError && <SCreateUserFetchedMultipleSelectMessage>{t("usersInfoPage.createMultipleSelectMessage")}</SCreateUserFetchedMultipleSelectMessage>}
    </SCreateUserFetchedMultipleSelect>
  );
});
