import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { InputProps } from "./Input.props";
import { SSignInFormInput } from "./InputStyled/SSignInFormInput.styled";

export const Input = createComponent<InputProps>("Input", (props) => {
  const { service, placeholder, className = "", name, type = "text", autoFocus = false, dataTestId } = props;

  const value = useDefaultObservableString(service.state.value);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SSignInFormInput
      className={className}
      showError={showError}
      data-test-id={dataTestId}
      onKeyUp={service.controller.onKeyUp}
      autoFocus={autoFocus}
      type={type}
      value={value}
      name={name}
      onChange={service.controller.onChange}
      placeholder={placeholder}
    />
  );
});
