import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { paymentsPageService } from "service/shared/singletones/paymentsPageService/paymentsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPaymentsTable } from "./PaymentsTableStyled/SPaymentsTable.styled";
import { SPaymentsTableContainer } from "./PaymentsTableStyled/SPaymentsTableContainer.styled";

export const PaymentsTable = createComponent("PaymentsTable", () => {
  const loaded = useDefaultObservableBoolean(paymentsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(paymentsPageService.tableService);

  return (
    <SPaymentsTable>
      <SPaymentsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SPaymentsTableContainer>
    </SPaymentsTable>
  );
});
