import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";

export const SH2HErrorMessageContainer = styled.div`
  padding: 12px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
