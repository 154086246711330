import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_93a5b8 } from "theme/setter/colorsValues/color_93a5b8";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

type SRolesSelectOptionProps = {
  isFirst: boolean;
};

const isFirstSet = setProps<SRolesSelectOptionProps>(({ isFirst }) => isFirst)(css`
  border-top: none;
`);

export const SRolesSelectOption = styled.button<SRolesSelectOptionProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 8px 0;

  border-top: 1px solid ${color_93a5b8};

  ${setFlexDFAIC};
  ${isFirstSet};
`;
