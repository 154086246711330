import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const statisticTypeFilterModel = new FilterModel({
  id: 5,
  type: "select",
  title: t("statisticTypeFilterModel.title"),
  name: "STATISTIC_TYPE",
  options: {
    optionList: [new OptionModel("statisticTypeFilterModel.QUANTITY", "QUANTITY"), new OptionModel("statisticTypeFilterModel.SUM", "SUM")],
  },
  langPath: "statisticTypeFilterModel.title",
});
