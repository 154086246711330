import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_95 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_95";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const STerminalsListTableHeader = styled.div`
  padding: 10px 20px;

  border-bottom: 1px solid ${color_rgba_0_0_0_95};

  ${setFlexDF};
`;
