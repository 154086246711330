import { styled } from "theme/default/styled";
import { setFontSize_24px } from "theme/setter/setFontSizeValues/setFontSize_24px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SInvoiceDetailsPageContentTableHeader = styled.div`
  margin-top: 32px;
  margin-bottom: 14px;

  ${setFontSize_24px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
`;
