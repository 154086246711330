import { useRolesFormContext } from "context/RolesForm.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SRolesFormBackButton } from "./RolesFormBackButtonStyled/SRolesFormBackButton.styled";

export const RolesFormBackButton = createComponent("RolesFormBackButton", () => {
  const { t } = useTranslation();
  const { service } = useRolesFormContext();

  return <SRolesFormBackButton onClick={service.controller.onClickBackButton}>{t("usersDetailsPage.rolesFormBackButton")}</SRolesFormBackButton>;
});
