import { userService } from "api/userService";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectGroupModelNull } from "observables/GroupModelNullObservable";
import { BehaviorSubjectStringArray } from "observables/StringArrayObservable";
import { BehaviorSubjectUserModelNull } from "observables/UserModelNullObservable";
import { ChangeEmailPhoneInputService } from "service/shared/others/ChangeEmailPhoneInputService/ChangeEmailPhoneInput.service";
import { ChangePasswordInputService } from "service/shared/others/ChangePasswordInputService/ChangePasswordInput.service";
import { PasswordConfigType } from "types/business/PasswordConfigType";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public readonly passwordConfig: PasswordConfigType;

  public readonly user: BehaviorSubjectUserModelNull;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly showDigitsError: BehaviorSubjectBoolean;

  public readonly showLengthError: BehaviorSubjectBoolean;

  public readonly showLowercaseLettersError: BehaviorSubjectBoolean;

  public readonly showRussianLetterError: BehaviorSubjectBoolean;

  public readonly showSpecialSymbolsError: BehaviorSubjectBoolean;

  public readonly showUppercaseLettersError: BehaviorSubjectBoolean;

  public readonly showCurrentPasswordEqualNewPasswordError: BehaviorSubjectBoolean;

  public readonly showNewPasswordNotEqualRepeatedNewPasswordError: BehaviorSubjectBoolean;

  public readonly showCapsLockError: BehaviorSubjectBoolean;

  public readonly showErrors: BehaviorSubjectBoolean;

  public readonly currentPassword: ChangePasswordInputService;

  public readonly newPassword: ChangePasswordInputService;

  public readonly repeatedNewPassword: ChangePasswordInputService;

  public readonly email: ChangeEmailPhoneInputService;

  public readonly phoneNumber: ChangeEmailPhoneInputService;

  public readonly userData: BehaviorSubjectGroupModelNull;

  public readonly errorTextArray: BehaviorSubjectStringArray;

  public constructor() {
    this.passwordConfig = userService.getPasswordConfig();

    this.userData = new BehaviorSubjectGroupModelNull(null);
    this.user = new BehaviorSubjectUserModelNull(null);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.showDigitsError = new BehaviorSubjectBoolean(false);
    this.showLengthError = new BehaviorSubjectBoolean(false);
    this.showLowercaseLettersError = new BehaviorSubjectBoolean(false);
    this.showRussianLetterError = new BehaviorSubjectBoolean(false);
    this.showSpecialSymbolsError = new BehaviorSubjectBoolean(false);
    this.showUppercaseLettersError = new BehaviorSubjectBoolean(false);
    this.showCurrentPasswordEqualNewPasswordError = new BehaviorSubjectBoolean(false);
    this.showNewPasswordNotEqualRepeatedNewPasswordError = new BehaviorSubjectBoolean(false);
    this.showCapsLockError = new BehaviorSubjectBoolean(false);
    this.showErrors = new BehaviorSubjectBoolean(false);
    this.currentPassword = new ChangePasswordInputService();
    this.newPassword = new ChangePasswordInputService();
    this.repeatedNewPassword = new ChangePasswordInputService();
    this.email = new ChangeEmailPhoneInputService();
    this.phoneNumber = new ChangeEmailPhoneInputService();

    this.showDigitsError = setCombinedBehaviorSubject(
      this.setShowDigitsError,
      this.currentPassword.state.digitsState,
      this.newPassword.state.digitsState,
      this.repeatedNewPassword.state.digitsState
    );

    this.showLengthError = setCombinedBehaviorSubject(
      this.setShowLengthError,
      this.currentPassword.state.lengthState,
      this.newPassword.state.lengthState,
      this.repeatedNewPassword.state.lengthState
    );

    this.showLowercaseLettersError = setCombinedBehaviorSubject(
      this.setShowLowercaseLettersError,
      this.currentPassword.state.lowercaseLettersState,
      this.newPassword.state.lowercaseLettersState,
      this.repeatedNewPassword.state.lowercaseLettersState
    );

    this.showRussianLetterError = setCombinedBehaviorSubject(
      this.setShowRussianLetterError,
      this.currentPassword.state.russianLetterState,
      this.newPassword.state.russianLetterState,
      this.repeatedNewPassword.state.russianLetterState
    );

    this.showSpecialSymbolsError = setCombinedBehaviorSubject(
      this.setShowSpecialSymbolsError,
      this.currentPassword.state.specialSymbolsState,
      this.newPassword.state.specialSymbolsState,
      this.repeatedNewPassword.state.specialSymbolsState
    );

    this.showUppercaseLettersError = setCombinedBehaviorSubject(
      this.setShowUppercaseLettersError,
      this.currentPassword.state.uppercaseLettersState,
      this.newPassword.state.uppercaseLettersState,
      this.repeatedNewPassword.state.uppercaseLettersState
    );

    this.showCapsLockError = setCombinedBehaviorSubject(
      this.setShowCapsLockError,
      this.currentPassword.state.capsLockState,
      this.newPassword.state.capsLockState,
      this.repeatedNewPassword.state.capsLockState
    );

    this.errorTextArray = setCombinedBehaviorSubject(
      this.setErrorTextArray,
      this.showDigitsError,
      this.showLengthError,
      this.showLowercaseLettersError,
      this.showSpecialSymbolsError,
      this.showUppercaseLettersError,
      this.showCurrentPasswordEqualNewPasswordError,
      this.showNewPasswordNotEqualRepeatedNewPasswordError
    );
  }

  public readonly setShowDigitsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLengthError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowLowercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowRussianLetterError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowSpecialSymbolsError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setShowUppercaseLettersError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };

  public readonly setErrorTextArray = (
    showDigitsError: boolean,
    showLengthError: boolean,
    showLowercaseLettersError: boolean,
    showSpecialSymbolsError: boolean,
    showUppercaseLettersError: boolean,
    showCurrentPasswordEqualNewPasswordError: boolean,
    showNewPasswordNotEqualRepeatedNewPasswordError: boolean
  ): string[] => {
    const errorTextArray: string[] = [];

    if (showDigitsError) errorTextArray.push("changePasswordSection.digitsError");
    if (showLengthError) errorTextArray.push("changePasswordSection.lengthError");
    if (showLowercaseLettersError) errorTextArray.push("changePasswordSection.lowercaseLettersError");
    if (showSpecialSymbolsError) errorTextArray.push("changePasswordSection.specialSymbolsError");
    if (showUppercaseLettersError) errorTextArray.push("changePasswordSection.uppercaseLettersError");
    if (showCurrentPasswordEqualNewPasswordError) errorTextArray.push("changePasswordSection.showCurrentPasswordEqualNewPasswordError");
    if (showNewPasswordNotEqualRepeatedNewPasswordError) errorTextArray.push("changePasswordSection.showNewPasswordNotEqualRepeatedNewPasswordError");

    return errorTextArray;
  };

  private readonly setShowCapsLockError = (currentPassword: boolean, newPassword: boolean, repeatedNewPassword: boolean): boolean => {
    return currentPassword || newPassword || repeatedNewPassword;
  };
}

export const userInfoPageState = new State();
