import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const transferIdFilterModel = new FilterModel({
  id: 2,
  type: "text",
  title: t("transferIdFilterModel.title"),
  name: "TRANSFER_ID",
  options: {
    maxLength: 36,
  },
  langPath: "transferIdFilterModel.title",
});
