import { useSupportArticleContext } from "context/SuportArticle.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ApplyChangesButton } from "./ApplyChangesButton/ApplyChangesButton.component";
import { SSupportArticleContent } from "./SupportArticleContentStyled/SSupportArticleContent.styled";
import { SSupportArticleContentHeader } from "./SupportArticleContentStyled/SSupportArticleContentHeader.styled";
import { SupportArticleDescriptionInput } from "./SupportArticleDescriptionInput/SupportArticleDescriptionInput.component";
import { SupportArticleEmailInput } from "./SupportArticleEmailInput/SupportArticleEmailInput.component";
import { SupportArticleSelect } from "./SupportArticleSelect/SupportArticleSelect.component";

export const SupportArticleContent = createComponent("SupportArticleContent", () => {
  const { service } = useSupportArticleContext();

  const formIsChanged = useDefaultObservableBoolean(service.state.formIsChanged);

  return (
    <SSupportArticleContent formIsChanged={formIsChanged}>
      <SSupportArticleContentHeader>
        <SupportArticleSelect />
        <SupportArticleEmailInput />
      </SSupportArticleContentHeader>

      <SupportArticleDescriptionInput />
      <ApplyChangesButton />
    </SSupportArticleContent>
  );
});
