import React from "react";
import { css, setColor_8bc3f5, setFontSize_14px, setFontWeight600, setProps, styled } from "theme";

type SFilterButtonTitleProps = {
  isArabic: boolean;
};

const isArabicLang = setProps<SFilterButtonTitleProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SFilterButtonTitleProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SFilterButtonTitle = styled.div<SFilterButtonTitleProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_8bc3f5};
`;
