import React from "react";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SCustomDateTimeButton = styled.button.attrs((props) => ({
  type: "button", // Set a default button type
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 4px 0;

  ${setFontSize_14px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;
