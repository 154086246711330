import { useSupportArticleContext } from "context/SuportArticle.context";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { SSupportArticleSelect } from "./SupportArticleSelectStyled/SSupportArticleSelect.styled";

export const SupportArticleSelect = createComponent("SupportArticleSelect", () => {
  const { service } = useSupportArticleContext();

  const typeValue = useDefaultObservableOptionModel(service.state.typeValue);

  return <SSupportArticleSelect optionList={service.state.typeOptionList} value={typeValue} onChange={service.controller.onChangeTypeValue} />;
});
