import { SignInInputController } from "service/shared/others/SignInInputService/SignInInput.controller";
import { SignInInputState } from "service/shared/others/SignInInputService/SignInInput.state";

type InputServiceParams = {
  regexp?: RegExp;
  required?: true;
};

export class SignInInputService {
  public readonly state: SignInInputState;

  public readonly controller: SignInInputController;

  public constructor(params?: InputServiceParams) {
    this.state = new SignInInputState(params);
    this.controller = new SignInInputController(this.state);
  }
}
