/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Plus = createIcon("Plus", (props) => {
  const { width = 11, height = 11, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#8BC3F5" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 11 11" fill="none">
      <path d="M1.18176 5.63428L10.0194 5.63428" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
      <path d="M5.60062 1.21533L5.60062 10.053" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    </svg>
  );
});
