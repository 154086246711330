import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
  width: 100%;
`);

export const SPaymentCancelTable = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFFDC};
  ${isArabicLang};
`;
