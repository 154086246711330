import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectRolesSelectOptionModelArray } from "observables/RolesSelectOptionModelArrayObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setTitledValue } from "service/shared/others/UsersChangeRowRolesService/setTitledValue";

export class UsersChangeRowRolesState {
  public readonly titledValue: BehaviorSubjectString;

  public readonly updateUserCallBack: () => void;

  public readonly open: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly mounted: BehaviorSubjectBoolean;

  public readonly selectedOptions: BehaviorSubjectRolesSelectOptionModelArray;

  public readonly rolesSelectOptionArray: BehaviorSubjectRolesSelectOptionModelArray;

  public constructor(initialValue: RolesSelectOptionModel[], updateUserCallBack: () => void) {
    this.updateUserCallBack = updateUserCallBack;
    this.open = new BehaviorSubjectBoolean(false);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.mounted = new BehaviorSubjectBoolean(false);
    this.selectedOptions = new BehaviorSubjectRolesSelectOptionModelArray(initialValue);
    this.titledValue = new BehaviorSubjectString(setTitledValue(this.selectedOptions.value));
    this.rolesSelectOptionArray = new BehaviorSubjectRolesSelectOptionModelArray([]);
  }
}
