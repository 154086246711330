import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetPasswordApplyButton } from "./ResetPasswordApplyButtonStyled/SResetPasswordApplyButton.styled";

export const ResetPasswordApplyButton = createComponent("ResetPasswordApplyButton", () => {
  const { t } = useTranslation();
  return (
    <SResetPasswordApplyButton onClick={usersDetailsPageService.controller.onClickApplyButton}>
      {t("usersDetailsPage.resetPasswordApplyButton")}
    </SResetPasswordApplyButton>
  );
});
