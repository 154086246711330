import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { invoicesFilterConfig } from "config/filterConfig/invoicesFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { t } from "i18n";
import { BehaviorSubjectAnyArray } from "observables/AnyArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectInvoiceTypeArray } from "observables/InvoiceTypeArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { InvoiceType } from "types/business/InvoiceType";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<InvoiceType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly invoices: BehaviorSubjectInvoiceTypeArray;

  public readonly csvData: BehaviorSubjectAnyArray;

  public readonly csvName: string;

  public constructor() {
    super(tableHeaderInitialCell.invoicesPage);
    this.csvName = "filters.cvsNameInvoices";
    this.invoices = new BehaviorSubjectInvoiceTypeArray([]);
    this.csvData = new BehaviorSubjectAnyArray([]);
    this.filterService = new FiltersService(invoicesFilterConfig, this.applyFilter);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.invoices, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getInvoices().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceInvoice = (dataItem: any): InvoiceType => {
    return {
      amount: dataItem.amount || "",
      invoiceId: dataItem.orderId || "",
      phone: dataItem.customerInfo.phone || "",
      terminalId: dataItem.terminalId || "",
      creationDate: convertFetchedDateToTableShowDate(dataItem.creationDate) || "",
      email: dataItem.customerInfo.email || "",
      status: dataItem.status || "",
    };
  };

  private readonly produceInvoiceData = (data: any[]): InvoiceType[] => {
    return data.map(this.produceInvoice);
  };

  private readonly fetchInvoices = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = { page, queries, size, sort };

    const { data } = await client.post(apiPaths.invoiceInfo, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.content;
  };

  public readonly getCsvData = (data: any[]): any[] => {
    const names = {
      invoiceId: "invoicesPageConfig.invoiceId",
      email: "invoicesPageConfig.email",
      phone: "invoicesPageConfig.phone",
      amount: "invoicesPageConfig.amount",
      creationDate: "invoicesPageConfig.creationDate",
      status: "invoicesPageConfig.status",
      terminalId: "invoicesPageConfig.terminalId",
    };

    return data.map((dataCell) => {
      return {
        [t(names.terminalId)]: dataCell.terminalId,
        [t(names.invoiceId)]: dataCell.invoiceId,
        [t(names.email)]: dataCell.email,
        [t(names.phone)]: dataCell.phone,
        [t(names.amount)]: dataCell.amount,
        [t(names.status)]: dataCell.status,
        [t(names.creationDate)]: dataCell.creationDate,
      };
    });
  };

  private readonly getInvoices = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const data: any[] = await this.fetchInvoices();
      const produceData = this.produceInvoiceData(data);
      const produceCsvData = this.getCsvData(produceData);
      this.csvData.next(produceCsvData);
      this.invoices.next(produceData);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getInvoices();
  };

  public readonly fetchTotalData = async (): Promise<any[]> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const sort = this.state.sort.value;
    const query = { page: 0, queries, size: this.state.filterNavigationService.state.totalElements.value, sort };
    const { data } = await client.post(apiPaths.invoiceInfo, query);
    return data.content;
  };

  public readonly getTotalData = async (): Promise<any[]> => {
    const data = await this.fetchTotalData();
    const produceData = this.produceInvoiceData(data);
    const csvData = this.getCsvData(produceData);
    return csvData;
  };
}

export const invoicesPageService = new Service();
