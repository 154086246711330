import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SelectProps } from "./Select.props";
import { SInvoiceCreateSelect } from "./SelectStyled/SInvoiceCreateSelect.styled";
import { SInvoiceCreateSelectContainer } from "./SelectStyled/SInvoiceCreateSelectContainer.styled";
import { SInvoiceCreateSelectErrorMessage } from "./SelectStyled/SInvoiceCreateSelectErrorMessage.styled";
import { SInvoiceCreateSelectSelect } from "./SelectStyled/SInvoiceCreateSelectSelect.styled";
import { SInvoiceCreateSelectSelectContainer } from "./SelectStyled/SInvoiceCreateSelectSelectContainer.styled";
import { SInvoiceCreateSelectTitle } from "./SelectStyled/SInvoiceCreateSelectTitle.styled";

export const Select = createComponent<SelectProps>("Select", (props) => {
  const { t } = useTranslation();
  const { service: currentService } = props;

  const value = useDefaultObservableOptionModel(currentService.state.value);
  const isSelected = useDefaultObservableBoolean(currentService.state.isSelected);
  const valid = useDefaultObservableBoolean(currentService.state.valid);
  const showError = useDefaultObservableBoolean(currentService.state.showError);

  return (
    <SInvoiceCreateSelect>
      <SInvoiceCreateSelectTitle>{currentService.state.langPath ? t(currentService.state.langPath) : currentService.state.title}</SInvoiceCreateSelectTitle>

      <SInvoiceCreateSelectContainer>
        <SInvoiceCreateSelectSelectContainer>
          <SInvoiceCreateSelectSelect
            isError={showError && !valid}
            isSelected={isSelected}
            onChange={currentService.controller.onChange}
            value={value}
            selectTitle={currentService.state.titleOption.value}
            selectTitleValue={currentService.state.titleOption.value}
            optionList={currentService.state.options}
          />
        </SInvoiceCreateSelectSelectContainer>
      </SInvoiceCreateSelectContainer>

      {showError ? (
        <SInvoiceCreateSelectErrorMessage>
          {currentService.state.langPathError ? t(currentService.state.langPathError) : currentService.state.errorMessage}
        </SInvoiceCreateSelectErrorMessage>
      ) : null}
    </SInvoiceCreateSelect>
  );
});
