type OptionConfig = {
  isTitle?: boolean;
  data?: string[];
};

export class OptionModel {
  public readonly title: string;

  public readonly value: string;

  public readonly isTitle: boolean;

  public readonly data: string[];

  public readonly langPath: string;

  public constructor(title: string, value: string, options?: OptionConfig, langPath?: string) {
    this.title = title;
    this.value = value;
    this.isTitle = options?.isTitle || false;
    this.data = options?.data || [];
    this.langPath = langPath || "";
  }
}
