import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetButton } from "./ResetButtonStyled/SResetButton.styled";

export const ResetButton = createComponent("ResetButton", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  const filterListIsEmpty = useDefaultObservableBoolean(service.state.filterListIsEmpty);

  return (
    <SResetButton filterListIsEmpty={filterListIsEmpty} onClick={service.controller.onClickReset}>
      {t("filters.resetButton")}
    </SResetButton>
  );
});
