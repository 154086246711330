import { Observable } from "packages/rxjs";
import { BehaviorSubject } from "types/libReplace/BehaviorSubject";

export const convertObservableToBehaviorSubject = <T>(observable: Observable<T>, initValue: T): BehaviorSubject<T> => {
  const subject = new BehaviorSubject(initValue);

  observable.subscribe((value) => subject.next(value));

  return subject;
};
