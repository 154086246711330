import { ActivateInviteInputState } from "service/shared/others/ActivateInviteInputService/ActivateInviteInput.state";
import { KeyboardCustomEvent } from "types/libExtend/KeyboardCustomEvent";
import { passwordValidateFunction } from "utils/business/passwordValidateFunction/passwordValidateFunction";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class ActivateInviteInputController {
  public readonly state: ActivateInviteInputState;

  public readonly onChange = onChangeHelper((text: string): void => {
    const errorObject = passwordValidateFunction(text);

    this.state.digitsState.next(errorObject.hasDigitsError);
    this.state.lengthState.next(errorObject.hasLengthError);
    this.state.lowercaseLettersState.next(errorObject.hasLowercaseLettersError);
    this.state.russianLetterState.next(errorObject.hasRussianLetterError);
    this.state.specialSymbolsState.next(errorObject.hasSpecialSymbolsError);
    this.state.uppercaseLettersState.next(errorObject.hasUppercaseLettersError);

    this.state.value.next(text);
  });

  public constructor(state: ActivateInviteInputState) {
    this.state = state;
  }

  public readonly clearForm = (): void => {
    this.state.value.next("");
  };

  public readonly onKeyUp = (event: KeyboardCustomEvent): void => {
    const capsLockState = event.getModifierState("CapsLock");
    this.state.capsLockState.next(capsLockState);
  };
}
