import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

interface SFilterSelectorProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectorProps>(({ isArabic }) => isArabic)(css`
  margin-left: 40px;
`);

const isNotArabicLang = setProps<SFilterSelectorProps>(({ isArabic }) => !isArabic)(css`
  margin-right: 40px;
`);

export const SFilterSelector = styled.div<SFilterSelectorProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 180px;

  ${setFlexDFFDC};
  ${isArabicLang};
  ${isNotArabicLang};
`;
