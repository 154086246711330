/* eslint-disable max-len */

import { colorsValues } from "theme/values/colorsValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const FileUpload = createIcon("FileUpload", (props) => {
  const { width = 24, height = 24, color = props.isFinOn ? colorsValuesFin.main.icon_fill : colorsValues.main.icon_fill } = props;

  return (
    <svg width={width} height={height} enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill={color}>
      <g>
        <rect fill="none" height="24" width="24" />
      </g>
      <g>
        <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z" />
      </g>
    </svg>
  );
});
