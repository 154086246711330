import i18next from "i18next";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectSortDictionary } from "observables/SortDictionaryObservable";
import { FiltersNavigationService } from "service/shared/others/FiltersNavigationService/FiltersNavigation.service";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { SortDictionary } from "types/business/SortDictionary";

export class TableEntityState {
  public readonly loaded: BehaviorSubjectBoolean;

  public readonly filterNavigationService: FiltersNavigationService;

  public prevCurrentPage: number | null;

  public prevSizeOption: number | null;

  public prevIsMounted: boolean;

  public prevSort: SortDictionary | null;

  public readonly desc: BehaviorSubjectBoolean;

  public readonly isMounted: BehaviorSubjectBoolean;

  public readonly sort: BehaviorSubjectSortDictionary;

  public rewardDate!: string;

  public readonly getInitialHeaderCell: SetPageTableHandlerType;

  public constructor(getInitialHeaderCell: SetPageTableHandlerType, params?: { showCountElements?: boolean; disableMount?: boolean }) {
    this.prevCurrentPage = null;
    this.prevSizeOption = null;
    this.prevIsMounted = true;
    this.prevSort = null;
    this.desc = new BehaviorSubjectBoolean(false);
    this.isMounted = new BehaviorSubjectBoolean(params?.disableMount ?? false);
    this.loaded = new BehaviorSubjectBoolean(!!params?.disableMount);
    this.sort = new BehaviorSubjectSortDictionary({});
    this.filterNavigationService = new FiltersNavigationService({ showCountElements: params?.showCountElements, initialArabic: i18next.language === "ar" });
    this.getInitialHeaderCell = getInitialHeaderCell;
  }
}
