import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { CustomSelectService } from "service/shared/others/CustomSelectService/CustomSelect.service";
import { forwardRef } from "utils/libReplace/forwardRef";
import { useImperativeHandle } from "utils/libReplace/useImperativeHandle";
import { useMemo } from "utils/libReplace/useMemo";

import { CustomSelectProps } from "./CustomSelect.props";
import { SCustomSelect } from "./CustomSelectStyled/SCustomSelect.styled";
import { SCustomSelectContainer } from "./CustomSelectStyled/SCustomSelectContainer.styled";
import { SCustomSelectEmitter } from "./CustomSelectStyled/SCustomSelectEmitter.styled";
import { SCustomSelectInput } from "./CustomSelectStyled/SCustomSelectInput.styled";
import { SelectOptionList } from "./SelectOptionList/SelectOptionList.component";

type CustomSelectRef = {
  clearForm?: () => void;
};

export const CustomSelect = forwardRef<CustomSelectRef, CustomSelectProps>((props, ref) => {
  const { t, i18n } = useTranslation();
  const { listClassName = "", className = "", onChange, optionList, value } = props;
  const isArabic = i18n.language === "ar";

  const service = useMemo(() => {
    return new CustomSelectService(optionList, onChange);
  }, [optionList]);

  const searchValue = useDefaultObservableString(service.state.searchValue);
  const isDropDownOpened = useDefaultObservableBoolean(service.state.isDropDownOpened);

  useImperativeHandle(
    ref,
    () => ({
      clearForm: (): void => {
        service.controller.clearForm();
      },
    }),
    [searchValue]
  );

  const optionValue = t(value.title);
  const selectedValue = isDropDownOpened ? searchValue : optionValue;

  return (
    <SCustomSelect className={className}>
      <SCustomSelectContainer>
        <SCustomSelectInput isArabic={isArabic} onFocus={service.controller.onFocusInput} value={selectedValue} onChange={service.controller.onChangeInput} />

        <SelectOptionList className={listClassName} service={service} value={optionValue} />
      </SCustomSelectContainer>

      {isDropDownOpened ? <SCustomSelectEmitter onClick={service.controller.onClickEmitter} /> : null}
    </SCustomSelect>
  );
});
