import { ResetPasswordInputController } from "service/shared/others/ResetPasswordInputService/ResetPasswordInput.controller";
import { ResetPasswordInputState } from "service/shared/others/ResetPasswordInputService/ResetPasswordInput.state";

type InputServiceParams = {
  regexp?: RegExp;
  required?: true;
};

export class ResetPasswordInputService {
  public readonly state: ResetPasswordInputState;

  public readonly controller: ResetPasswordInputController;

  public constructor(params: InputServiceParams) {
    this.state = new ResetPasswordInputState(params);
    this.controller = new ResetPasswordInputController(this.state);
  }
}
