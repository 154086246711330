import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { ImgButton } from "../ImgButton/ImgButton.component";
import { RightControlsProps } from "./RightControls.props";
import { SRightControls } from "./RightControlsStyled/SRightControls.styled";
import { SRightControlsTitle } from "./RightControlsStyled/SRightControlsTitle.styled";

export const RightControls = createComponent<RightControlsProps>("RightControls", (props) => {
  const { service } = props;

  const rightArrowIconActive = useDefaultObservableBoolean(service.state.rightArrowIconActive);
  const doubleRightArrowIconActive = useDefaultObservableBoolean(service.state.doubleRightArrowIconActive);

  return (
    <SRightControls>
      <ImgButton active={rightArrowIconActive} name="PaginationRightArrow" onClick={service.controller.onClickRightArrowIcon} alt="right arrow icon" />
      <SRightControlsTitle>10</SRightControlsTitle>
      <ImgButton
        active={doubleRightArrowIconActive}
        name="PaginationRightDoubleArrow"
        onClick={service.controller.onClickDoubleRightArrowIcon}
        alt="double right arrow icon"
      />
    </SRightControls>
  );
});
