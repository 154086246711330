import { auditPage } from "config/tableInitialHeaderCells/auditPage";
import { h2hPage } from "config/tableInitialHeaderCells/h2hPage";
import { invoicesPage } from "config/tableInitialHeaderCells/invoicesPage";
import { paymentDetailsPageCancel } from "config/tableInitialHeaderCells/paymentDetailsPageCancel";
import { paymentDetailsPageConfirm } from "config/tableInitialHeaderCells/paymentDetailsPageConfirm";
import { paymentDetailsPageRefund } from "config/tableInitialHeaderCells/paymentDetailsPageRefund";
import { paymentsPage } from "config/tableInitialHeaderCells/paymentsPage";
import { refundsPage } from "config/tableInitialHeaderCells/refundsPage";
import { rewardDetailsPage } from "config/tableInitialHeaderCells/rewardDetailsPage";
import { rewardsPage } from "config/tableInitialHeaderCells/rewardsPage";
import { statisticPage } from "config/tableInitialHeaderCells/statisticPage";
import { transferDetailsPage } from "config/tableInitialHeaderCells/transferDetailsPage";
import { transfersPage } from "config/tableInitialHeaderCells/transfersPage";

export const tableHeaderInitialCell = {
  rewardDetailsPage,
  statisticPage,
  rewardsPage,
  auditPage,
  invoicesPage,
  paymentsPage,
  h2hPage,
  refundsPage,
  transfersPage,
  transferDetailsPage,
  paymentDetailsPageCancel,
  paymentDetailsPageConfirm,
  paymentDetailsPageRefund,
};
