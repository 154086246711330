import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { SvgImage } from "components/SvgImage/SvgImage.component";
import { GroupModel } from "model/Group.model";
import { GroupUserModel } from "model/GroupUser.model";
import { useTranslation } from "react-i18next";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";
import { useState } from "utils/libReplace/useState";

import { GroupProps } from "./Group.props";
import { SGroup } from "./GroupStyled/SGroup.styled";
import { SGroupImage } from "./GroupStyled/SGroupImage.styled";
import { SGroupTitle } from "./GroupStyled/SGroupTitle.styled";
import { GroupUser } from "./GroupUser/GroupUser.component";

export const Group = createComponent<GroupProps>("Group", (props) => {
  const { i18n } = useTranslation();
  const { groupModel, orderNumber } = props;

  const [groupData, setGroupData] = useState<GroupModel | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isArabic = i18n.language === "ar";

  const onFetchData = async (): Promise<void> => {
    setLoaded(true);
    const { data } = await client.post(apiPaths.adminGroupInfo, {
      groupId: groupModel.id,
    });
    setGroupData(data);
    setLoaded(false);
  };
  const onClickButton = async (): Promise<void> => {
    if (!isOpen) {
      await onFetchData();
    }
    setIsOpen(!isOpen);
  };
  const renderGroup: RenderFunctionType<GroupModel> = (itemGroupModel: GroupModel, index) => {
    return <Group orderNumber={index} groupModel={itemGroupModel} key={index} />;
  };
  const renderUser: RenderFunctionType<GroupUserModel> = (groupUserModel: GroupUserModel, index) => {
    return <GroupUser orderNumber={index} groupUserModel={groupUserModel} key={index} />;
  };

  return (
    <SGroup loaded={loaded} isFirst={orderNumber === 0}>
      <SGroupTitle isArabic={isArabic} onClick={onClickButton}>
        <SGroupImage isArabic={isArabic} isOpen={isOpen}>
          <SvgImage name="ArrowDown" />
        </SGroupImage>
        {groupModel.description}
      </SGroupTitle>

      {isOpen ? (
        <div>
          {groupData?.groups.length !== 0 ? groupData?.groups.map(renderGroup) : null}
          {groupData?.users.length !== 0 ? groupData?.users.map(renderUser) : null}
        </div>
      ) : null}
    </SGroup>
  );
});
