import { UsersChangeRowRolesService } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.service";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type RolesFormContextModel = {
  service: UsersChangeRowRolesService;
  hasChange: boolean;
};

export const RolesFormContext = createContext<RolesFormContextModel>({} as RolesFormContextModel);

export const useRolesFormContext = (): RolesFormContextModel => useContext(RolesFormContext);
