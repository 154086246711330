import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const auditUserIdModel = new FilterModel({
  id: 25,
  type: "fetched-select",
  title: t("auditUserIdModel.title"),
  name: "EXECUTOR",
  options: {
    optionListCallBack: async (): Promise<OptionModel[]> => {
      const { data } = await client.post(apiPaths.adminGroupUsers);

      return data.users.map((element: any) => {
        const email = element.email || "";
        const userId = element.id || "";
        return new OptionModel(`${email}`, `${userId}`);
      });
    },
  },
  langPath: "auditUserIdModel.title",
});
