import { Filters } from "components/Filters/Filters.component";
import { rewardsPageService } from "service/shared/singletones/rewardsPageService/rewardsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardsHeader } from "./RewardsHeaderStyled/SRewardsHeader.styled";
import { SRewardsHeaderFilter } from "./RewardsHeaderStyled/SRewardsHeaderFilter.styled";

export const RewardsHeader = createComponent("RewardsHeader", () => {
  return (
    <SRewardsHeader>
      <SRewardsHeaderFilter>
        <Filters service={rewardsPageService.filterService} />
      </SRewardsHeaderFilter>
    </SRewardsHeader>
  );
});
