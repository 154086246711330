import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

interface SRangeInputProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SRangeInputProps>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);

export const SRangeInput = styled.div<SRangeInputProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100%;

  ${setFlexDFFDC};
  ${isArabicLang};
`;
