import { HeaderCellModel } from "model/HeaderCell.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectPaymentModelWithPaymentIdArray } from "observables/PaymentModelWithPaymentIdArrayObservable";
import { BehaviorSubjectRefundDetailsModelNull } from "observables/RefundDetailsModelNullObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";

class State {
  public readonly refund: BehaviorSubjectRefundDetailsModelNull;

  public readonly loaded: BehaviorSubjectBoolean;

  public tableService!: BehaviorSubjectTableService;

  public refundId!: string;

  public initialHeaderCellList!: HeaderCellModel[];

  public payments!: BehaviorSubjectPaymentModelWithPaymentIdArray;

  public constructor() {
    this.refund = new BehaviorSubjectRefundDetailsModelNull(null);
    this.loaded = new BehaviorSubjectBoolean(false);
  }
}

export const refundDetailsPageState = new State();
