import { Fragment } from "components/Fragment/Fragment.component";
import { useTranslation } from "react-i18next";
import { DetailsLayoutHeader } from "types/business/DetailsLayoutHeader";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { DetailsLayoutProps } from "./DetailsLayout.props";
import { SDetailsLayout } from "./DetailsLayoutStyled/SDetailsLayout.styled";
import { SDetailsLayoutContainer } from "./DetailsLayoutStyled/SDetailsLayoutContainer.styled";
import { SDetailsLayoutDivider } from "./DetailsLayoutStyled/SDetailsLayoutDivider.styled";
import { SDetailsLayoutHeader } from "./DetailsLayoutStyled/SDetailsLayoutHeader.styled";
import { SDetailsLayoutHeaderContainer } from "./DetailsLayoutStyled/SDetailsLayoutHeaderContainer.styled";
import { SDetailsLayoutTitle } from "./DetailsLayoutStyled/SDetailsLayoutTitle.styled";

export const DetailsLayout = createComponent<DetailsLayoutProps>("DetailsLayout", (props) => {
  const { children, header = [], title } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const renderHeader: RenderFunctionType<DetailsLayoutHeader> = (value, index: number) => {
    return (
      <Fragment key={index}>
        <SDetailsLayoutHeader isArabic={isArabic} to={value.src}>
          {value.title}
        </SDetailsLayoutHeader>
        <SDetailsLayoutDivider>/</SDetailsLayoutDivider>
      </Fragment>
    );
  };

  return (
    <SDetailsLayout isArabic={isArabic}>
      <SDetailsLayoutContainer>
        <SDetailsLayoutHeaderContainer isArabic={isArabic}>
          {header.map(renderHeader)}
          <SDetailsLayoutTitle>{title}</SDetailsLayoutTitle>
        </SDetailsLayoutHeaderContainer>
        {children}
      </SDetailsLayoutContainer>
    </SDetailsLayout>
  );
});
