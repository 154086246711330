import { ControlsSelectController } from "service/shared/others/ControlsSelectService/ControlsSelect.controller";
import { ControlsSelectState } from "service/shared/others/ControlsSelectService/ControlsSelect.state";

export class ControlsSelectService {
  public readonly state: ControlsSelectState;

  public readonly controller: ControlsSelectController;

  public constructor() {
    this.state = new ControlsSelectState();
    this.controller = new ControlsSelectController(this.state);
  }
}
