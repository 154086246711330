import React from "react";
import { borderRadius_8px, color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setFlexDFAIC, setFlexJCC, setProps, styled } from "theme";

type SFilterButtonProps = {
  open: boolean;
  isArabic: boolean;
};

const openSet = setProps<SFilterButtonProps>(({ open }) => open)(css`
  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setBackgroundColor_ffffff};
`);

const isArabicLang = setProps<SFilterButtonProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterButton = styled.div<SFilterButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: relative;
  width: 130px;
  height: 45px;
  padding: 0 10px;

  border-top-left-radius: ${borderRadius_8px};
  border-top-right-radius: ${borderRadius_8px};

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${openSet};
  ${isArabicLang};
`;
