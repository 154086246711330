import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

export const SApplyChangesButton = styled.button`
  margin-bottom: 8px;
  margin-top: 16px;

  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
`;
