import { CreateUserSelectController } from "service/shared/others/CreateUserSelectService/CreateUserSelect.controller";
import { CreateUserSelectState } from "service/shared/others/CreateUserSelectService/CreateUserSelect.state";

type ConstructorParams = {
  isEmail?: boolean;
  isPhoneNumber?: boolean;
  isNumber?: boolean;
  isPassword?: boolean;
  placeholder: string;
};

export class CreateUserSelectService {
  public readonly state: CreateUserSelectState;

  public readonly controller: CreateUserSelectController;

  public constructor(params: ConstructorParams) {
    this.state = new CreateUserSelectState(params);
    this.controller = new CreateUserSelectController(this.state);
  }
}
