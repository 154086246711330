import { OptionModel } from "model/Option.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { ControlsSelectProps } from "./ControlsSelect.props";
import { SControlsSelect } from "./ControlsSelectStyled/SControlsSelect.styled";
import { SControlsSelectSelect } from "./ControlsSelectStyled/SControlsSelectSelect.styled";
import { SControlsSelectTitle } from "./ControlsSelectStyled/SControlsSelectTitle.styled";

export const ControlsSelect = createComponent<ControlsSelectProps>("ControlsSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;

  const activeOption = useDefaultObservableOptionModel(service.state.controlsSelectService.state.activeOption);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  const renderOption: RenderFunctionType<OptionModel> = (option, index: number) => (
    <option key={index} value={option.value}>
      {option.title}
    </option>
  );

  return (
    <SControlsSelect isArabic={isArabic}>
      <SControlsSelectSelect value={activeOption.value} onChange={service.state.controlsSelectService.controller.onChange}>
        {service.state.controlsSelectService.state.optionList.map(renderOption)}
      </SControlsSelectSelect>
      <SControlsSelectTitle isArabic={isArabic}>{t("controlsSelect.title")}</SControlsSelectTitle>
    </SControlsSelect>
  );
});
