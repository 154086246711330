import { authenticatedController } from "service/common/authenticatedService/authenticated.controller";
import { authenticatedState } from "service/common/authenticatedService/authenticated.state";

class Service {
  public readonly state = authenticatedState;

  public readonly controller = authenticatedController;
}

export const authenticatedService = new Service();
