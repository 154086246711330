import { useTranslation } from "react-i18next";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateUserInviteButton } from "./CreateUserInviteButtonStyled/SCreateUserInviteButton.styled";

export const CreateUserInviteButton = createComponent("CreateUserInviteButton", () => {
  const { t } = useTranslation();
  return (
    <SCreateUserInviteButton onClick={createUserServiceFormService.controller.onClickCreateUserInviteButton}>
      {t("usersInfoPage.createUserInviteButtonTitle")}
    </SCreateUserInviteButton>
  );
});
