import React from "react";
import {
  borderRadius_8px,
  color_ffffff,
  css,
  setBackgroundColor_b7c8dc,
  setBackgroundColor_ffffff,
  setFontSize_12px,
  setFontWeight400,
  setProps,
  styled,
} from "theme";

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SMultipleCustomSelectOptionAll = styled.button<Props>`
  padding: 7px 8px;
  text-align: left;
  white-space: nowrap;

  border: 1px solid ${color_ffffff};
  border-top-left-radius: ${borderRadius_8px};
  border-top-right-radius: ${borderRadius_8px};

  ${setFontSize_12px};
  ${setFontWeight400};
  ${setBackgroundColor_ffffff};
  ${isNotArabicLang};
  ${isArabicLang};

  &:hover {
    ${setBackgroundColor_b7c8dc};
  }
`;
