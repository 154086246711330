import { ChangePasswordInputState } from "service/shared/others/ChangePasswordInputService/ChangePasswordInput.state";
import { KeyboardCustomEvent } from "types/libExtend/KeyboardCustomEvent";
import { passwordValidateFunction } from "utils/business/passwordValidateFunction/passwordValidateFunction";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class ChangePasswordInputController {
  public readonly state: ChangePasswordInputState;

  public constructor(state: ChangePasswordInputState) {
    this.state = state;
  }

  public readonly resetInput = (): void => {
    this.state.value.next("");
  };

  public readonly onKeyUp = (event: KeyboardCustomEvent): void => {
    const capsLockState = event.getModifierState("CapsLock");
    this.state.capsLockState.next(capsLockState);
  };

  private readonly validate = (value: string): boolean => {
    return value.trim() !== "";
  };

  public readonly onChange = onChangeHelper((text: string): void => {
    const errorObject = passwordValidateFunction(text);

    this.state.digitsState.next(errorObject.hasDigitsError);
    this.state.lengthState.next(errorObject.hasLengthError);
    this.state.lowercaseLettersState.next(errorObject.hasLowercaseLettersError);
    this.state.russianLetterState.next(errorObject.hasRussianLetterError);
    this.state.specialSymbolsState.next(errorObject.hasSpecialSymbolsError);
    this.state.uppercaseLettersState.next(errorObject.hasUppercaseLettersError);
    this.state.value.next(text);
    this.state.valid.next(this.validate(text));
  });
}
