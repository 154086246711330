import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { refundsFilterConfig } from "config/filterConfig/refundsFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { t } from "i18n";
import { BehaviorSubjectAnyArray } from "observables/AnyArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectRefundTypeArray } from "observables/RefundTypeArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { RefundType } from "types/business/RefundType";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<RefundType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly refunds: BehaviorSubjectRefundTypeArray;

  public readonly csvData: BehaviorSubjectAnyArray;

  public readonly csvName: string;

  public constructor() {
    super(tableHeaderInitialCell.refundsPage);
    this.csvName = "filters.cvsNameRefunds";
    this.refunds = new BehaviorSubjectRefundTypeArray([]);
    this.csvData = new BehaviorSubjectAnyArray([]);
    this.filterService = new FiltersService(refundsFilterConfig, this.applyFilter);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.refunds, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getRefunds().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceRefund = (dataItem: any): RefundType => {
    return {
      amount: dataItem.amount || "",
      maskedPan: dataItem.details.maskedPan || "",
      rrn: dataItem.details.rrn || "",
      terminalId: dataItem.terminalId || "",
      creationDate: convertFetchedDateToTableShowDate(dataItem.creationDate) || "",
      refundId: dataItem.refundId || "",
      status: dataItem.status,
    };
  };

  private readonly produceRefundData = (data: any[]): RefundType[] => {
    return data.map(this.produceRefund);
  };

  private readonly fetchRefunds = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = { page, queries, size, sort };

    const { data } = await client.post(apiPaths.refundInfo, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.content;
  };

  public readonly getCsvData = (data: any[]): any[] => {
    const names = {
      refundId: "refundsPageConfig.refundId",
      successful: "refundsPageConfig.status",
      amount: "refundsPageConfig.amount",
      creationDate: "refundsPageConfig.creationDate",
      maskedPan: "refundsPageConfig.maskedPan",
      rrn: "refundsPageConfig.rrn",
      terminalId: "refundsPageConfig.terminalId",
    };

    return data.map((dataCell) => {
      return {
        [t(names.rrn)]: dataCell.rrn,
        [t(names.terminalId)]: dataCell.terminalId,
        [t(names.maskedPan)]: dataCell.maskedPan,
        [t(names.refundId)]: dataCell.refundId,
        [t(names.amount)]: dataCell.amount,
        [t(names.creationDate)]: dataCell.creationDate,
        [t(names.successful)]: dataCell.status,
      };
    });
  };

  private readonly getRefunds = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const data: any[] = await this.fetchRefunds();
      const produceData = this.produceRefundData(data);
      const produceCsvData = this.getCsvData(produceData);
      this.csvData.next(produceCsvData);
      this.refunds.next(produceData);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getRefunds();
  };

  public readonly fetchTotalData = async (): Promise<any[]> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const sort = this.state.sort.value;
    const query = { page: 0, queries, size: this.state.filterNavigationService.state.totalElements.value, sort };
    const { data } = await client.post(apiPaths.refundInfo, query);
    return data.content;
  };

  public readonly getTotalData = async (): Promise<any[]> => {
    const data = await this.fetchTotalData();
    const produceData = this.produceRefundData(data);
    const csvData = this.getCsvData(produceData);
    return csvData;
  };
}

export const refundsPageService = new Service();
