import { userRolesConfig } from "appConstants/userRolesConfig";

export const initialReadRoles = [
  userRolesConfig.ADMINISTRATOR,
  userRolesConfig.R_REWARD,
  userRolesConfig.R_OPERATION,
  userRolesConfig.R_INVOICE,
  userRolesConfig.R_REFUND,
  userRolesConfig.R_TRANSFER,
  userRolesConfig.R_STATISTICS,
];
