import { UsersChangePasswordInputController } from "service/shared/others/UsersChangePasswordInputService/UsersChangePasswordInput.controller";
import { UsersChangePasswordInputState } from "service/shared/others/UsersChangePasswordInputService/UsersChangePasswordInput.state";

export class UsersChangePasswordInputService {
  public readonly state: UsersChangePasswordInputState;

  public readonly controller: UsersChangePasswordInputController;

  public constructor(placeholder: string) {
    this.state = new UsersChangePasswordInputState(placeholder);
    this.controller = new UsersChangePasswordInputController(this.state);
  }
}
