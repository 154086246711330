import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";

type SInvoiceCreateSelectSelectProps = {
  isError: boolean;
  isSelected: boolean;
};

const isErrorSet = setProps<SInvoiceCreateSelectSelectProps>(({ isError }) => isError)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

const isSelectedSet = setProps<SInvoiceCreateSelectSelectProps>(({ isSelected }) => isSelected)(css`
  color: transparent;
`);

export const SInvoiceCreateSelectSelect = styled(CustomSelect).attrs({
  listClassName: "listClassName",
})<SInvoiceCreateSelectSelectProps>`
  width: 100%;
  background-color: transparent;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${isErrorSet};
  ${isSelectedSet};

  & > option {
    color: initial;
  }

  .listClassName {
    right: -1px;
    left: -1px;
  }
`;
