import React from "react";
import { css, setProps, styled } from "theme";

interface SFilterInputClearProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterInputClearProps>(({ isArabic }) => isArabic)(css`
  left: 4px;
`);

const isNotArabicLang = setProps<SFilterInputClearProps>(({ isArabic }) => !isArabic)(css`
  right: 4px;
`);

export const SFilterInputClear = styled.button<SFilterInputClearProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  ${isNotArabicLang};
  ${isArabicLang};
`;
