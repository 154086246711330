/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Statistic = createIcon("Statistic", (props) => {
  const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#90C5F5" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 5.43311C3 3.77625 4.34315 2.43311 6 2.43311H15C16.6569 2.43311 18 3.77625 18 5.43311V17.4331C18 19.09 16.6569 20.4331 15 20.4331H6C4.34315 20.4331 3 19.09 3 17.4331V5.43311Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 3.93311H6C5.17157 3.93311 4.5 4.60468 4.5 5.43311V17.4331C4.5 18.2615 5.17157 18.9331 6 18.9331H15C15.8284 18.9331 16.5 18.2615 16.5 17.4331V5.43311C16.5 4.60468 15.8284 3.93311 15 3.93311ZM6 2.43311C4.34315 2.43311 3 3.77625 3 5.43311V17.4331C3 19.09 4.34315 20.4331 6 20.4331H15C16.6569 20.4331 18 19.09 18 17.4331V5.43311C18 3.77625 16.6569 2.43311 15 2.43311H6Z"
        fill={color}
      />
      <path
        d="M12.5 14.6831C12.5 15.0973 12.8358 15.4331 13.25 15.4331C13.6642 15.4331 14 15.0973 14 14.6831L14 7.18311C14 6.76889 13.6642 6.43311 13.25 6.43311C12.8358 6.43311 12.5 6.76889 12.5 7.18311L12.5 14.6831Z"
        fill={color}
      />
      <path
        d="M10.25 15.4331C9.83579 15.4331 9.5 15.0973 9.5 14.6831L9.5 10.1831C9.5 9.76889 9.83579 9.43311 10.25 9.43311C10.6642 9.43311 11 9.76889 11 10.1831V14.6831C11 15.0973 10.6642 15.4331 10.25 15.4331Z"
        fill={color}
      />
      <path
        d="M7.25 15.4331C6.83579 15.4331 6.5 15.0973 6.5 14.6831V12.1831C6.5 11.7689 6.83579 11.4331 7.25 11.4331C7.66421 11.4331 8 11.7689 8 12.1831L8 14.6831C8 15.0973 7.66421 15.4331 7.25 15.4331Z"
        fill={color}
      />
    </svg>
  );
});
