import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ChangePasswordInput } from "./ChangePasswordInput/ChangePasswordInput.component";
import { ChangePasswordSectionCapsLockErrorForm } from "./ChangePasswordSectionCapsLockErrorForm/ChangePasswordSectionCapsLockErrorForm.component";
import { ChangePasswordSectionErrorForm } from "./ChangePasswordSectionErrorForm/ChangePasswordSectionErrorForm.component";
import { ChangePasswordSectionRussianLetterErrorForm } from "./ChangePasswordSectionRussianLetterErrorForm/ChangePasswordSectionRussianLetterErrorForm.component";
import { SChangePasswordSection } from "./ChangePasswordSectionStyled/SChangePasswordSection.styled";
import { SChangePasswordSectionButton } from "./ChangePasswordSectionStyled/SChangePasswordSectionButton.styled";
import { SChangePasswordSectionTitle } from "./ChangePasswordSectionStyled/SChangePasswordSectionTitle.styled";

export const ChangePasswordSection = createComponent("ChangePasswordSection", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <SChangePasswordSection isArabic={isArabic}>
      <SChangePasswordSectionTitle>{t("changePasswordSection.title")}</SChangePasswordSectionTitle>
      <ChangePasswordInput service={userInfoPageService.state.currentPassword} title={t("changePasswordSection.currentPasswordTitle")} />
      <ChangePasswordInput service={userInfoPageService.state.newPassword} title={t("changePasswordSection.newPasswordTitle")} />
      <ChangePasswordInput service={userInfoPageService.state.repeatedNewPassword} title={t("changePasswordSection.repeatedNewPasswordTitle")} />

      <ChangePasswordSectionCapsLockErrorForm />
      <ChangePasswordSectionRussianLetterErrorForm />
      <ChangePasswordSectionErrorForm />

      <div>
        <SChangePasswordSectionButton onClick={userInfoPageService.controller.changePasswordHandler}>
          {t("changePasswordSection.buttonTitle")}
        </SChangePasswordSectionButton>
      </div>
    </SChangePasswordSection>
  );
});
