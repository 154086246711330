import { routerPaths } from "appConstants/routerPaths";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteSignInButton } from "./InviteSignInButtonStyled/SInviteSignInButton.styled";

export const InviteSignInButton = createComponent("InviteSignInButton", () => {
  const { t } = useTranslation();
  return <SInviteSignInButton to={routerPaths.signIn}>{t("activateInvitePage.redirectToSignIn")}</SInviteSignInButton>;
});
