import { resetScrollbar } from "theme/default/resetScrollbar";
import { styled } from "theme/default/styled";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const SGroupTable = styled.div`
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;

  ${setFlexDF};
  ${resetScrollbar};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
