import { StatisticDataItem } from "types/business/StatisticDataItem";
import { StatisticPartDay } from "types/business/StatisticPartDay";

export const getFromStatisticDayAndValue = (statistics: StatisticDataItem[]): StatisticPartDay[] => {
  return statistics.map((element) => {
    console.log(element);
    const day = element.datePart.split("-")[2];

    return {
      day,
      value: element.successSum,
    };
  });
};
