import { PaymentCancel } from "types/business/PaymentCancel";
import { PaymentConfirm } from "types/business/PaymentConfirm";
import { PaymentRefund } from "types/business/PaymentRefund";
import { Dictionary } from "types/commonExtend/Dictionary";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";

export class PaymentDetailsModel {
  public readonly additionalInfo: Dictionary<string>;

  public readonly amount: string;

  public readonly cardHolder: string;

  public readonly canceled: boolean | null;

  public readonly creationDate: string;

  public readonly currency: string;

  public readonly maskedPan: string;

  public readonly paymentSystem: string;

  public readonly requisitesTrxId: string;

  public readonly refundsAmount: string;

  public readonly requestId: string;

  public readonly status: string;

  public readonly terminalId: string;

  public readonly type: string;

  public readonly fee: string;

  public readonly externalRrn: string;

  public readonly hasConfirmAction: boolean;

  public readonly hasCancelAction: boolean;

  public readonly hasRefundAction: boolean;

  public readonly confirmedAmount: string;

  public readonly authId: string;

  public readonly resultCode: string;

  public readonly rrn: string;

  public readonly cancels: PaymentCancel[];

  public readonly confirms: PaymentConfirm[];

  public readonly refunds: PaymentRefund[];

  private readonly confirmed: boolean | null;

  public constructor(dataItem: any) {
    this.additionalInfo = dataItem.additionalInfo || {};
    this.amount = dataItem.amount || "";
    this.canceled = dataItem?.canceled === true || dataItem?.canceled === false ? dataItem.canceled : null;
    this.confirmed = dataItem?.confirmed === true || dataItem?.confirmed === false ? dataItem.confirmed : null;
    this.creationDate = dataItem?.creationDate ? convertFetchedDateToTableShowDate(dataItem?.creationDate) : "";
    this.currency = dataItem.currency || "";
    this.maskedPan = dataItem.details.maskedPan || "";
    this.paymentSystem = dataItem.details.paymentSystem || "";
    this.requisitesTrxId = dataItem.details.requisitesTrxId || "";
    this.refundsAmount = dataItem.refundsAmount || "";
    this.requestId = dataItem.requestId || "";
    this.status = dataItem.status || "";
    this.fee = dataItem.fee ? dataItem.fee.toString() : "";
    this.terminalId = dataItem.terminalId || "";
    this.type = dataItem.type || "";
    this.cardHolder = dataItem.cardHolder || "";
    this.hasConfirmAction = dataItem.operationAvailability.CONFIRM || false;
    this.hasCancelAction = dataItem.operationAvailability.CANCEL || false;
    this.hasRefundAction = dataItem.operationAvailability.REFUND || false;
    this.confirmedAmount = dataItem.confirmedAmount || "";
    this.authId = dataItem?.details?.authId || "";
    this.resultCode = dataItem?.details?.resultCode || "";
    this.rrn = dataItem?.details?.rrn || "";
    this.externalRrn = dataItem?.details?.externalRrn || "";
    this.cancels = dataItem?.cancels ? this.produceCancels(dataItem?.cancels) : [];
    this.confirms = dataItem?.confirms ? this.produceConfirms(dataItem?.confirms) : [];
    this.refunds = dataItem?.refunds ? this.produceRefunds(dataItem?.refunds) : [];
  }

  private readonly produceCancels = (cancelData: any): PaymentCancel[] => {
    return cancelData.map((cancel: any): PaymentCancel => {
      return {
        creationDate: cancel?.created ? convertFetchedDateToTableShowDate(cancel.created) : "",
        status: cancel?.status,
        amount: cancel?.amount || "",
      };
    });
  };

  private readonly produceConfirms = (confirmData: any): PaymentConfirm[] => {
    return confirmData.map((confirm: any): PaymentConfirm => {
      return {
        creationDate: confirm?.created ? convertFetchedDateToTableShowDate(confirm.created) : "",
        status: confirm?.status,
        amount: confirm?.amount || "",
      };
    });
  };

  private readonly produceRefunds = (refundData: any): PaymentRefund[] => {
    return refundData.map((refund: any): PaymentRefund => {
      return {
        creationDate: refund?.creationDate ? convertFetchedDateToTableShowDate(refund.creationDate) : "",
        status: refund?.status,
        refundId: refund?.refundId || "",
        amount: refund?.amount || "",
      };
    });
  };
}
