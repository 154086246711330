import { useSupportArticleContext } from "context/SuportArticle.context";
import { useDefaultObservableString } from "observables/StringObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { SSupportArticleDescriptionInput } from "./SupportArticleDescriptionInputStyled/SSupportArticleDescriptionInput.styled";

export const SupportArticleDescriptionInput = createComponent("SupportArticleDescriptionInput", () => {
  const { service } = useSupportArticleContext();

  const descriptionValue = useDefaultObservableString(service.state.descriptionValue);

  return <SSupportArticleDescriptionInput rows={12} value={descriptionValue} onChange={service.controller.onChangeDescriptionValue} />;
});
