import React from "react";
import { styled } from "theme/default/styled";

export const SCustomSelectEmitter = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
