import { useTableContext } from "context/Table.context";
import { HeaderCellWithId } from "types/business/HeaderCellWithId";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { BodyDataCell } from "./BodyDataCell/BodyDataCell.component";
import { BodyDataRowProps } from "./BodyDataRow.props";
import { SBodyDataRow } from "./BodyDataRowStyled/SBodyDataRow.styled";

export const BodyDataRow = createComponent<BodyDataRowProps>("BodyDataRow", (props) => {
  const { cellData, isFirst } = props;

  const { service } = useTableContext();

  const renderCell: RenderFunctionType<HeaderCellWithId> = (headerCell, index: number) => (
    <BodyDataCell cellData={cellData} value={cellData[headerCell.key]} cellKey={headerCell.key} key={index} />
  );

  return <SBodyDataRow isFirst={isFirst}>{service.state.headerCellList.value.map(renderCell)}</SBodyDataRow>;
});
