import { Dictionary } from "types/commonExtend/Dictionary";

export const replacePath = (url: string, object: Dictionary<string | number>): string => {
  let string = url;
  Object.keys(object).forEach((key) => {
    const value = object[key];
    string = string.replace(`:${key}`, `${value}`);
  });
  return string;
};
