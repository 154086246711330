import { OptionModel } from "model/Option.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { MultipleCustomSelectOption } from "./MultipleCustomSelectOption/MultipleCustomSelectOption.component";
import { MultipleSelectOptionListProps } from "./MultipleSelectOptionList.props";
import { SMultipleCustomSelectOptionList } from "./MultipleSelectOptionListStyled/SMultipleCustomSelectOptionList.styled";

export const MultipleSelectOptionList = createComponent<MultipleSelectOptionListProps>("MultipleSelectOptionList", (props) => {
  const { className = "", service, value } = props;
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const isDropDownOpened = useDefaultObservableBoolean(service.state.isDropDownOpened);

  if (!isDropDownOpened) {
    return null;
  }

  const renderOption: RenderFunctionType<OptionModel> = (option: OptionModel, index: number, array: OptionModel[]) => {
    const isLast = index === array.length - 1;

    return <MultipleCustomSelectOption isLast={isLast} value={value} service={service} option={option} key={index} />;
  };

  return (
    <SMultipleCustomSelectOptionList isArabic={isArabic} isFitContentList={service.state.isFitContentList} className={className}>
      <MultipleCustomSelectOption service={service} />
      {service.state.optionList.map(renderOption)}
    </SMultipleCustomSelectOptionList>
  );
});
