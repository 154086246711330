import { useTranslation } from "react-i18next";
import { terminalsPageService } from "service/shared/singletones/terminalsPageService/terminalsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { STerminalsApplyButton } from "./TerminalsApplyButtonStyled/STerminalsApplyButton.styled";

export const TerminalsApplyButton = createComponent("TerminalsApplyButton", () => {
  const { t } = useTranslation();
  return (
    <STerminalsApplyButton type="submit" onClick={terminalsPageService.controller.onClickApplyButton}>
      {t("terminalsPage.terminalsApplyButtonTitle")}
    </STerminalsApplyButton>
  );
});
