import { resetScrollbar } from "theme/default/resetScrollbar";
import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";
import { setFlexF1 } from "theme/setter/setFlexValues/setFlexF1";

export const STableBody = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;

  ${setFlexDFFDC};
  ${setFlexF1};
  ${resetScrollbar};
`;
