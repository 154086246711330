import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SFullActionButton } from "./FullActionButtonStyled/SFullActionButton.styled";

export const FullActionButton = createComponent("FullActionButton", () => {
  const { t } = useTranslation();
  return (
    <SFullActionButton onClick={paymentDetailsPageService.controller.onClickFull}>{t("paymentDetailsPageContent.fullActionButtonTitle")}</SFullActionButton>
  );
});
