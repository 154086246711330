import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { rewardDetailsFilterConfig } from "config/filterConfig/rewardDetailsFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import i18next from "i18next";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectRewardRowTypeArray } from "observables/RewardRowTypeArrayObservable";
import { BehaviorSubjectRewardTypeNull } from "observables/RewardTypeNullObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { RewardRowType } from "types/business/RewardRowType";
import { Dictionary } from "types/commonExtend/Dictionary";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<RewardRowType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  public readonly operationAggregate: BehaviorSubjectRewardTypeNull;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly rewardRows: BehaviorSubjectRewardRowTypeArray;

  public constructor() {
    super(tableHeaderInitialCell.rewardDetailsPage, { showCountElements: false });
    this.rewardRows = new BehaviorSubjectRewardRowTypeArray([]);
    this.operationAggregate = new BehaviorSubjectRewardTypeNull(null);
    this.filterService = new FiltersService(rewardDetailsFilterConfig, this.applyFilter, {
      initialOpen: true,
      showCloseIcon: false,
      initialArabic: i18next.language === "ar",
    });
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.rewardRows, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getRewardRow().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceRewardRow = (dataItem: any): RewardRowType => {
    return {
      paymentId: dataItem.paymentId || "",
      amount: dataItem.amount || "",
      authId: dataItem.authId || "",
      fee: dataItem.fee || "",
      operTime: convertFetchedDateToTableShowDate(dataItem.operTime) || "",
      pan: dataItem.pan || "",
      rrn: dataItem.rrn || "",
      settlement: dataItem.settlement || "",
      terminalId: dataItem.terminalId || "",
      terminalName: dataItem.terminalName || "",
    };
  };

  private readonly produceRewardData = (data: any[]): RewardRowType[] => {
    return data.map(this.produceRewardRow);
  };

  private readonly produceQueries = (queries: Dictionary<string>): Dictionary<string | string[] | Dictionary<string>> => {
    console.log("queries", queries);
    return {
      terminalIds: queries.FILTERS,
      date: this.state.rewardDate,
      queries: {
        AUTH_ID: queries.AUTH_CODE,
        PAN: queries.PAN,
        RRN: queries.RRN,
      },
    };
  };

  private readonly fetchRewardRow = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;

    const query = {
      ...this.produceQueries(queries),
      size,
      page,
    };

    const { data } = await client.post(apiPaths.rewardDetails, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return {
      data: data.operations,
      operationAggregate: data.operationAggregate,
    };
  };

  private readonly getRewardRow = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const { data, operationAggregate } = await this.fetchRewardRow();
      const produceData = this.produceRewardData(data);
      this.rewardRows.next(produceData);
      this.operationAggregate.next(operationAggregate);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getRewardRow();
  };
}

export const rewardDetailsPageService = new Service();
