import { UsersChangeRowState } from "service/shared/others/UsersChangeRowService/UsersChangeRow.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

type InputType = "phoneNumber" | "email" | "number" | "common";

type UsersChangeRowServiceParams = {
  type?: InputType;
};

export class UsersChangeRowController {
  public readonly type: InputType;

  public readonly state: UsersChangeRowState;

  public readonly onChangeInput = onChangeHelper((nextValue: string): void => {
    if (this.type === "phoneNumber" && nextValue.length === 0) {
      this.state.value.next("+964");
    } else if (this.type === "phoneNumber" && `${nextValue[0]}${nextValue[1]}${nextValue[2]}${nextValue[3]}` !== "+964") {
      /* empty */
    } else {
      this.state.value.next(nextValue);
    }
  });

  public constructor(state: UsersChangeRowState, params?: UsersChangeRowServiceParams) {
    this.type = params?.type ?? "common";
    this.state = state;
  }

  public readonly onClickEdit = (): void => {
    if (this.state.valid.value) {
      if (this.state.active.value) {
        if (!this.state.valueIsEqual.value) {
          this.state.initialValue.next(this.state.value.value);
          this.state.updateUserCallBack();
        }
      }

      this.state.active.next(!this.state.active.value);
    }
  };
}
