import { useDefaultObservableCurrentDataType } from "observables/CurrentDataTypeObservable";
import { paymentDetailsTableService } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.service";
import { createComponent } from "utils/libExtend/createComponent";

import { PaymentTableSwitchProps } from "./PaymentTableSwitch.props";
import { SPaymentTableSwitch } from "./PaymentTableSwitchStyled/SPaymentTableSwitch.styled";

export const PaymentTableSwitch = createComponent<PaymentTableSwitchProps>("PaymentTableSwitch", (props) => {
  const { dataType, title } = props;

  const currentDataType = useDefaultObservableCurrentDataType(paymentDetailsTableService.state.currentDataType);

  const onClick = (): void => paymentDetailsTableService.controller.setDataType(dataType);

  return (
    <SPaymentTableSwitch active={dataType === currentDataType} onClick={onClick}>
      {title}
    </SPaymentTableSwitch>
  );
});
