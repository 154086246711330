import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { RefundsDetailsCellProps } from "detailsCell/RefundsDetailsCell/RefundsDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

export const RefundsDetailsCell = createComponent<RefundsDetailsCellProps>("RefundsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.refundDetails, {
    refundId: cellData.refundId,
  });

  return <DetailsCell to={to} />;
});
