import { TableService } from "service/shared/others/TableService/Table.service";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type TableContextModel = {
  loaded?: boolean;
  service: TableService;
};

export const TableContext = createContext<TableContextModel>({} as TableContextModel);

export const useTableContext = (): TableContextModel => useContext(TableContext);
