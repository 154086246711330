import { ArrowDown } from "./svgs/ArrowDown.component";
import { ArrowLeft } from "./svgs/ArrowLeft.component";
import { ArrowRight } from "./svgs/ArrowRight.component";
import { Asc } from "./svgs/Asc.component";
import { Check } from "./svgs/Check.component";
import { Clear } from "./svgs/Clear.component";
import { Close } from "./svgs/Close.component";
import { ContentCopy } from "./svgs/ContentCopy.component";
import { DeleteFilter } from "./svgs/DeleteFilter.component";
import { Desc } from "./svgs/Desc.component";
import { DetailsIcon } from "./svgs/DetailsIcon.component";
import { DetailsIconActive } from "./svgs/DetailsIconActive.component";
import { Dynamic } from "./svgs/Dynamic.component";
import { EditIcon } from "./svgs/EditIcon.component";
import { FileUpload } from "./svgs/FileUpload.component";
import { H2H } from "./svgs/H2H.component";
import { Invoices } from "./svgs/Invoices.component";
import { Logo } from "./svgs/Logo.component";
import { LogoFin } from "./svgs/LogoFin.component";
import { LogoQi } from "./svgs/LogoQi.component";
import { PaginationLeftArrow } from "./svgs/PaginationLeftArrow.component";
import { PaginationLeftDoubleArrow } from "./svgs/PaginationLeftDoubleArrow.component";
import { PaginationRightArrow } from "./svgs/PaginationRightArrow.component";
import { PaginationRightDoubleArrow } from "./svgs/PaginationRightDoubleArrow.component";
import { Payments } from "./svgs/Payments.component";
import { Plus } from "./svgs/Plus.component";
import { Refunds } from "./svgs/Refunds.component";
import { Reward } from "./svgs/Reward.component";
import { Search } from "./svgs/Search.component";
import { Statistic } from "./svgs/Statistic.component";
import { Support } from "./svgs/Support.component";
import { Transfers } from "./svgs/Transfers.component";
import { Warning } from "./svgs/Warning.component";
import { WhiteClear } from "./svgs/WhiteClear.component";

export const icons = {
  ArrowDown,
  ArrowLeft,
  H2H,
  ArrowRight,
  Asc,
  Check,
  Clear,
  Close,
  ContentCopy,
  DeleteFilter,
  Desc,
  DetailsIcon,
  DetailsIconActive,
  Invoices,
  Logo,
  LogoFin,
  LogoQi,
  PaginationLeftArrow,
  PaginationLeftDoubleArrow,
  PaginationRightArrow,
  PaginationRightDoubleArrow,
  Payments,
  Plus,
  Refunds,
  Search,
  Transfers,
  Warning,
  WhiteClear,
  Dynamic,
  Statistic,
  FileUpload,
  Support,
  Reward,
  EditIcon,
};
