import { styled } from "theme/default/styled";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const SCreateUserFetchedMultipleSelectTitle = styled.div`
  margin-bottom: 4px;

  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_rgba_0_0_0_87};
`;
