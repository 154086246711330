import { ControlsSelectState } from "service/shared/others/ControlsSelectService/ControlsSelect.state";
import { CustomInputEvent } from "types/commonExtend/CustomInputEvent";

export class ControlsSelectController {
  public readonly state: ControlsSelectState;

  public constructor(state: ControlsSelectState) {
    this.state = state;
  }

  public readonly onChange = (event: CustomInputEvent): void => {
    const { value } = event.target;
    const option = this.state.optionList.find((optionModel) => optionModel.value === value)!;

    this.state.activeOption.next(option);
  };
}
