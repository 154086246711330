import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { RewardsDetailsCell } from "detailsCell/RewardsDetailsCell/RewardsDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const rewardsPage: SetPageTableHandlerType = () => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.details, t("rewardsPageConfig.details"), "rewardsPageConfig.details", {
      isDetails: true,
      Element: RewardsDetailsCell,
      width: tableWidths.width120,
    }),
    getInitialHeaderCell(initialHeaderCellKey.date, t("rewardsPageConfig.date"), "rewardsPageConfig.date", { width: tableWidths.width180 }),
    getInitialHeaderCell(initialHeaderCellKey.operationsNumber, t("rewardsPageConfig.operationsNumber"), "rewardsPageConfig.operationsNumber", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.incomeAmount, t("rewardsPageConfig.incomeAmount"), "rewardsPageConfig.incomeAmount", {
      width: tableWidths.width180,
    }),
    getInitialHeaderCell(initialHeaderCellKey.settlementAmount, t("rewardsPageConfig.settlementAmount"), "rewardsPageConfig.settlementAmount", {
      width: tableWidths.width180,
    }),
    getInitialHeaderCell(initialHeaderCellKey.feeAmount, t("rewardsPageConfig.feeAmount"), "rewardsPageConfig.feeAmount", { width: tableWidths.width180 }),
  ];
};
