import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { CreateFormContent } from "./CreateFormContent/CreateFormContent.component";
import { SInvoiceCreateForm } from "./InvoiceCreateFormStyled/SInvoiceCreateForm.styled";
import { SInvoiceCreateFormPopUp } from "./InvoiceCreateFormStyled/SInvoiceCreateFormPopUp.styled";

export const InvoiceCreateForm = createComponent("InvoiceCreateForm", () => {
  const { i18n } = useTranslation();
  const open = useDefaultObservableBoolean(invoiceCreateFormService.state.open);
  const isArabic = i18n.language === "ar";

  if (!open) return null;

  return (
    <SInvoiceCreateFormPopUp closeCallBack={invoiceCreateFormService.controller.close}>
      <SInvoiceCreateForm isArabic={isArabic}>
        <CreateFormContent />
      </SInvoiceCreateForm>
    </SInvoiceCreateFormPopUp>
  );
});
