import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { h2hPageService } from "service/shared/singletones/h2hPageService/h2hPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SH2HTable } from "./H2HTableStyled/SH2HTable.styled";
import { SH2HTableContainer } from "./H2HTableStyled/SH2HTableContainer.styled";

export const H2HTable = createComponent("H2HTable", () => {
  const loaded = useDefaultObservableBoolean(h2hPageService.state.loaded);
  const tableService = useDefaultObservableTableService(h2hPageService.tableService);

  return (
    <SH2HTable>
      <SH2HTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SH2HTableContainer>
    </SH2HTable>
  );
});
