import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";

interface SFilterListProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterListProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterList = styled.div<SFilterListProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  flex-wrap: wrap;

  ${setFlexDF};
  ${isArabicLang};
`;
