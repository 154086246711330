import { styled } from "theme/default/styled";
import { color_rgba_34_36_38_15 } from "theme/setter/opacityColorsValues/color_rgba_34_36_38_15";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SMenuListHeader = styled.div`
  height: 50px;

  border-bottom: 1px solid ${color_rgba_34_36_38_15};

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;
