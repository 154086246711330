import React from "react";
import { css, setFlexDFJCSB, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  justify-content: flex-end;
`);

export const SComparisonSectionGroups = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 0 24px;
  gap: 48px;
  flex-wrap: wrap;

  ${setFlexDFJCSB};
  ${isArabicLang};
`;
