import React from "react";
import { setColor_e74c3c, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SComparisonSectionMessage = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setColor_e74c3c};
  ${setTextRightArabic()};
`;
