import React from "react";
import { css, setColor_0083f5, setFontSize_13px, setFontWeight600, setProps, styled } from "theme";

interface SAddFilterButtonTitleProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SAddFilterButtonTitleProps>(({ isArabic }) => isArabic)(css`
  margin-right: 8px;
`);

const isNotArabicLang = setProps<SAddFilterButtonTitleProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 8px;
`);

export const SAddFilterButtonTitle = styled.div<SAddFilterButtonTitleProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${isArabicLang};
  ${isNotArabicLang};
`;
