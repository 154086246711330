import { useFiltersContext } from "context/Filter.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SApplyButton } from "./ApplyButtonStyled/SApplyButton.styled";

export const ApplyButton = createComponent("ApplyButton", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  return <SApplyButton onClick={service.controller.onClickApply}>{t("filters.applyButtonTitle")}</SApplyButton>;
});
