import React from "react";
import { setFlexDFFDC, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SSupportPageQuestion = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 8px;

  ${setFlexDFFDC};
  ${setRowReverse()};
`;
