import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCreateArticleSelect } from "./CreateArticleTypeInputStyled/SCreateArticleSelect.styled";

export const CreateArticleTypeInput = createComponent("CreateArticleTypeInput", () => {
  const { t } = useTranslation();
  const typeValue = useDefaultObservableString(createArticleFormService.state.typeValue);
  const placeholder = t("supportAdministrationPage.createArticleTypeInputPlaceholder") || "";

  return <SCreateArticleSelect placeholder={placeholder} value={typeValue} onChange={createArticleFormService.controller.onTypeValueChange} />;
});
