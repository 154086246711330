import React from "react";
import { css, setBorderRadius_4px, setProps, styled } from "theme";

interface SCustomMultipleSelectOpenButtonProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SCustomMultipleSelectOpenButtonProps>(({ isArabic }) => isArabic)(css`
  text-align: right;
`);

const isNotArabicLang = setProps<SCustomMultipleSelectOpenButtonProps>(({ isArabic }) => !isArabic)(css`
  text-align: left;
`);

export const SCustomMultipleSelectOpenButton = styled.button.attrs((props) => ({
  type: "button", // Default button type
})) <React.ButtonHTMLAttributes<HTMLButtonElement> & SCustomMultipleSelectOpenButtonProps>`
  width: 100%;
  padding-right: 16px;
  padding-left: 8px;
  text-overflow: ellipsis;

  ${setBorderRadius_4px};
  ${isNotArabicLang};
  ${isArabicLang};
`;
