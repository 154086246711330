import { UserTableContext } from "context/UserTable.context";
import { createComponent } from "utils/libExtend/createComponent";

import { GroupTableProps } from "./export GroupTable.props";
import { GroupTableBody } from "./GroupTableBody/GroupTableBody.component";
import { GroupTableHeader } from "./GroupTableHeader/GroupTableHeader.component";
import { SGroupTable } from "./GroupTableStyled/SGroupTable.styled";
import { SGroupTableContainer } from "./GroupTableStyled/SGroupTableContainer.styled";

export const GroupTable = createComponent<GroupTableProps>("GroupTable", (props) => {
  const { userData } = props;

  return (
    <UserTableContext.Provider value={{ userData }}>
      <SGroupTable>
        <SGroupTableContainer>
          <GroupTableHeader />
          <GroupTableBody />
        </SGroupTableContainer>
      </SGroupTable>
    </UserTableContext.Provider>
  );
});
