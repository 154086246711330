import { MenuIconModel } from "model/MenuIcon.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableMenuIconModelArray } from "observables/MenuIconModelArrayObservable";
import { useTranslation } from "react-i18next";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { MenuIcon } from "../MenuIcon/MenuIcon.component";
import { Toggler } from "../Toggler/Toggler.component";
import { SNavigationMenu } from "./NavigationMenuContentStyled/SNavigationMenu.styled";
import { SNavigationMenuContainer } from "./NavigationMenuContentStyled/SNavigationMenuContainer.styled";
import { SNavigationMenuList } from "./NavigationMenuContentStyled/SNavigationMenuList.styled";

export const NavigationMenuContent = createComponent("NavigationMenuContent", () => {
  const { i18n } = useTranslation();
  const open = useDefaultObservableBoolean(navigationMenuService.state.open);
  const menuIconList = useDefaultObservableMenuIconModelArray(navigationMenuService.state.menuIconList);

  const renderMenuIcon: RenderFunctionType<MenuIconModel> = (menuIcon) => {
    return <MenuIcon menuIcon={menuIcon} key={menuIcon.id} />;
  };

  const isArabic = i18n.language === "ar";

  return (
    <SNavigationMenu open={open} isArabic={isArabic}>
      <SNavigationMenuContainer open={open} isArabic={isArabic}>
        <Toggler />
        <SNavigationMenuList isArabic={isArabic}>{menuIconList.map(renderMenuIcon)}</SNavigationMenuList>
      </SNavigationMenuContainer>
    </SNavigationMenu>
  );
});
