import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { rewardsPageService } from "service/shared/singletones/rewardsPageService/rewardsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardsTable } from "./RewardsTableStyled/SRewardsTable.styled";
import { SRewardsTableContainer } from "./RewardsTableStyled/SRewardsTableContainer.styled";

export const RewardsTable = createComponent("RewardsTable", () => {
  const loaded = useDefaultObservableBoolean(rewardsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(rewardsPageService.tableService);

  return (
    <SRewardsTable>
      <SRewardsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRewardsTableContainer>
    </SRewardsTable>
  );
});
