import { OptionModel } from "model/Option.model";
import { FilterType } from "types/business/FilterType";

type FiltersOptions = {
  isNumber?: boolean;
  initialValue?: string;
  optionList?: OptionModel[];
  optionListCallBack?: () => Promise<OptionModel[]>;
  selectTitle?: string;
  initialOptionValue?: OptionModel;
  initialMultipleOptionValue?: OptionModel[];
  initialStartDateValue?: Date;
  initialEndDateValue?: Date;
  dateFormat?: string;
  timeFormat?: string;
  maxLength?: number;
  initialBooleanValue?: boolean;
  default?: boolean;
};

interface ConstructorParams {
  id: number;
  type: FilterType;
  title: string;
  name: string;
  options?: FiltersOptions;
  langPath: string;
}

export class FilterModel {
  public readonly type: FilterType;

  public readonly title: string;

  public readonly name: string;

  public readonly langPath: string;

  public readonly initialValue?: string;

  public readonly initialBooleanValue?: boolean;

  public readonly optionList?: OptionModel[];

  public readonly optionListCallBack?: () => Promise<OptionModel[]>;

  public readonly selectTitle?: string;

  public readonly initialOptionValue?: OptionModel;

  public readonly initialMultipleOptionValue?: OptionModel[];

  public readonly initialStartDateValue?: Date;

  public readonly initialEndDateValue?: Date;

  public readonly dateFormat?: string;

  public readonly timeFormat?: string;

  public readonly maxLength?: number;

  public readonly default?: boolean;

  public readonly isNumber?: boolean;

  public readonly id: number;

  public constructor({ id, type, title, name, options, langPath }: ConstructorParams) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.name = name;
    this.langPath = langPath;

    if (options) {
      if (options.initialValue) this.initialValue = options.initialValue;
      if (options.initialBooleanValue) this.initialBooleanValue = options.initialBooleanValue;
      if (options.optionList) this.optionList = options.optionList;
      if (options.optionListCallBack) this.optionListCallBack = options.optionListCallBack;
      if (options.selectTitle) this.selectTitle = options.selectTitle;
      if (options.initialOptionValue) this.initialOptionValue = options.initialOptionValue;
      if (options.initialMultipleOptionValue) this.initialMultipleOptionValue = options.initialMultipleOptionValue;
      if (options.maxLength) this.maxLength = options.maxLength;
      if (options.initialStartDateValue) this.initialStartDateValue = options.initialStartDateValue;
      if (options.initialEndDateValue) this.initialEndDateValue = options.initialEndDateValue;
      if (options.dateFormat) this.dateFormat = options.dateFormat;
      if (options.timeFormat) this.timeFormat = options.timeFormat;
      if (options.isNumber) this.isNumber = options.isNumber;
      // if (options.default) this.default = options.default;
      this.default = true;
    }
  }
}
