import { Link as CommonLink } from "packages/react-router-dom";
import { createComponent } from "utils/libExtend/createComponent";

import { LinkProps } from "./Link.props";

export const Link = createComponent<LinkProps>("Link", (props) => {
  const { to, className, onMouseOut, onMouseOver, children, onClick } = props;

  return (
    <CommonLink onMouseOut={onMouseOut} onMouseOver={onMouseOver} to={to} className={className} onClick={onClick}>
      {children}
    </CommonLink>
  );
});
