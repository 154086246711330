import { FiltersState } from "service/shared/others/FiltersService/Filters.state";

export class FiltersController {
  public readonly state: FiltersState;

  public constructor(state: FiltersState) {
    this.state = state;
  }

  public readonly onClickButton = (): void => {
    this.state.open.next(true);
  };

  public readonly onChangeArabic = (value: boolean): void => {
    this.state.isArabic.next(value);
  };

  public readonly onClickCross = (): void => {
    this.state.open.next(false);
  };

  public readonly onClickAddFilter = (): void => {
    this.state.openFilterSelector.next(true);
  };

  public readonly clearFilterSelector = (): void => {
    this.state.openFilterSelector.next(false);
  };

  public readonly onClickApply = (): void => {
    this.state.applyCallBack();
  };

  public readonly onClickReset = (): void => {
    this.state.activeFilterList.value.forEach(({ filterModel }) => this.state.removeActiveFilter(filterModel));
    this.state.applyCallBack();
  };
}
