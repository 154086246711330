import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SCancelButton } from "./CancelButtonStyled/SCancelButton.styled";

export const CancelButton = createComponent("CancelButton", () => {
  const { t } = useTranslation();
  return <SCancelButton onClick={paymentDetailsPageService.controller.onClickCancel}>{t("paymentDetailsPageContent.cancelButtonTitle")}</SCancelButton>;
});
