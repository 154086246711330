import { HeaderCellModel } from "model/HeaderCell.model";
import { TableController } from "service/shared/others/TableService/Table.controller";
import { TableState } from "service/shared/others/TableService/Table.state";
import { InitialBodyRowList } from "types/business/InitialBodyRowList";

export class TableService {
  public readonly state: TableState;

  public readonly controller: TableController;

  public constructor(initialBodyRowList: InitialBodyRowList, initialHeaderCellList: HeaderCellModel[]) {
    this.state = new TableState(initialBodyRowList, initialHeaderCellList);
    this.controller = new TableController(this.state);
  }
}
