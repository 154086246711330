import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { RefundsDetailsCell } from "detailsCell/RefundsDetailsCell/RefundsDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const refundsPage: SetPageTableHandlerType = (sort, desc, setNewSort) => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.details, t("refundsPageConfig.details"), "refundsPageConfig.details", {
      isDetails: true,
      Element: RefundsDetailsCell,
      width: tableWidths.width120,
    }),
    getInitialHeaderCell(initialHeaderCellKey.rrn, t("refundsPageConfig.rrn"), "refundsPageConfig.rrn", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalId, t("refundsPageConfig.terminalId"), "refundsPageConfig.terminalId", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.maskedPan, t("refundsPageConfig.maskedPan"), "refundsPageConfig.maskedPan", { width: tableWidths.width250 }),
    getInitialHeaderCell(initialHeaderCellKey.refundId, t("refundsPageConfig.refundId"), "refundsPageConfig.refundId", {
      width: tableWidths.width350,
      callBack: () => setNewSort("REFUND_ID"),
      showAsc: sort.fieldName === "REFUND_ID" && !desc,
      showDesc: sort.fieldName === "REFUND_ID" && desc,
    }),
    getInitialHeaderCell(initialHeaderCellKey.amount, t("refundsPageConfig.amount"), "refundsPageConfig.amount", {
      width: tableWidths.width120,
      callBack: () => setNewSort("AMOUNT"),
      showAsc: sort.fieldName === "AMOUNT" && !desc,
      showDesc: sort.fieldName === "AMOUNT" && desc,
    }),
    getInitialHeaderCell(initialHeaderCellKey.creationDate, t("refundsPageConfig.creationDate"), "refundsPageConfig.creationDate", {
      width: tableWidths.width180,
      callBack: () => setNewSort("CREATION_DATE"),
      showAsc: sort.fieldName === "CREATION_DATE" && !desc,
      showDesc: sort.fieldName === "CREATION_DATE" && desc,
    }),
    getInitialHeaderCell(initialHeaderCellKey.status, t("refundsPageConfig.status"), "refundsPageConfig.status", { width: tableWidths.width120 }),
  ];
};
