export class SupportQuestionModel {
  public readonly question: string;

  public readonly answer: string;

  public constructor(dataItem: any) {
    this.answer = dataItem.answer || "";
    this.question = dataItem.question || "";
  }
}
