import { GroupModel } from "model/Group.model";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type UserTableContextModel = {
  userData: GroupModel;
};

export const UserTableContext = createContext<UserTableContextModel>({} as UserTableContextModel);

export const useUserTableContext = (): UserTableContextModel => useContext(UserTableContext);
