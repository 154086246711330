import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { PaymentActionContent } from "./PaymentActionContent/PaymentActionContent.component";
import { SPaymentActionModal } from "./PaymentActionModalStyled/SPaymentActionModal.styled";
import { SPaymentActionModalPopUp } from "./PaymentActionModalStyled/SPaymentActionModalPopUp.styled";

export const PaymentActionModal = createComponent("PaymentActionModal", () => {
  return (
    <SPaymentActionModalPopUp closeCallBack={paymentDetailsPageService.controller.close}>
      <SPaymentActionModal>
        <PaymentActionContent />
      </SPaymentActionModal>
    </SPaymentActionModalPopUp>
  );
});
