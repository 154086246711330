import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { RowProps } from "./Row.props";
import { SRow } from "./RowStyled/SRow.styled";
import { SRowTitle } from "./RowStyled/SRowTitle.styled";
import { SRowValue } from "./RowStyled/SRowValue.styled";

export type RowType = "medium" | "big";

export const Row = createComponent<RowProps>("Row", (props) => {
  const { i18n } = useTranslation();
  const { title, value, type = "medium" } = props;
  const isArabic = i18n.language === "ar";

  if (!value) {
    return null;
  }

  return (
    <SRow isArabic={isArabic} isMedium={type === "medium"} isBig={type === "big"}>
      <SRowTitle>{title}</SRowTitle>
      <SRowValue>{value}</SRowValue>
    </SRow>
  );
});
