import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInEmailInput } from "./SignInEmailInputStyled/SSignInEmailInput.styled";

export const SignInEmailInput = createComponent("SignInEmailInput", () => {
  const { t, i18n } = useTranslation();
  return (
    <SSignInEmailInput
      $isArabic={i18n.language === "ar"}
      dataTestId="email"
      autoFocus
      type="email"
      name="email"
      placeholder={t("signInPage.emailTitle")}
      service={signInFormService.state.emailService}
    />
  );
});
