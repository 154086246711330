import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { paymentsFilterConfig } from "config/filterConfig/paymentsFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { t } from "i18n";
import { BehaviorSubjectAnyArray } from "observables/AnyArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectPaymentTypeArray } from "observables/PaymentTypeArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { PaymentType } from "types/business/PaymentType";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<PaymentType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly payments: BehaviorSubjectPaymentTypeArray;

  public readonly csvData: BehaviorSubjectAnyArray;

  public readonly csvName: string;

  public constructor() {
    super(tableHeaderInitialCell.paymentsPage);
    this.csvName = "filters.cvsNamePayments";
    this.payments = new BehaviorSubjectPaymentTypeArray([]);
    this.csvData = new BehaviorSubjectAnyArray([]);
    this.filterService = new FiltersService(paymentsFilterConfig, this.applyFilter);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.payments, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getPayments().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly producePayment = (dataItem: any): PaymentType => {
    return {
      amount: dataItem.amount || "",
      terminalId: dataItem.terminalId || "",
      creationDate: convertFetchedDateToTableShowDate(dataItem.creationDate) || "",
      paymentId: dataItem.paymentId || "",
      status: dataItem.status || "",
      rrn: dataItem.details.rrn || "",
      cardHolder: dataItem.cardHolder || "",
      maskedPan: dataItem.details.maskedPan || "",
      requestId: dataItem.requestId || "",
    };
  };

  private readonly producePaymentData = (data: any[]): PaymentType[] => {
    return data.map(this.producePayment);
  };

  private readonly fetchPayments = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = { page, queries, size, sort };

    const { data } = await client.post(apiPaths.paymentInfo, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.content;
  };

  public readonly getCsvData = (data: any[]): any[] => {
    const names = {
      amount: "paymentsPageConfig.amount",
      creationDate: "paymentsPageConfig.creationDate",
      details: "paymentsPageConfig.details",
      maskedPan: "paymentsPageConfig.maskedPan",
      paymentId: "paymentsPageConfig.paymentId",
      rrn: "paymentsPageConfig.rrn",
      status: "paymentsPageConfig.status",
      terminalId: "paymentsPageConfig.terminalId",
    };

    return data.map((dataCell) => {
      return {
        [t(names.rrn)]: dataCell.rrn,
        [t(names.terminalId)]: dataCell.terminalId,
        [t(names.maskedPan)]: dataCell.maskedPan,
        [t(names.paymentId)]: dataCell.paymentId,
        [t(names.amount)]: dataCell.amount,
        [t(names.creationDate)]: dataCell.creationDate,
        [t(names.status)]: dataCell.status,
      };
    });
  };

  private readonly getPayments = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: any[] = await this.fetchPayments();
    const produceData = this.producePaymentData(data);
    const produceCsvData = this.getCsvData(produceData);
    this.csvData.next(produceCsvData);
    this.payments.next(produceData);
    this.state.loaded.next(true);
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getPayments();
  };

  public readonly fetchTotalData = async (): Promise<any[]> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const sort = this.state.sort.value;
    const query = { page: 0, queries, size: this.state.filterNavigationService.state.totalElements.value, sort };
    const { data } = await client.post(apiPaths.paymentInfo, query);
    return data.content;
  };

  public readonly getTotalData = async (): Promise<any[]> => {
    const data = await this.fetchTotalData();
    const produceData = this.producePaymentData(data);
    const csvData = this.getCsvData(produceData);
    return csvData;
  };
}

export const paymentsPageService = new Service();
