import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSendSmsButton } from "./SendSmsButtonStyled/SSendSmsButton.styled";

export const SendSmsButton = createComponent("SendSmsButton", () => {
  const { t } = useTranslation();
  return <SSendSmsButton onClick={invoiceDetailsPageService.controller.onClickSendSmsButton}>{t("invoiceDetailsPage.sendSmsButtonTitle")}</SSendSmsButton>;
});
