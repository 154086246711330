export class SupportArticleModel {
  public readonly type: string;

  public readonly description: string;

  public readonly email: string;

  private readonly id: number;

  public constructor(id: number, type: string, description: string, email: string) {
    this.id = id;
    this.type = type;
    this.description = description;
    this.email = email;
  }
}
