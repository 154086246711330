import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { resetPasswordFormService } from "service/shared/singletones/resetPasswordFormService/resetPasswordForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SResetPasswordSendButton } from "./SendButtonStyled/SResetPasswordSendButton.styled";
import { SResetPasswordSendButtonLoader } from "./SendButtonStyled/SResetPasswordSendButtonLoader.styled";

export const SendButton = createComponent("SendButton", () => {
  const { t } = useTranslation();
  const isLoaded = useDefaultObservableBoolean(resetPasswordFormService.state.isLoaded);

  if (isLoaded) {
    return (
      <SResetPasswordSendButtonLoader>
        <Loader small />
      </SResetPasswordSendButtonLoader>
    );
  }
  return <SResetPasswordSendButton onClick={resetPasswordFormService.controller.sendForm}>{t("resetPasswordPage.sendButtonTitle")}</SResetPasswordSendButton>;
});
