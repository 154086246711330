import { useTranslation } from "react-i18next";
import { signInFormService } from "service/shared/singletones/signInFormService/signInForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInPasswordInput } from "./SignInPasswordInputStyled/SSignInPasswordInput.styled";

export const SignInPasswordInput = createComponent("SignInPasswordInput", () => {
  const { t, i18n } = useTranslation();
  return (
    <SSignInPasswordInput
      $isArabic={i18n.language === "ar"}
      dataTestId="password"
      type="password"
      name="password"
      placeholder={t("signInPage.passwordTitle")}
      service={signInFormService.state.passwordService}
    />
  );
});
