import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { TransferDetailsModel } from "model/TransferDetails.model";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { transferDetailsPageState } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.state";
import { replacePath } from "utils/commonExtend/replacePath";

class Controller {
  public readonly state = transferDetailsPageState;

  public readonly getTransfer = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: any[] = await this.fetchTransfer();
    const produceData = this.produceTransfer(data);
    this.state.transfer.next(produceData);
    this.state.loaded.next(true);
  };

  public readonly setTransferId = (transferId: string): void => {
    this.state.transferId = transferId;
  };

  public readonly unMounted = (): void => {
    this.state.transfer.next(null);
    this.state.loaded.next(false);
  };

  public readonly cancelTransfer = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);

      const response = await client.post(
        replacePath(apiPaths.transferCancelId, {
          transferId: this.state.transferId,
        }),
        {}
      );
      toastContainerService.controller.createSuccessToast(t("transferDetailsPageService.transferCancel"));

      const produceData = this.produceTransfer(response?.data);
      this.state.transfer.next(produceData);
    } catch (error: any) {
      const { message } = error.response.data;
      toastContainerService.controller.createErrorToast(message);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly produceTransfer = (dataItem: any): TransferDetailsModel => {
    return new TransferDetailsModel(dataItem);
  };

  private readonly fetchTransfer = async (): Promise<any> => {
    const { data } = await client.get(
      replacePath(apiPaths.transferInfoId, {
        transferId: this.state.transferId,
      })
    );
    return data;
  };
}

export const transferDetailsPageController = new Controller();
