import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { h2hFilterConfig } from "config/filterConfig/h2hFilterConfig";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { t } from "i18n";
import i18next from "i18next";
import { BehaviorSubjectAnyArray } from "observables/AnyArrayObservable";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectH2HTypeArray } from "observables/H2HTypeArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { CSVNameColumns, H2HList, H2HType } from "types/business/H2HType";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertRewardDateToUtc0 } from "utils/business/convertRewardDateToUtc0";
import { produceDateToTable } from "utils/business/produceDateToTable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<H2HType> {
  public readonly merchantError: BehaviorSubjectBoolean;

  public fetchCallCount: number;

  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly h2h: BehaviorSubjectH2HTypeArray;

  public readonly csvData: BehaviorSubjectAnyArray;

  public readonly csvName: string;

  private totalElements: number;

  public constructor() {
    super(tableHeaderInitialCell.h2hPage, { disableMount: true });
    this.merchantError = new BehaviorSubjectBoolean(false);
    this.fetchCallCount = 0;
    this.csvName = "H2H";
    this.totalElements = 20;
    this.h2h = new BehaviorSubjectH2HTypeArray([]);
    this.csvData = new BehaviorSubjectAnyArray([]);
    this.filterService = new FiltersService(h2hFilterConfig, this.applyFilter, {
      initialOpen: true,
      showCloseIcon: false,
      initialArabic: i18next.language === "ar",
    });
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.h2h, this.initialHeaderCellList);

    this.state.filterNavigationService.state.currentPage
      .pipe(combineLatestWith(this.state.filterNavigationService.state.controlsSelectService.state.activeOption, this.state.sort))
      .subscribe(([currentPage, sizeOption, sort]) => {
        this.fetchCallCount += 1;

        if (this.fetchCallCount === 1) return;

        const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
        const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
        const sortNotEqual = this.state.prevSort !== sort;

        if (currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
          this.state.loaded.next(false);

          this.state.prevSizeOption = +sizeOption.value;
          this.state.prevSort = sort;

          this.getH2H().then(() => {
            this.state.prevCurrentPage = currentPage;
          });
        }
      });
  }

  private readonly produceH2H = (dataItem: any): H2HType => {
    return {
      terminalTzOperationTime: produceDateToTable(dataItem.terminalTzOperationTime) || "",
      processingTzOperationTime: produceDateToTable(dataItem.processingTzOperationTime) || "",
      merchantId: dataItem.merchantId || "",
      terminalId: dataItem.terminalId || "",
      amount: dataItem.amount || "",
      refundsAmount: dataItem.refundsAmount || "",
      messageCode: dataItem.messageCode || "",
      responseCode: dataItem.responseCode || "",
      maskedPan: dataItem.maskedPan || "",
      rrn: dataItem.rrn || "",
      transactionType: dataItem.transactionType || "",
      authId: dataItem.authId || "",
      pointOfServiceEntryMode: dataItem.pointOfServiceEntryMode || "",
      eci: dataItem.eci || "",
      mcc: dataItem.mcc || "",
      terminalName: dataItem.terminalName || "",
      terminalCity: dataItem.terminalCity || "",
      terminalRegion: dataItem.terminalRegion || "",
      terminalCountry: dataItem.terminalCountry || "",
      currency: dataItem.currency || "",
      acquireId: dataItem.acquireId || "",
      paymentSystem: dataItem.paymentSystem || "",
    };
  };

  private readonly produceH2HData = (data: H2HType[]): H2HType[] => {
    return data.map(this.produceH2H);
  };

  private readonly fetchH2H = async (elementsCount?: number): Promise<H2HType[]> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = { page, queries, size, sort };

    const [startDate, endDate] = convertRewardDateToUtc0(query.queries.CREATION_DATE).split("_");

    const { data }: H2HList = await client.post(apiPaths.h2h, {
      startDate,
      endDate,
      merchantId: query.queries.MERCHANT,
      terminalIds: query.queries.FILTERS,
      queries: {
        PAN: query.queries.PAN,
        RRN: query.queries.RRN,
        AMOUNT: query.queries.AMOUNT,
      },
      page: 0,
      size: elementsCount || 20,
    });

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);
    this.totalElements = data.totalElements;
    return data.operations;
  };

  public readonly getCsvData = (data: H2HType[]): CSVNameColumns[] => {
    const names = {
      terminalTzOperationTime: "h2hPageConfig.terminalTzOperationTime",
      processingTzOperationTime: "h2hPageConfig.processingTzOperationTime",
      merchantId: "h2hPageConfig.merchantId",
      terminalId: "h2hPageConfig.terminalId",
      amount: "h2hPageConfig.amount",
      refundsAmount: "h2hPageConfig.refundsAmount",
      messageCode: "h2hPageConfig.messageCode",
      responseCode: "h2hPageConfig.responseCode",
      maskedPan: "h2hPageConfig.maskedPan",
      rrn: "h2hPageConfig.rrn",
      transactionType: "h2hPageConfig.transactionType",
      authId: "h2hPageConfig.authId",
      pointOfServiceEntryMode: "h2hPageConfig.pointOfServiceEntryMode",
      eci: "h2hPageConfig.eci",
      mcc: "h2hPageConfig.mcc",
      terminalName: "h2hPageConfig.terminalName",
      terminalCity: "h2hPageConfig.terminalCity",
      terminalRegion: "h2hPageConfig.terminalRegion",
      terminalCountry: "h2hPageConfig.terminalCountry",
      currency: "h2hPageConfig.currency",
      acquireId: "h2hPageConfig.acquireId",
      paymentSystem: "h2hPageConfig.paymentSystem",
    };

    return data.map((dataCell) => {
      return {
        [t(names.terminalTzOperationTime)]: dataCell.terminalTzOperationTime,
        [t(names.processingTzOperationTime)]: dataCell.processingTzOperationTime,
        [t(names.merchantId)]: dataCell.merchantId,
        [t(names.terminalId)]: dataCell.terminalId,
        [t(names.amount)]: dataCell.amount,
        [t(names.refundsAmount)]: dataCell.refundsAmount,
        [t(names.messageCode)]: dataCell.messageCode,
        [t(names.responseCode)]: dataCell.responseCode,
        [t(names.maskedPan)]: dataCell.maskedPan,
        [t(names.rrn)]: dataCell.rrn,
        [t(names.transactionType)]: dataCell.transactionType,
        [t(names.authId)]: dataCell.authId,
        [t(names.pointOfServiceEntryMode)]: dataCell.pointOfServiceEntryMode,
        [t(names.eci)]: dataCell.eci,
        [t(names.mcc)]: dataCell.mcc,
        [t(names.terminalName)]: dataCell.terminalName,
        [t(names.terminalCity)]: dataCell.terminalCity,
        [t(names.terminalRegion)]: dataCell.terminalRegion,
        [t(names.terminalCountry)]: dataCell.terminalCountry,
        [t(names.currency)]: dataCell.currency,
        [t(names.acquireId)]: dataCell.acquireId,
        [t(names.paymentSystem)]: dataCell.paymentSystem,
      };
    });
  };

  private readonly getH2H = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: H2HType[] = await this.fetchH2H();
    const produceData = this.produceH2HData(data);
    const produceCsvData = this.getCsvData(produceData);
    this.csvData.next(produceCsvData);
    this.h2h.next(produceData);
    this.state.loaded.next(true);
  };

  private readonly applyFilter = async (): Promise<void> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());

    let hasError = false;

    if (!queries.MERCHANT) {
      this.merchantError.next(true);
      hasError = true;
    } else {
      this.merchantError.next(false);
    }

    if (hasError) return;

    this.state.loaded.next(false);
    this.merchantError.next(false);
    await this.getH2H();
  };

  public readonly getTotalData = async (): Promise<any[]> => {
    const listDataH2H = await this.fetchH2H(this.totalElements);
    const produceData = this.produceH2HData(listDataH2H);
    return this.getCsvData(produceData);
  };
}

export const h2hPageService = new Service();
