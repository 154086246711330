import React from "react";
import { color_a0b9d2, css, setBorderRadius_4px, setProps, styled } from "theme";

interface SFilterInputInputProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterInputInputProps>(({ isArabic }) => isArabic)(css`
  padding: 5px 5px 5px 25px;
  text-align: right;
`);

const isNotArabicLang = setProps<SFilterInputInputProps>(({ isArabic }) => !isArabic)(css`
  padding: 5px 25px 5px 5px;
`);

export const SFilterInputInput = styled.input<SFilterInputInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 180px;
  height: 26px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${isNotArabicLang};
  ${isArabicLang};
  ${setBorderRadius_4px};
`;
