import React from "react";
import { css, setColor_69809a, setFontSize_14px, setFontWeight400, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  margin-right: 10px;
`);

const isNotArabicLang = setProps<Props>(({ isArabic }) => !isArabic)(css`
  margin-left: 10px;
`);

export const SControlsSelectTitle = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${isNotArabicLang};
  ${isArabicLang};
  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_69809a};
`;
