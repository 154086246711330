import { styled } from "theme/default/styled";
import { setFontSize_20px } from "theme/setter/setFontSizeValues/setFontSize_20px";
import { setFontWeight700 } from "theme/setter/setFontWeightValues/setFontWeight700";
import { setColor_rgba_0_0_0_87 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_87";

export const STerminalsListTitle = styled.div`
  ${setFontSize_20px};
  ${setFontWeight700};
  ${setColor_rgba_0_0_0_87};
`;
