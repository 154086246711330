import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { OptionModel } from "model/Option.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SFilterSelector } from "./FilterSelectorStyled/SFilterSelector.styled";
import { SFilterSelectorClear } from "./FilterSelectorStyled/SFilterSelectorClear.styled";
import { SFilterSelectorContainer } from "./FilterSelectorStyled/SFilterSelectorContainer.styled";
import { SFilterSelectorDelete } from "./FilterSelectorStyled/SFilterSelectorDelete.styled";
import { SFilterSelectorSelect } from "./FilterSelectorStyled/SFilterSelectorSelect.styled";
import { SFilterSelectorSelectContainer } from "./FilterSelectorStyled/SFilterSelectorSelectContainer.styled";
import { SFilterSelectorTitle } from "./FilterSelectorStyled/SFilterSelectorTitle.styled";
import { hideSelector } from "./hideSelector";

export const FilterSelector = createComponent("FilterSelector", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  const filterListOption = useDefaultObservableOptionModelArray(service.state.filterListOption);
  const openFilterSelector = useDefaultObservableBoolean(service.state.openFilterSelector);
  const filterListIsEmpty = useDefaultObservableBoolean(service.state.filterListIsEmpty);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);
  const selectTitle = t("filters.filterSelectorSelectTitle") || "";

  if (hideSelector(filterListIsEmpty, openFilterSelector)) {
    return null;
  }

  return (
    <SFilterSelector isArabic={isArabic}>
      <SFilterSelectorTitle isArabic={isArabic}>{t("filters.filterSelectorTitle")}</SFilterSelectorTitle>

      <SFilterSelectorContainer isArabic={isArabic}>
        <SFilterSelectorSelectContainer>
          <SFilterSelectorSelect
            onChange={service.state.onChange}
            value={new OptionModel("", "")}
            selectTitle={selectTitle}
            selectTitleValue=""
            optionList={filterListOption ?? []}
          />

          <SFilterSelectorClear isArabic={isArabic}>
            <SvgImage name="ArrowDown" />
          </SFilterSelectorClear>
        </SFilterSelectorSelectContainer>

        <SFilterSelectorDelete isArabic={isArabic} onClick={service.controller.clearFilterSelector}>
          <SvgImage width={20} height={20} name="Clear" />
        </SFilterSelectorDelete>
      </SFilterSelectorContainer>
    </SFilterSelector>
  );
});
