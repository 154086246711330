import React from "react";
import { css, setBorderRadius_4px, setProps, styled } from "theme";

interface SCustomSelectInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isArabic: boolean;
}

const isArabicLang = setProps<SCustomSelectInputProps>(({ isArabic }) => isArabic)(css`
  padding: 5px 5px 0 25px;
  text-align: right;
`);

const isNotArabicLang = setProps<SCustomSelectInputProps>(({ isArabic }) => !isArabic)(css`
  padding: 5px 25px 0 5px;
`);

export const SCustomSelectInput = styled.input<SCustomSelectInputProps>`
  width: 100%;
  text-overflow: ellipsis;
  border: none;
  outline: none;

  ${setBorderRadius_4px};
  ${isArabicLang};
  ${isNotArabicLang};
`;
