import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";

export class ChangeEmailPhoneInputState {
  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public constructor() {
    this.value = new BehaviorSubjectString("");
    this.valid = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
  }
}
