import { BehaviorSubjectNumber } from "observables/NumberObservable";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { createContext } from "utils/libReplace/createContext";
import { useContext } from "utils/libReplace/useContext";

export type FiltersContextModel = {
  service: FiltersService;
  csvName?: string;
  csvCallback?: () => Promise<any[]>;
  totalElements?: BehaviorSubjectNumber;
};

export const FiltersContext = createContext<FiltersContextModel>({} as FiltersContextModel);

export const useFiltersContext = (): FiltersContextModel => useContext(FiltersContext);
