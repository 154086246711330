import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { PaymentDetailsModel } from "../../../../../model/PaymentDetails.model";
import { useDefaultObservablePaymentDetailsModelNull } from "../../../../../observables/PaymentDetailsModelNullObservable";
import { Children } from "../../../../../types/commonExtend/Children";
import { ActionCloseButton } from "./ActionCloseButton/ActionCloseButton.component";
import { AdditionalInfoStep } from "./AdditionalInfoStep/AdditionalInfoStep.component";
import { SPaymentActionContent } from "./PaymentActionContentStyled/SPaymentActionContent.styled";
import { SPaymentActionContentLoader } from "./PaymentActionContentStyled/SPaymentActionContentLoader.styled";
import { SelectedActionStep } from "./SelectedActionStep/SelectedActionStep.component";
import { UnSelectedActionStep } from "./UnSelectedActionStep/UnSelectedActionStep.component";

const getStage = (additionalInfoStageSelected: boolean, partialFormSelected: boolean, payment: PaymentDetailsModel): Children => {
  if (payment.type === "SBP") {
    if (additionalInfoStageSelected) return <AdditionalInfoStep />;
  }

  if (partialFormSelected) return <SelectedActionStep />;
  return <UnSelectedActionStep />;
};

export const PaymentActionContent = createComponent("PaymentActionContent", () => {
  const partialFormSelected = useDefaultObservableBoolean(paymentDetailsPageService.state.partialFormSelected);
  const payment = useDefaultObservablePaymentDetailsModelNull(paymentDetailsPageService.state.payment)!;
  const additionalInfoStageSelected = useDefaultObservableBoolean(paymentDetailsPageService.state.additionalInfoStageSelected);
  const actionLoaded = useDefaultObservableBoolean(paymentDetailsPageService.state.actionLoaded);

  if (!actionLoaded) {
    return (
      <SPaymentActionContentLoader>
        <Loader />
      </SPaymentActionContentLoader>
    );
  }

  return (
    <SPaymentActionContent>
      <ActionCloseButton />
      {getStage(additionalInfoStageSelected, partialFormSelected, payment)}
    </SPaymentActionContent>
  );
});
