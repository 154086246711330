import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { usersInfoPageService } from "service/shared/singletones/usersInfoPageService/usersInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { UsersInfoContent } from "./UsersInfoContent/UsersInfoContent.component";
import { UsersInfoPageLayout } from "./UsersInfoPageLayout/UsersInfoPageLayout.component";
import { SUsersInfoPageLoader } from "./UsersInfoPageStyled/SUsersInfoPageLoader.styled";

export const UsersInfoPage = createComponent("UsersInfoPage", () => {
  const loaded = useDefaultObservableBoolean(usersInfoPageService.state.loaded);

  useEffect(async () => {
    await usersInfoPageService.controller.onMount();
  }, []);

  return (
    <UsersInfoPageLayout>
      {loaded ? (
        <UsersInfoContent />
      ) : (
        <SUsersInfoPageLoader>
          <Loader />
        </SUsersInfoPageLoader>
      )}
    </UsersInfoPageLayout>
  );
});
