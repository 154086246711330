import { CustomSelect } from "components/CustomSelect/CustomSelect.component";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SCreateUserFetchedSelectSelect = styled(CustomSelect).attrs({
  listClassName: "listClassName",
})`
  width: 375px;
  height: 26px;
  padding: 5px 25px 5px 0;
  gap: 8px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setFlexDFFDC};
  ${setBorderRadius_4px};

  .listClassName {
    right: -1px;
    left: -1px;
  }
`;
