import { routerPaths } from "appConstants/routerPaths";
import { useTranslation } from "react-i18next";
import { resetPasswordFormService } from "service/shared/singletones/resetPasswordFormService/resetPasswordForm.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useLocation } from "utils/libReplace/useLocation";

import { SResetPasswordForm } from "./ResetPasswordPageStyled/SResetPasswordForm.styled";
import { SResetPasswordFormHeader } from "./ResetPasswordPageStyled/SResetPasswordFormHeader.styled";
import { SResetPasswordFormLink } from "./ResetPasswordPageStyled/SResetPasswordFormLink.styled";
import { SResetPasswordFormPassword } from "./ResetPasswordPageStyled/SResetPasswordFormPassword.styled";
import { SResetPasswordFormPasswordConfirm } from "./ResetPasswordPageStyled/SResetPasswordFormPasswordConfirm.styled";
import { SendButton } from "./SendButton/SendButton.component";

export const ResetPasswordPage = createComponent("ResetPasswordPage", () => {
  const { t } = useTranslation();
  const { search } = useLocation();

  useEffect(() => {
    resetPasswordFormService.controller.onMount(search);
  }, []);

  return (
    <SResetPasswordForm>
      <SResetPasswordFormHeader>{t("resetPasswordPage.title")}</SResetPasswordFormHeader>
      <SResetPasswordFormPassword
        type="password"
        name="password"
        placeholder={t("resetPasswordPage.passwordPlaceholder")}
        service={resetPasswordFormService.state.passwordService}
      />

      <SResetPasswordFormPasswordConfirm
        type="password"
        name="confirmPassword"
        placeholder={t("resetPasswordPage.repeatPasswordPlaceholder")}
        service={resetPasswordFormService.state.confirmPasswordService}
      />
      <SResetPasswordFormLink to={routerPaths.signIn}>{t("resetPasswordPage.backButtonTitle")}</SResetPasswordFormLink>
      <SendButton />
    </SResetPasswordForm>
  );
});
