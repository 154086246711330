import { OptionModel } from "model/Option.model";
import { TopicOptionModel } from "model/TopicOption.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { BehaviorSubjectSupportQuestionModelArray } from "observables/SupportQuestionModelArrayObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public topicOptions: TopicOptionModel[];

  public readonly questionList: BehaviorSubjectSupportQuestionModelArray;

  public readonly selectOptionList: BehaviorSubjectOptionModelArray;

  public readonly selectValue: BehaviorSubjectOptionModel;

  public readonly inputValue: BehaviorSubjectString;

  public readonly loadedContent: BehaviorSubjectBoolean;

  public readonly loadedForm: BehaviorSubjectBoolean;

  public readonly selectValueValid: BehaviorSubjectBoolean;

  public readonly inputValueValid: BehaviorSubjectBoolean;

  public readonly sendButtonValid: BehaviorSubjectBoolean;

  public constructor() {
    this.topicOptions = [];
    this.questionList = new BehaviorSubjectSupportQuestionModelArray([]);
    this.selectOptionList = new BehaviorSubjectOptionModelArray([]);
    this.selectValue = new BehaviorSubjectOptionModel(new OptionModel("", ""));
    this.inputValue = new BehaviorSubjectString("");
    this.loadedContent = new BehaviorSubjectBoolean(false);
    this.loadedForm = new BehaviorSubjectBoolean(false);
    this.selectValueValid = setCombinedBehaviorSubject(this.setSelectValueValid, this.selectValue);
    this.inputValueValid = setCombinedBehaviorSubject(this.setInputValueValid, this.inputValue);
    this.sendButtonValid = setCombinedBehaviorSubject(this.setSendButtonValid, this.selectValueValid, this.inputValueValid);
  }

  public readonly setSendButtonValid = (selectValueValid: boolean, inputValueValid: boolean): boolean => {
    return selectValueValid && inputValueValid;
  };

  public readonly setSelectValueValid = (selectValue: OptionModel): boolean => {
    return selectValue.value !== "";
  };

  public readonly setInputValueValid = (inputValue: string): boolean => {
    return inputValue.trim() !== "";
  };
}

export const supportPageState = new State();
