import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type InputServiceParams = {
  regexp?: RegExp;
  required?: true;
};

export class ForgotPasswordInputState {
  public readonly showError: BehaviorSubjectBoolean;

  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  private readonly regexp?: RegExp;

  private readonly required?: true;

  public constructor(params: InputServiceParams) {
    this.regexp = params.regexp;
    this.required = params.required;
    this.showError = new BehaviorSubjectBoolean(false);
    this.value = new BehaviorSubjectString("");
    this.valid = setCombinedBehaviorSubject(this.validate, this.value);
  }

  private readonly validate = (value: string): boolean => {
    if (this.regexp) {
      return this.regexp.test(value);
    }
    if (this.required) {
      return value.trim() !== "";
    }
    return true;
  };
}
