import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_0083f5 } from "theme/setter/colorsValues/color_0083f5";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

type SSupportArticleContentProps = {
  formIsChanged: boolean;
};

const formIsChangedNotSet = setProps<SSupportArticleContentProps>(({ formIsChanged }) => !formIsChanged)(css`
  border: 1px solid ${color_a0b9d2};
`);

const formIsChangedSet = setProps<SSupportArticleContentProps>(({ formIsChanged }) => formIsChanged)(css`
  border: 1px solid ${color_0083f5};
`);

export const SSupportArticleContent = styled.div<SSupportArticleContentProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 8px;

  ${setFlexDFFDC};
  ${formIsChangedSet};
  ${formIsChangedNotSet};
  ${setBorderRadius_8px};
`;
