import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { RewardDetailsHeader } from "./RewardDetailsHeader/RewardDetailsHeader.component";
import { RewardDetailsInformation } from "./RewardDetailsInformation/RewardDetailsInformation.component";
import { SRewardDetailsPage } from "./RewardDetailsPageStyled/SRewardDetailsPage.styled";
import { RewardDetailsTable } from "./RewardDetailsTable/RewardDetailsTable.component";

export const RewardDetailsPage = createComponent("RewardDetailsPage", () => {
  const { i18n } = useTranslation();
  const { rewardDate } = useParams<{ rewardDate: string }>();

  useEffect(() => {
    rewardDetailsPageService.controller.setRewardDate(rewardDate);
    rewardDetailsPageService.controller.mounted();

    return (): void => {
      rewardDetailsPageService.controller.unMounted();
    };
  }, []);

  return (
    <SRewardDetailsPage isArabic={i18n.language === "ar"}>
      <RewardDetailsHeader />
      <RewardDetailsInformation />
      <RewardDetailsTable />
      <TableNavigation service={rewardDetailsPageService.state.filterNavigationService} />
    </SRewardDetailsPage>
  );
});
