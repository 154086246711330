import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableStringArray } from "observables/StringArrayObservable";
import { useTranslation } from "react-i18next";
import { activateInvitePageService } from "service/shared/singletones/activateInvitePageService/activateInvitePage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { SInviteErrorForm } from "./InviteErrorFormStyled/SInviteErrorForm.styled";
import { SInviteErrorFormTitle } from "./InviteErrorFormStyled/SInviteErrorFormTitle.styled";

const LENGTH_ERROR = "changePasswordSection.lengthError";
export const InviteErrorForm = createComponent("InviteErrorForm", () => {
  const { t } = useTranslation();
  const errorTextArray = useDefaultObservableStringArray(activateInvitePageService.state.errorTextArray);
  const showErrors = useDefaultObservableBoolean(activateInvitePageService.state.showErrors);
  const minLength = activateInvitePageService.state.passwordConfig.minLength!;
  const maxLength = activateInvitePageService.state.passwordConfig.maxLength!;

  const hideForm = !(showErrors && errorTextArray.length !== 0);

  if (hideForm) {
    return null;
  }

  const renderErrorText: RenderFunctionType<string> = (errorText, index) => {
    const text = errorText === LENGTH_ERROR ? t(errorText, { minLength, maxLength }) : t(errorText);
    return <SInviteErrorFormTitle key={index}>{text}</SInviteErrorFormTitle>;
  };

  return <SInviteErrorForm>{errorTextArray.map(renderErrorText)}</SInviteErrorForm>;
});
