import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class CustomSelectState {
  public readonly searchValue: BehaviorSubjectString;

  public readonly isDropDownOpened: BehaviorSubjectBoolean;

  public readonly filteredOptionList: BehaviorSubjectOptionModelArray;

  public readonly isFitContentList: BehaviorSubjectBoolean;

  public readonly onChange: (value: OptionModel) => void;

  private readonly optionList: OptionModel[];

  public constructor(optionList: OptionModel[], onChange: (value: OptionModel) => void) {
    this.onChange = onChange;
    this.optionList = optionList;
    this.searchValue = new BehaviorSubjectString("");
    this.isDropDownOpened = new BehaviorSubjectBoolean(false);
    this.filteredOptionList = setCombinedBehaviorSubject(this.setFilteredOptionList, this.searchValue);
    this.isFitContentList = setCombinedBehaviorSubject(this.setIsFitContentList, this.filteredOptionList);
  }

  private readonly setFilteredOptionList = (searchValue: string): OptionModel[] => {
    return this.optionList.filter((el) => {
      return Boolean(el.title) && el.title.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  private readonly setIsFitContentList = (filteredOptionList: OptionModel[]): boolean => {
    return filteredOptionList.length < 5;
  };
}
