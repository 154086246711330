import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { initialOptionList } from "service/shared/others/CompareMonthService/initialOptionList";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class CompareMonthState {
  public readonly value: BehaviorSubjectOptionModel;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly optionList: OptionModel[];

  public constructor() {
    this.value = new BehaviorSubjectOptionModel(new OptionModel("", ""));
    this.optionList = initialOptionList;
    this.showError = new BehaviorSubjectBoolean(false);
    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  private readonly setValid = (value: OptionModel): boolean => {
    return !!value.value;
  };
}
