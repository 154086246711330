import { useTranslation } from "react-i18next";
import { dynamicPageService } from "service/shared/singletones/dynamicPageService/dynamicPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SApplyCompareButton } from "./ApplyCompareButtonStyled/SApplyCompareButton.styled";

export const ApplyCompareButton = createComponent("ApplyCompareButton", () => {
  const { t } = useTranslation();
  return <SApplyCompareButton onClick={dynamicPageService.controller.onClick}>{t("dynamicPage.applyButton")}</SApplyCompareButton>;
});
