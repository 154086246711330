import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createUserServiceFormService } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { CreateUserFetchedMultipleSelect } from "./CreateUserFetchedMultipleSelect/CreateUserFetchedMultipleSelect.component";
import { CreateUserFetchedSelect } from "./CreateUserFetchedSelect/CreateUserFetchedSelect.component";
import { SCreateUserForm } from "./CreateUserFormStyled/SCreateUserForm.styled";
import { SCreateUserFormPopUp } from "./CreateUserFormStyled/SCreateUserFormPopUp.styled";
import { SCreateUserFormTitle } from "./CreateUserFormStyled/SCreateUserFormTitle.styled";
import { CreateUserInviteButton } from "./CreateUserInviteButton/CreateUserInviteButton.component";
import { CreateUserSelect } from "./CreateUserSelect/CreateUserSelect.component";

export const CreateUserForm = createComponent("CreateUserForm", () => {
  const { t } = useTranslation();
  const open = useDefaultObservableBoolean(createUserServiceFormService.state.open);

  if (!open) {
    return null;
  }

  return (
    <SCreateUserFormPopUp closeCallBack={createUserServiceFormService.controller.onClickCloseForm}>
      <SCreateUserForm>
        <SCreateUserFormTitle>{t("usersInfoPage.createUserFormTitle")}</SCreateUserFormTitle>
        <CreateUserFetchedSelect service={createUserServiceFormService.state.merchantGroupIdService} />
        <CreateUserFetchedMultipleSelect />
        <CreateUserSelect service={createUserServiceFormService.state.emailService} />
        <CreateUserSelect service={createUserServiceFormService.state.nameService} />
        <CreateUserSelect service={createUserServiceFormService.state.phoneNumberService} />
        <CreateUserInviteButton />
      </SCreateUserForm>
    </SCreateUserFormPopUp>
  );
});
