export const tableWidths = {
  width50: 50,
  width100: 100,
  width120: 120,
  width150: 150,
  width180: 180,
  width200: 200,
  width230: 230,
  width250: 250,
  width300: 300,
  width350: 350,
  width400: 400,
};
