export class RolesSelectOptionModel {
  public readonly title: string;

  public readonly description: string;

  public readonly langPath: string;

  public constructor(title: string, description: string, langPath: string) {
    this.title = title;
    this.description = description;
    this.langPath = langPath;
  }
}
