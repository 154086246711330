import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { useFiltersContext } from "../../../../context/Filter.context";
import { CheckboxFilterProps } from "./CheckboxFilter.props";
import { SCheckboxFilter } from "./CheckboxFilterStyled/SCheckboxFilter.styled";
import { SCheckboxFilterContainer } from "./CheckboxFilterStyled/SCheckboxFilterContainer.styled";
import { SCheckboxFilterDelete } from "./CheckboxFilterStyled/SCheckboxFilterDelete.styled";
import { SCheckboxFilterInputContainer } from "./CheckboxFilterStyled/SCheckboxFilterInputContainer.styled";
import { SCheckboxFilterTitle } from "./CheckboxFilterStyled/SCheckboxFilterTitle.styled";

export const CheckboxFilter = createComponent<CheckboxFilterProps>("CheckboxFilter", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const value = useDefaultObservableBoolean(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return (
    <SCheckboxFilter>
      <SCheckboxFilterTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SCheckboxFilterTitle>

      <SCheckboxFilterContainer>
        <SCheckboxFilterInputContainer onClick={service.onChange}>{value ? <SvgImage name="Check" /> : null}</SCheckboxFilterInputContainer>

        <SCheckboxFilterDelete onClick={service.onClickDelete}>
          <SvgImage width={20} height={20} name="DeleteFilter" />
        </SCheckboxFilterDelete>
      </SCheckboxFilterContainer>
    </SCheckboxFilter>
  );
});
