import { userService } from "api/userService";
import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsRefundCell } from "detailsCell/PaymentsDetailsRefundCell/PaymentsDetailsRefundCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentDetailsPageRefund = (): HeaderCellModel[] => {
  const arr = [
    getInitialHeaderCell(initialHeaderCellKey.creationDate, t("paymentDetailsPageRefundConfig.creationDate"), "paymentDetailsPageRefundConfig.creationDate", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageRefundConfig.status"), "paymentDetailsPageRefundConfig.status", {
      width: tableWidths.width150,
    }),
    getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageRefundConfig.amount"), "paymentDetailsPageRefundConfig.amount", {
      width: tableWidths.width150,
    }),
  ];

  const canGetRefundInfo = userService.canGetRefundInfo();

  if (canGetRefundInfo) {
    arr.unshift(
      getInitialHeaderCell(initialHeaderCellKey.details, t("paymentDetailsPageRefundConfig.details"), "paymentDetailsPageRefundConfig.details", {
        isDetails: true,
        Element: PaymentsDetailsRefundCell,
        width: tableWidths.width120,
      })
    );
  }

  return arr;
};
