import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFF1 } from "theme/setter/setFlexValues/setFlexDFF1";

export const SMainLayoutContent = styled.div<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  height: 0;
  margin-top: -8px;
  padding-top: 8px;

  ${setFlexDFF1};
  ${(props) => props.isArabic && `flex-direction: row-reverse`};
`;
