import React from "react";
import { setColor_2d3033, setFontSize_18px, setFontWeight700, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SSupportPageQuestionListTitle = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_18px};
  ${setFontWeight700};
  ${setColor_2d3033};
  ${setTextRightArabic()};
`;
