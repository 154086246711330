import { FiltersContext } from "context/Filter.context";
import { useEffect } from "packages/react";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { FiltersProps } from "./Filters.props";
import { FiltersContent } from "./FiltersContent/FiltersContent.component";

export const Filters = createComponent<FiltersProps>("Filters", (props) => {
  const { i18n } = useTranslation();
  const { service, csvName, csvCallback, totalElements } = props;

  useEffect(() => {
    service.controller.onChangeArabic(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <FiltersContext.Provider value={{ service, csvName, csvCallback, totalElements }}>
      <FiltersContent />
    </FiltersContext.Provider>
  );
});
