import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { TerminalsApplyButton } from "./TerminalsApplyButton/TerminalsApplyButton.component";
import { TerminalsClearFilterButton } from "./TerminalsClearFilterButton/TerminalsClearFilterButton.component";
import { TerminalsFilterSelect } from "./TerminalsFilterSelect/TerminalsFilterSelect.component";
import { STerminalsFilters } from "./TerminalsFiltersStyled/STerminalsFilters.styled";
import { STerminalsFiltersContainer } from "./TerminalsFiltersStyled/STerminalsFiltersContainer.styled";
import { STerminalsFiltersTitle } from "./TerminalsFiltersStyled/STerminalsFiltersTitle.styled";

export const TerminalsFilters = createComponent("TerminalsFilters", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  return (
    <STerminalsFilters>
      <STerminalsFiltersTitle>{t("terminalsPage.filterTitle")}</STerminalsFiltersTitle>
      <STerminalsFiltersContainer isArabic={isArabic}>
        <TerminalsFilterSelect />
        <TerminalsClearFilterButton />
        <TerminalsApplyButton />
      </STerminalsFiltersContainer>
    </STerminalsFilters>
  );
});
