import { usersDetailsPageController } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.controller";
import { usersDetailsPageState } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.state";

class Service {
  public readonly state = usersDetailsPageState;

  public readonly controller = usersDetailsPageController;
}

export const usersDetailsPageService = new Service();
