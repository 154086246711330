import { Filters } from "components/Filters/Filters.component";
import { invoicesPageService } from "service/shared/singletones/invoicesPageService/invoicesPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const InvoicesPageFilters = createComponent("InvoicesPageFilters", () => {
  return (
    <Filters
      totalElements={invoicesPageService.state.filterNavigationService.state.totalElements}
      service={invoicesPageService.filterService}
      csvCallback={invoicesPageService.getTotalData}
      csvName={invoicesPageService.csvName}
    />
  );
});
