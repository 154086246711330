import { useTableContext } from "context/Table.context";
import { useDefaultObservableHeaderCellWithIdArray } from "observables/HeaderCellWithIdArrayObservable";
import { HeaderCellWithId } from "types/business/HeaderCellWithId";
import { createComponent } from "utils/libExtend/createComponent";

import { BodyDataCellProps } from "./BodyDataCell.props";
import { SBodyDataCell } from "./BodyDataCellStyled/SBodyDataCell.styled";

export const BodyDataCell = createComponent<BodyDataCellProps>("BodyDataCell", (props) => {
  const { value, cellKey, cellData } = props;
  const { service } = useTableContext();

  const headerCellList = useDefaultObservableHeaderCellWithIdArray(service.state.headerCellList);

  const headerCell: HeaderCellWithId = headerCellList.find((cell) => cell.key === cellKey)!;

  const { width, Element, isDetails } = headerCell;

  return <SBodyDataCell width={width}>{isDetails ? <Element cellData={cellData} headerCell={headerCell} /> : value}</SBodyDataCell>;
});
