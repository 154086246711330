import React from "react";
import { setFlexDFJCSB, setRowReverse, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SCompareMonth = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 400px;

  ${setFlexDFJCSB};
  ${setRowReverse()};
`;
