import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const customerEmailFilterModel = new FilterModel({
  id: 21,
  type: "text",
  title: t("customerEmailFilterModel.title"),
  name: "CUSTOMER_EMAIL",
  options: {},
  langPath: "customerEmailFilterModel.title",
});
