import { usersInfoPageController } from "service/shared/singletones/usersInfoPageService/usersInfoPage.controller";
import { usersInfoPageState } from "service/shared/singletones/usersInfoPageService/usersInfoPage.state";

class Service {
  public readonly state = usersInfoPageState;

  public readonly controller = usersInfoPageController;
}

export const usersInfoPageService = new Service();
