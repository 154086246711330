import { userService } from "api/userService";
import { routerPaths } from "appConstants/routerPaths";
import { MainLayout } from "components/MainLayout/MainLayout.component";
import { Navigate, Route, Routes } from "packages/react-router-dom";
import { createComponent } from "utils/libExtend/createComponent";

import { createAppPage } from "App/createAppPage";
import * as Pages from "App/pages";

export const AppAuthPart = createComponent("AppAuthPart", () => {
  const redirectRoute = userService.getRedirectRoute();

  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path={routerPaths.userInfoPage} element={<Pages.UserInfoPage />} />
        {createAppPage(routerPaths.dynamic, Pages.DynamicPage, userService.canGetStatisticPage())}
        {createAppPage(routerPaths.statistic, Pages.StatisticPage, userService.canGetStatisticPage())}
        {createAppPage(routerPaths.support, Pages.SupportPage, userService.canGetSupportPage())}
        {createAppPage(routerPaths.usersInfoPage, Pages.UsersInfoPage, userService.canGetUsersInfo())}
        {createAppPage(routerPaths.payments, Pages.PaymentsPage, userService.canGetPaymentInfo())}
        {createAppPage(routerPaths.invoices, Pages.InvoicesPage, userService.canGetInvoiceInfo())}
        {createAppPage(routerPaths.transfers, Pages.TransfersPage, userService.canGetTransferInfo())}
        {createAppPage(routerPaths.refunds, Pages.RefundsPage, userService.canGetRefundInfo())}
        {createAppPage(routerPaths.rewards, Pages.RewardsPage, userService.canGetRewardInfo())}
        {createAppPage(routerPaths.h2h, Pages.H2HPage, userService.canGetH2HInfo())}
        {createAppPage(routerPaths.paymentDetails, Pages.PaymentDetailsPage, userService.canGetPaymentInfo())}
        {createAppPage(routerPaths.paymentRefundDetails, Pages.PaymentRefundDetailsPage, userService.canGetPaymentInfo())}
        {createAppPage(routerPaths.invoiceDetails, Pages.InvoiceDetailsPage, userService.canGetInvoiceInfo())}
        {createAppPage(routerPaths.transferDetails, Pages.TransferDetailsPage, userService.canGetTransferInfo())}
        {createAppPage(routerPaths.refundDetails, Pages.RefundDetailsPage, userService.canGetRefundInfo())}
        {createAppPage(routerPaths.rewardDetails, Pages.RewardDetailsPage, userService.canGetRewardInfo())}
        {createAppPage(routerPaths.auditPage, Pages.AuditPage, userService.canGetAuditPage())}
        {createAppPage(routerPaths.terminalsPage, Pages.TerminalsPage, userService.canTerminalsPage())}
        {createAppPage(routerPaths.supportAdministration, Pages.SupportAdministrationPage, userService.canSupportAdministration())}
        {createAppPage(routerPaths.usersDetailsPage, Pages.UsersDetailsPage, userService.canUsersDetailsPage())}
      </Route>

      <Route path="/" element={<Navigate to={redirectRoute} />} />
      {createAppPage("*", Pages.NotFoundPage)}
    </Routes>
  );
});
