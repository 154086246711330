import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { CompareMonthProps } from "./CompareMonth.props";
import { SCompareMonth } from "./CompareMonthStyled/SCompareMonth.styled";
import { SCompareMonthSelector } from "./CompareMonthStyled/SCompareMonthSelector.styled";

export const CompareMonth = createComponent<CompareMonthProps>("CompareMonth", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { service } = props;

  const value = useDefaultObservableOptionModel(service.state.value);
  const valid = useDefaultObservableBoolean(service.state.valid);
  const showError = useDefaultObservableBoolean(service.state.showError);

  return (
    <SCompareMonth isArabic={isArabic}>
      <span>{t("dynamicPage.monthInputTitle")}</span>
      <SCompareMonthSelector isError={showError && !valid} onChange={service.controller.onChange} value={value} optionList={service.state.optionList} />
    </SCompareMonth>
  );
});
