import { createUserServiceFormController } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.controller";
import { createUserServiceFormState } from "service/shared/singletones/createUserServiceFormService/createUserServiceForm.state";

class Service {
  public readonly state = createUserServiceFormState;

  public readonly controller = createUserServiceFormController;
}

export const createUserServiceFormService = new Service();
