import { OptionModel } from "model/Option.model";
import { UsersChangeRowGroupController } from "service/shared/others/UsersChangeRowGroupService/UsersChangeRowGroup.controller";
import { UsersChangeRowGroupState } from "service/shared/others/UsersChangeRowGroupService/UsersChangeRowGroup.state";

export class UsersChangeRowGroupService {
  public readonly state: UsersChangeRowGroupState;

  public readonly controller: UsersChangeRowGroupController;

  public constructor(initialValue: OptionModel, updateUserCallBack: () => void) {
    this.state = new UsersChangeRowGroupState(initialValue, updateUserCallBack);
    this.controller = new UsersChangeRowGroupController(this.state);
  }
}
