import { routerPaths } from "appConstants/routerPaths";
import { DetailsLayout } from "components/DetailsLayout/DetailsLayout.component";
import { Loader } from "components/Loader/Loader.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { transferDetailsPageService } from "service/shared/singletones/transferDetailsPageService/transferDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";
import { useParams } from "utils/libReplace/useParams";

import { TransferDetailsPageContent } from "./TransferDetailsPageContent/TransferDetailsPageContent.component";
import { STransferDetailsPageLoader } from "./TransferDetailsPageStyled/STransferDetailsPageLoader.styled";

export const TransferDetailsPage = createComponent("TransferDetailsPage", () => {
  const { t } = useTranslation();
  const { transferId } = useParams<{ transferId: string }>();

  useEffect(async () => {
    transferDetailsPageService.controller.setTransferId(transferId);
    await transferDetailsPageService.controller.getTransfer();
    return (): void => {
      transferDetailsPageService.controller.unMounted();
    };
  }, []);

  const loaded: boolean = useDefaultObservableBoolean(transferDetailsPageService.state.loaded);

  return (
    <DetailsLayout header={[{ title: t("transferDetailsPage.title"), src: routerPaths.transfers }]} title={`# ${transferId}`}>
      {loaded ? (
        <TransferDetailsPageContent />
      ) : (
        <STransferDetailsPageLoader>
          <Loader />
        </STransferDetailsPageLoader>
      )}
    </DetailsLayout>
  );
});
