import React from "react";

import { styled } from "../../theme/default/styled";
import { color_00346e } from "../../theme/setter/colorsValues/color_00346e";

export const SSwitchLangApp = styled.div``;
export const SSwitchLangAppBtn = styled.button.attrs((props) => ({
  type: "submit",
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 2px;
  border: 1px solid ${color_00346e};
  border-radius: 2px;
  margin: 3px 0px 3px 3px;
`;
