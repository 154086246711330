import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SendButton } from "./SendButton/SendButton.component";
import { SignInCapsLockErrorForm } from "./SignInCapsLockErrorForm/SignInCapsLockErrorForm.component";
import { SignInEmailInput } from "./SignInEmailInput/SignInEmailInput.component";
import { SignInErrorForm } from "./SignInErrorForm/SignInErrorForm.component";
import { SignInForgotPasswordButton } from "./SignInForgotPasswordButton/SignInForgotPasswordButton.component";
import { SSignInForm } from "./SignInPageStyled/SSignInForm.styled";
import { SSignInFormHeader } from "./SignInPageStyled/SSignInFormHeader.styled";
import { SignInPasswordInput } from "./SignInPasswordInput/SignInPasswordInput.component";
import { SignInRussianLetterErrorForm } from "./SignInRussianLetterErrorForm/SignInRussianLetterErrorForm.component";

export const SignInPage = createComponent("SignInPage", () => {
  const { t, i18n } = useTranslation();
  return (
    <SSignInForm>
      <SSignInFormHeader>{t("signInPage.title")}</SSignInFormHeader>
      <SignInEmailInput />
      <SignInPasswordInput />
      <SignInRussianLetterErrorForm />
      <SignInCapsLockErrorForm />
      <SignInErrorForm />
      <SignInForgotPasswordButton />
      <SendButton />
    </SSignInForm>
  );
});
