import { styled } from "theme/default/styled";
import { setFontSize_18px } from "theme/setter/setFontSizeValues/setFontSize_18px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const SChangePasswordFormTitle = styled.div`
  margin-bottom: 12px;

  ${setFontSize_18px};
  ${setFontWeight400};
`;
