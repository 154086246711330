import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useTranslation } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { AppSwitchLang } from "../../../App/AppSwitchLang.component";
import { SHeader } from "./HeaderStyled/SHeader.styled";
import { SHeaderLogo } from "./HeaderStyled/SHeaderLogo.styled";
import { Menu } from "./Menu/Menu.component";

export const Header = createComponent("Header", () => {
  const { i18n } = useTranslation();
  return (
    <SHeader isArabic={i18n.language === "ar"}>
      <SHeaderLogo isFinOnDelivery={isFinOn || isQi} isArabic={i18n.language === "ar"}>
        <SvgImage name={isFinOn ? "LogoFin" : isQi ? "LogoQi" : "Logo"} />
      </SHeaderLogo>
      <AppSwitchLang />
      <Menu />
    </SHeader>
  );
});
