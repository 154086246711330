import { SupportArticleModel } from "model/SupportArticle.model";
import { useDefaultObservableSupportArticleModelArray } from "observables/SupportArticleModelArrayObservable";
import { supportAdministrationPageService } from "service/shared/singletones/supportAdministrationPageService/supportAdministrationPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { SSupportAdministrationPageList } from "./SupportAdministrationPageListStyled/SSupportAdministrationPageList.styled";
import { SupportArticle } from "./SupportArticle/SupportArticle.component";

export const SupportAdministrationPageList = createComponent("SupportAdministrationPageList", () => {
  const supportArticles = useDefaultObservableSupportArticleModelArray(supportAdministrationPageService.state.supportArticlesList);

  const renderSupportArticle: RenderFunctionType<SupportArticleModel> = (supportArticle, index) => {
    return <SupportArticle key={index} supportArticle={supportArticle} />;
  };

  return <SSupportAdministrationPageList>{supportArticles.map(renderSupportArticle)}</SSupportAdministrationPageList>;
});
