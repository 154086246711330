import React from "react";
import { styled } from "theme/default/styled";
import { setFlexDFAICJCSB } from "theme/setter/setFlexValues/setFlexDFAICJCSB";

export const SHeader = styled.div<{ isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 100vw;
  padding: 14px;

  ${setFlexDFAICJCSB};
  ${(props) => props.isArabic && `flex-direction: row-reverse`};
`;
