import { css } from "./css";
import { setProps, Template } from "./setProps";

type Props = {
  isArabic: boolean;
};

export const setRowReverse = (): ((props: Props) => string | Template) => {
  return setProps<Props>(({ isArabic }) => isArabic)(css`
    flex-direction: row-reverse;
  `);
};
