import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { refundsPageService } from "service/shared/singletones/refundsPageService/refundsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { RefundsPageFilters } from "./RefundsPageFilters/RefundsPageFilters.component";
import { SRefundsPage } from "./RefundsPageStyled/SRefundsPage.styled";
import { RefundsTable } from "./RefundsTable/RefundsTable.component";

export const RefundsPage = createComponent("RefundsPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    refundsPageService.controller.mounted();

    return (): void => {
      refundsPageService.controller.unMounted();
    };
  }, []);

  return (
    <SRefundsPage isArabic={i18n.language === "ar"}>
      <RefundsPageFilters />
      <RefundsTable />
      <TableNavigation service={refundsPageService.state.filterNavigationService} />
    </SRefundsPage>
  );
});
