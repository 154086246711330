import { emailRegExp, phoneRegExp } from "appConstants/regexp";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type CreateInputOptions = {
  isEmail?: boolean;
  isPhone?: boolean;
  isNumber?: boolean;
  isTitle?: boolean;
  isDescription?: boolean;
};

export class InvoiceInputState {
  public readonly id: number;

  public readonly title: string;

  public readonly errorMessage: string;

  public readonly name: string;

  public readonly type: string;

  public readonly value: BehaviorSubjectString;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly valid: BehaviorSubjectBoolean;

  private readonly isEmail: boolean;

  public readonly isPhone: boolean;

  private readonly isNumber: boolean;

  public readonly isTitle: boolean;

  public readonly isDescription: boolean;

  public readonly langPath: string;

  public readonly langPathError: string;

  public constructor(id: number, title: string, errorMessage: string, name: string, langPath: string, langPathError: string, options?: CreateInputOptions) {
    this.id = id;
    this.title = title;
    this.errorMessage = errorMessage;
    this.name = name;
    this.type = "input";
    this.value = new BehaviorSubjectString("");
    this.showError = new BehaviorSubjectBoolean(false);
    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
    this.langPath = langPath;
    this.langPathError = langPathError;

    this.isEmail = options?.isEmail || false;
    this.isPhone = options?.isPhone || false;
    this.isNumber = options?.isNumber || false;
    this.isTitle = options?.isTitle || false;
    this.isDescription = options?.isDescription || false;
  }

  private readonly setValid = (value: string): boolean => {
    if (this.isEmail) {
      return emailRegExp.test(value);
    }
    if (this.isPhone) {
      return phoneRegExp.test(value);
    }
    if (this.isNumber) {
      return !Number.isNaN(+value) && +this.value.value > 0;
    }
    return value.trim() !== "";
  };
}
