import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDFFDCF1 } from "theme/setter/setFlexValues/setFlexDFFDCF1";

export const SStatisticConversionsContent = styled.div`
  margin-right: 16px;
  margin-left: 12px;
  padding: 10px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setFlexDFFDCF1};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
