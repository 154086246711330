import { useMultipleCustomSelectOptionContext } from "context/MultipleCustomSelectOption.context";
import { createComponent } from "utils/libExtend/createComponent";

import { MultipleCustomSelectOptionAll } from "./MultipleCustomSelectOptionAll/MultipleCustomSelectOptionAll.component";
import { MultipleCustomSelectOptionCommon } from "./MultipleCustomSelectOptionCommon/MultipleCustomSelectOptionCommon.component";

export const MultipleCustomSelectOptionContent = createComponent("MultipleCustomSelectOptionContent", () => {
  const { option } = useMultipleCustomSelectOptionContext();

  if (option) {
    return <MultipleCustomSelectOptionCommon />;
  }

  return <MultipleCustomSelectOptionAll />;
});
