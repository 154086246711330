export class ControlCellModel {
  public readonly id: number;

  public readonly title: string;

  public readonly active: boolean;

  public constructor(id: number, title: string, active: boolean) {
    this.id = id;
    this.title = title;
    this.active = active;
  }
}
