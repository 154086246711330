import { OptionModel } from "model/Option.model";

export const getInitialOptionList = (): OptionModel[] => {
  const initialOptionList: OptionModel[] = [];

  const startYear = 2020;
  const endYear = new Date().getFullYear();

  for (let year = startYear; year <= endYear; year++) {
    const title = String(year);
    const value = String(year);
    const optionModel = new OptionModel(title, value);
    initialOptionList.push(optionModel);
  }

  return initialOptionList;
};
