import { HeaderCellModel } from "model/HeaderCell.model";
import { TableEntityState } from "service/shared/others/TableEntityService/TableEntity.state";
import { TableService } from "service/shared/others/TableService/Table.service";
import { SortDictionary } from "types/business/SortDictionary";
import { Dictionary } from "types/commonExtend/Dictionary";

export class TableEntityController<T extends Dictionary<string>> {
  public readonly state: TableEntityState;

  public constructor(state: TableEntityState) {
    this.state = state;
  }

  public readonly setRewardDate = (rewardDate: string): void => {
    this.state.rewardDate = rewardDate;
  };

  public readonly mounted = (): void => {
    this.state.prevIsMounted = false;
    this.state.isMounted.next(true);
  };

  public readonly unMounted = (): void => {
    this.state.prevIsMounted = true;
    this.state.isMounted.next(false);
  };

  public readonly setTableService = (data: T[], initialHeaderCellList: HeaderCellModel[]): TableService => {
    return new TableService(data, initialHeaderCellList);
  };

  public readonly setInitialHeaderCellList = (sort: SortDictionary, desc: boolean): HeaderCellModel[] => {
    return this.state.getInitialHeaderCell(sort, desc, this.setNewSort);
  };

  private readonly setNewSort = (name: string): void => {
    const sameFieldName = this.state.sort.value.fieldName === name;
    this.state.desc.next(sameFieldName ? !this.state.desc.value : false);
    this.state.sort.next({ desc: this.state.desc.value, fieldName: name });
  };
}
