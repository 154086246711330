import React from "react";
import { css } from "theme/default/css";
import { keyframes } from "theme/default/keyframes";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_4 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_4";
import { color_rgba_0_0_0_07 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_07";
import { setBorderRadius_50p } from "theme/setter/setBorderRadiusValues/setBorderRadius_50p";
import { setFontSize_10px } from "theme/setter/setFontSizeValues/setFontSize_10px";

type SLoaderProps = {
  small: boolean;
};

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const smallSet = setProps<SLoaderProps>(({ small }) => small)(css`
  width: 2em;
  height: 2em;

  border-top: 0.44em solid ${color_rgba_0_0_0_07};
  border-right: 0.44em solid ${color_rgba_0_0_0_07};
  border-bottom: 0.44em solid ${color_rgba_0_0_0_07};
  border-left: 0.44em solid ${color_rgba_0_0_0_4};
`);

const smallNotSet = setProps<SLoaderProps>(({ small }) => !small)(css`
  width: 5em;
  height: 5em;

  border-top: 1.1em solid ${color_rgba_0_0_0_07};
  border-right: 1.1em solid ${color_rgba_0_0_0_07};
  border-bottom: 1.1em solid ${color_rgba_0_0_0_07};
  border-left: 1.1em solid ${color_rgba_0_0_0_4};
`);

const smallAfterSet = setProps<SLoaderProps>(({ small }) => small)(css`
  width: 2em;
  height: 2em;
`);

const smallAfterNotSet = setProps<SLoaderProps>(({ small }) => !small)(css`
  width: 5em;
  height: 5em;
`);

export const SLoader = styled.div<SLoaderProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  text-indent: -9999em;

  -webkit-animation: ${load8} 1.1s infinite linear;
  animation: ${load8} 1.1s infinite linear;

  ${setFontSize_10px};
  ${smallSet};
  ${smallNotSet};
  ${setBorderRadius_50p};

  &:after {
    ${smallAfterSet};
    ${smallAfterNotSet};
    ${setBorderRadius_50p};
  }
`;
