import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SNavigationMenuIconImg = styled.div`
  width: 40px;
  height: 40px;

  ${setFlexDFAIC};
  ${setFlexJCC};
`;
