import { Link } from "components/Link/Link.component";
import { styled } from "theme/default/styled";
import { setColor_1e70bf } from "theme/setter/setColorsValues/setColor_1e70bf";
import { setColor_4183c4 } from "theme/setter/setColorsValues/setColor_4183c4";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

export const SForgotPasswordFormLink = styled(Link)`
  text-decoration: none;

  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_4183c4};

  &:hover {
    ${setColor_1e70bf};
  }
`;
