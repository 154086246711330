import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useDefaultObservableSvgNames } from "observables/SvgNamesObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { UsersChangeRowInputProps } from "./UsersChangeRowInput.props";
import { SUsersChangeRowInput } from "./UsersChangeRowInputStyled/SUsersChangeRowInput.styled";
import { SUsersChangeRowInputIcon } from "./UsersChangeRowInputStyled/SUsersChangeRowInputIcon.styled";
import { SUsersChangeRowInputInput } from "./UsersChangeRowInputStyled/SUsersChangeRowInputInput.styled";
import { SUsersChangeRowInputTitle } from "./UsersChangeRowInputStyled/SUsersChangeRowInputTitle.styled";
import { SUsersChangeRowInputValue } from "./UsersChangeRowInputStyled/SUsersChangeRowInputValue.styled";

export const UsersChangeRowInput = createComponent<UsersChangeRowInputProps>("UsersChangeRowInput", (props) => {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const { title, service } = props;

  const active = useDefaultObservableBoolean(service.state.active);
  const value = useDefaultObservableString(service.state.value);
  const icon = useDefaultObservableSvgNames(service.state.icon);
  const valid = useDefaultObservableBoolean(service.state.valid);

  if (active) {
    return (
      <SUsersChangeRowInput isArabic={isArabic}>
        <SUsersChangeRowInputTitle>{title}</SUsersChangeRowInputTitle>
        <SUsersChangeRowInputInput valid={valid} value={value} onChange={service.controller.onChangeInput} />
        <SUsersChangeRowInputIcon onClick={service.controller.onClickEdit}>
          <SvgImage name={icon} />
        </SUsersChangeRowInputIcon>
      </SUsersChangeRowInput>
    );
  }

  return (
    <SUsersChangeRowInput isArabic={isArabic}>
      <SUsersChangeRowInputTitle>{title}</SUsersChangeRowInputTitle>
      <SUsersChangeRowInputValue>{value}</SUsersChangeRowInputValue>
      <SUsersChangeRowInputIcon onClick={service.controller.onClickEdit}>
        <SvgImage name={icon} />
      </SUsersChangeRowInputIcon>
    </SUsersChangeRowInput>
  );
});
