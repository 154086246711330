import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";

type SUsersChangePasswordInputProps = {
  showError: boolean;
};

const showErrorSet = setProps<SUsersChangePasswordInputProps>(({ showError }) => showError)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SUsersChangePasswordInput = styled.input<SUsersChangePasswordInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  width: 240px;
  height: 26px;
  margin-top: 10px;
  padding: 5px 25px 5px 5px;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};
  ${showErrorSet};
`;
