export const getChartOptions = (mirValue: number, visaValue: number, masterCardValue: number): any => {
  return {
    title: { x: "center" },
    tooltip: { trigger: "item" },
    legend: { orient: "vertical", left: "left", data: ["MIR", "VISA", "MASTER_CARD"] },
    series: [
      {
        type: "pie",
        radius: "70%",
        center: ["50%", "60%"],
        data: [
          { value: mirValue, name: "MIR" },
          { value: visaValue, name: "VISA" },
          { value: masterCardValue, name: "MASTER_CARD" },
        ],
      },
    ],
  };
};
