import React from "react";
import { styled } from "theme/default/styled";
import { color_f6b57a } from "theme/setter/colorsValues/color_f6b57a";
import { setBackgroundColor_rgba_246_181_122_40 } from "theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_246_181_122_40";
import { setBorderRadius_5px } from "theme/setter/setBorderRadiusValues/setBorderRadius_5px";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SSignInErrorForm = styled.div<{ $isArabic?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: auto;
  margin-bottom: 8px;
  padding: 8px;
  text-align: ${({ $isArabic }) => ($isArabic ? "right" : "left")};

  border: 1px solid ${color_f6b57a};

  ${setFlexDFFDC};
  ${setBorderRadius_5px};
  ${setBackgroundColor_rgba_246_181_122_40};
`;
