import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { FilterSelectProps } from "./FilterSelect.props";
import { SFilterSelect } from "./FilterSelectStyled/SFilterSelect.styled";
import { SFilterSelectClear } from "./FilterSelectStyled/SFilterSelectClear.styled";
import { SFilterSelectContainer } from "./FilterSelectStyled/SFilterSelectContainer.styled";
import { SFilterSelectDelete } from "./FilterSelectStyled/SFilterSelectDelete.styled";
import { SFilterSelectSelect } from "./FilterSelectStyled/SFilterSelectSelect.styled";
import { SFilterSelectSelectContainer } from "./FilterSelectStyled/SFilterSelectSelectContainer.styled";
import { SFilterSelectTitle } from "./FilterSelectStyled/SFilterSelectTitle.styled";

export const FilterSelect = createComponent<FilterSelectProps>("FilterSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const value = useDefaultObservableOptionModel(service.value);
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  return (
    <SFilterSelect>
      <SFilterSelectTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFilterSelectTitle>

      <SFilterSelectContainer isArabic={isArabic}>
        <SFilterSelectSelectContainer>
          <SFilterSelectSelect
            isTitle={value.isTitle}
            ref={service.inputRef}
            onChange={service.onChange}
            value={value}
            selectTitle={service.filterModel.selectTitle}
            selectTitleValue={service.filterModel.selectTitle}
            optionList={service.filterModel.optionList ?? []}
          />

          <SFilterSelectClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFilterSelectClear>
        </SFilterSelectSelectContainer>

        {filterService.state.showCloseIcon && (
          <SFilterSelectDelete isArabic={isArabic} onClick={service.onClickTrash}>
            <SvgImage width={20} height={20} name="DeleteFilter" />
          </SFilterSelectDelete>
        )}
      </SFilterSelectContainer>
    </SFilterSelect>
  );
});
