import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { dynamicPageService } from "service/shared/singletones/dynamicPageService/dynamicPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { GraphSectionContent } from "./GraphSectionContent/GraphSectionContent.component";

export const GraphSection = createComponent("GraphSection", () => {
  const loaded = useDefaultObservableBoolean(dynamicPageService.state.loaded);
  const fetched = useDefaultObservableBoolean(dynamicPageService.state.fetched);

  return loaded && fetched && <GraphSectionContent />;
});
