import { Link } from "components/Link/Link.component";
import { styled } from "theme/default/styled";
import { color_rgba_34_36_38_15 } from "theme/setter/opacityColorsValues/color_rgba_34_36_38_15";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";

export const SMenuListLink = styled(Link)`
  height: 50px;
  text-decoration: none;

  color: ${({ theme }) => theme.colors.main.menu_text};
  border-bottom: 1px solid ${color_rgba_34_36_38_15};

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBackgroundColor_ffffff};
`;

SMenuListLink.defaultProps = {
  theme: {
    colors: {
      main: {
        menu_text: "#0000EE",
      },
    },
  },
};
