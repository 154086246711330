import { invoiceDetailsPageController } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.controller";
import { invoiceDetailsPageState } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.state";

class Service {
  public readonly state = invoiceDetailsPageState;

  public readonly controller = invoiceDetailsPageController;
}

export const invoiceDetailsPageService = new Service();
