import { RolesSelectOptionModel } from "model/RolesSelectOption.model";
import { UsersChangeRowRolesController } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.controller";
import { UsersChangeRowRolesState } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.state";

export class UsersChangeRowRolesService {
  public readonly state: UsersChangeRowRolesState;

  public readonly controller: UsersChangeRowRolesController;

  public constructor(initialValue: RolesSelectOptionModel[], updateUserCallBack: () => void) {
    this.state = new UsersChangeRowRolesState(initialValue, updateUserCallBack);
    this.controller = new UsersChangeRowRolesController(this.state);
  }
}
