import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const statisticStepFilterModel = new FilterModel({
  id: 6,
  type: "select",
  title: t("statisticStepFilterModel.title"),
  name: "STATISTIC_STEP",
  options: {
    optionList: [
      new OptionModel("statisticStepFilterModel.DAY", "DAY"),
      new OptionModel("statisticStepFilterModel.MONTH", "MONTH"),
      new OptionModel("statisticStepFilterModel.YEAR", "YEAR"),
    ],
  },
  langPath: "statisticStepFilterModel.title",
});
