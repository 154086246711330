import React from "react";
import { css, setFlexDF, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  justify-content: flex-end;
`);

export const SPaymentCancelTableButtons = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-top: 32px;
  margin-bottom: 14px;
  margin-left: 12px;

  ${setFlexDF};
  ${isArabicLang};
`;
