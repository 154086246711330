import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPaymentActionInput } from "./PaymentActionInputStyled/SPaymentActionInput.styled";

export const PaymentActionInput = createComponent("PaymentActionInput", () => {
  const { t } = useTranslation();
  const value = useDefaultObservableString(paymentDetailsPageService.state.value);
  const amountShowError = useDefaultObservableBoolean(paymentDetailsPageService.state.amountShowError);
  const placeholder = t("paymentDetailsPageContent.actionInputPlaceholder") || "";

  return (
    <SPaymentActionInput
      showError={amountShowError}
      autoFocus
      type="text"
      value={value}
      onChange={paymentDetailsPageService.controller.onChange}
      placeholder={placeholder}
    />
  );
});
