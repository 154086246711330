import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { OptionModel } from "model/Option.model";

export const succesfullyFilterModel = new FilterModel({
  id: 4,
  type: "select",
  title: t("succesfullyFilterModel.title"),
  name: "STATUS",
  options: {
    optionList: [
      new OptionModel("succesfullyFilterModel.SUCCESS", "SUCCESS"),
      new OptionModel("succesfullyFilterModel.FAIL", "FAILED"),
      new OptionModel("succesfullyFilterModel.PROCESSING", "PROCESSING"),
    ],
  },
  langPath: "succesfullyFilterModel.title",
});
