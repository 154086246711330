import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableString } from "observables/StringObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPhoneInput } from "./PhoneInputStyled/SPhoneInput.styled";

export const PhoneInput = createComponent("PhoneInput", () => {
  const { t } = useTranslation();
  const phoneValue = useDefaultObservableString(paymentDetailsPageService.state.phoneValue);
  const phoneShowError = useDefaultObservableBoolean(paymentDetailsPageService.state.phoneShowError);
  const placeholder = t("paymentDetailsPageContent.phoneInputPlaceholder") || "";

  return (
    <SPhoneInput
      showError={phoneShowError}
      type="text"
      value={phoneValue}
      onFocus={paymentDetailsPageService.controller.onFocusPhone}
      onChange={paymentDetailsPageService.controller.onChangePhone}
      placeholder={placeholder}
    />
  );
});
