import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { FilterButton } from "../FilterButton/FilterButton.component";
import { FilterMenu } from "../FilterMenu/FilterMenu.component";
import { SFilters } from "./FiltersContentStyled/SFilters.styled";

export const FiltersContent = createComponent("FiltersContent", () => {
  const { service } = useFiltersContext();

  const open = useDefaultObservableBoolean(service.state.open);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return (
    <SFilters isArabic={isArabic}>
      <FilterButton />
      {open ? <FilterMenu /> : null}
    </SFilters>
  );
});
