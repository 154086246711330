import { BankType } from "../../../../types/business/BankType";
import { BehaviorSubject } from "../../../../types/libReplace/BehaviorSubject";

class State {
  public readonly bankData: BehaviorSubject<BankType[]>;

  public constructor() {
    this.bankData = new BehaviorSubject<BankType[]>([]);
  }
}

export const appState = new State();
