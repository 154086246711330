import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableDate } from "observables/DateObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { useDefaultObservableBoolean } from "../../../../observables/BooleanObservable";
import { RangeInputProps } from "./RangeInput.props";
import { SRangeInput } from "./RangeInputStyled/SRangeInput.styled";
import { SRangeInputContainer } from "./RangeInputStyled/SRangeInputContainer.styled";
import { SRangeInputDelete } from "./RangeInputStyled/SRangeInputDelete.styled";
import { SRangeInputLastPicker } from "./RangeInputStyled/SRangeInputLastPicker.styled";
import { SRangeInputStartPicker } from "./RangeInputStyled/SRangeInputStartPicker.styled";
import { SRangeInputTitle } from "./RangeInputStyled/SRangeInputTitle.styled";

import "react-datetime/css/react-datetime.css";

export const RangeInput = createComponent<RangeInputProps>("RangeInput", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();

  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  const startDate = useDefaultObservableDate(service.startValue);
  const endDate = useDefaultObservableDate(service.endValue);

  return (
    <SRangeInput isArabic={isArabic}>
      <SRangeInputTitle>{t("filters.rangeInputTitle")}</SRangeInputTitle>
      <SRangeInputContainer isArabic={isArabic}>
        <SRangeInputStartPicker
          isArabic={isArabic}
          onChange={service.onChangeStartDate}
          dateFormat={service.filterModel.dateFormat}
          timeFormat={service.filterModel.timeFormat}
          value={startDate}
        />
        <SRangeInputLastPicker
          isArabic={isArabic}
          onChange={service.onChangeEndDate}
          dateFormat={service.filterModel.dateFormat}
          timeFormat={service.filterModel.timeFormat}
          value={endDate}
        />
        {filterService.state.showCloseIcon && (
          <SRangeInputDelete onClick={service.onClickDelete}>
            <SvgImage width={20} height={20} name="DeleteFilter" />
          </SRangeInputDelete>
        )}
      </SRangeInputContainer>
    </SRangeInput>
  );
});
