import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SSendEmailButton } from "./SendEmailButtonStyled/SSendEmailButton.styled";

export const SendEmailButton = createComponent("SendEmailButton", () => {
  const { t } = useTranslation();
  return (
    <SSendEmailButton onClick={invoiceDetailsPageService.controller.onClickSendEmailButton}>{t("invoiceDetailsPage.sendEmailButtonTitle")}</SSendEmailButton>
  );
});
