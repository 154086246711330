import { createComponent } from "utils/libExtend/createComponent";

import { TerminalsListTableBody } from "./TerminalsListTableBody/TerminalsListTableBody.component";
import { TerminalsListTableHeader } from "./TerminalsListTableHeader/TerminalsListTableHeader.component";
import { STerminalsListTable } from "./TerminalsListTableStyled/STerminalsListTable.styled";

export const TerminalsListTable = createComponent("TerminalsListTable", () => {
  return (
    <STerminalsListTable>
      <TerminalsListTableHeader />
      <TerminalsListTableBody />
    </STerminalsListTable>
  );
});
