import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRefundButton } from "./RefundButtonStyled/SRefundButton.styled";

export const RefundButton = createComponent("RefundButton", () => {
  const { t } = useTranslation();
  return <SRefundButton onClick={paymentDetailsPageService.controller.onClickRefund}>{t("paymentDetailsPageContent.refundButtonTitle")}</SRefundButton>;
});
