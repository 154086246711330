import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableInvoiceDetailsModelNull } from "observables/InvoiceDetailsModelNullObservable";
import { CopyToClipboard } from "packages/react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { invoiceDetailsPageService } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.service";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { colorsValuesQi } from "theme/valuesQi/colorsValuesQi";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SCopyButton } from "./CopyButtonStyled/SCopyButton.styled";
import { SCopyButtonTitle } from "./CopyButtonStyled/SCopyButtonTitle.styled";

export const CopyButton = createComponent("CopyButton", () => {
  const { t } = useTranslation();
  const invoice = useDefaultObservableInvoiceDetailsModelNull(invoiceDetailsPageService.state.invoice);
  const formUrl = invoice?.formUrl || "";

  return (
    <CopyToClipboard text={formUrl} onCopy={invoiceDetailsPageService.controller.onClickCopyButton}>
      <SCopyButton>
        <SvgImage width={14} height={14} name="ContentCopy" color={isFinOn ? colorsValuesFin.color_1e70bf : isQi ? colorsValuesQi.color_1e70bf : undefined} />
        <SCopyButtonTitle>{t("invoiceDetailsPage.copyButtonTitle")}</SCopyButtonTitle>
      </SCopyButton>
    </CopyToClipboard>
  );
});
