import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

type Props = {
  isArabic: boolean;
};

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);

export const SSupportPageFeedbackForm = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 8px;

  ${setFlexDFFDC};
  ${isArabicLang};
`;
