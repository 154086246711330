import { useDefaultObservableCreateFieldServiceArray } from "observables/CreateFieldServiceArrayObservable";
import { InvoiceInputService } from "service/shared/others/InvoiceInputService/InvoiceInput.service";
import { InvoiceSelectService } from "service/shared/others/InvoiceSelectService/InvoiceSelect.service";
import { invoiceCreateFormService } from "service/shared/singletones/invoiceCreateFormService/invoiceCreateForm.service";
import { CreateFieldService } from "types/business/CreateFieldService";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { Input } from "./Input/Input.component";
import { SCreateFormList } from "./ListStyled/SCreateFormList.styled";
import { Select } from "./Select/Select.component";

export const List = createComponent("List", () => {
  const fields = useDefaultObservableCreateFieldServiceArray(invoiceCreateFormService.state.fields);

  const renderField: RenderFunctionType<CreateFieldService> = (service) => {
    if (service.state.type === "input") {
      return <Input service={service as InvoiceInputService} key={service.state.id} />;
    }
    if (service.state.type === "select") {
      return <Select service={service as InvoiceSelectService} key={service.state.id} />;
    }
    return null;
  };

  return <SCreateFormList>{fields.map(renderField)}</SCreateFormList>;
});
