import { styled } from "theme/default/styled";
import { setFlexDFFDC } from "theme/setter/setFlexValues/setFlexDFFDC";

export const SSupportAdministrationPageContainer = styled.div`
  height: fit-content;
  margin-left: 24px;
  padding-top: 24px;

  ${setFlexDFFDC};
`;
