import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SFilterButton } from "./FilterButtonStyled/SFilterButton.styled";
import { SFilterButtonButton } from "./FilterButtonStyled/SFilterButtonButton.styled";
import { SFilterButtonClear } from "./FilterButtonStyled/SFilterButtonClear.styled";
import { SFilterButtonHider } from "./FilterButtonStyled/SFilterButtonHider.styled";
import { SFilterButtonTitle } from "./FilterButtonStyled/SFilterButtonTitle.styled";

export const FilterButton = createComponent("FilterButton", () => {
  const { t } = useTranslation();
  const { service } = useFiltersContext();

  const open = useDefaultObservableBoolean(service.state.open);
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return (
    <SFilterButton open={open} isArabic={isArabic}>
      <SFilterButtonButton onClick={service.controller.onClickButton} isArabic={isArabic}>
        <SvgImage name="Search" />
        <SFilterButtonTitle isArabic={isArabic}>{t("filters.filterButton")}</SFilterButtonTitle>
      </SFilterButtonButton>
      <SFilterButtonClear isArabic={isArabic} open={open} disabled={!open} onClick={service.controller.onClickCross}>
        {service.state.showCloseIcon && <SvgImage name="Clear" />}
      </SFilterButtonClear>
      {open ? <SFilterButtonHider /> : null}
    </SFilterButton>
  );
});
