import { ChartValue } from "./ChartValue";
import { getCountOfSystem } from "./getCountOfSystem";

export const produceChartStatistic = (
  statistics: ChartValue[]
): {
  mirValue: number;
  visaValue: number;
  masterCardValue: number;
} => {
  const newStatistics = statistics.map((el) => ({ ...el }));
  const mirValue = getCountOfSystem(newStatistics, "MIR");
  const visaValue = getCountOfSystem(newStatistics, "VISA");
  const masterCardValue = getCountOfSystem(newStatistics, "MASTER_CARD");

  return {
    mirValue: +mirValue.toFixed(2),
    visaValue: +visaValue.toFixed(2),
    masterCardValue: +masterCardValue.toFixed(2),
  };
};
