import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_47a0ef } from "theme/setter/colorsValues/color_47a0ef";
import { color_a0b9d2 } from "theme/setter/colorsValues/color_a0b9d2";
import { color_e0b4b4 } from "theme/setter/colorsValues/color_e0b4b4";
import { setBackgroundColor_f0fbff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f0fbff";
import { setBackgroundColor_fff6f6 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_fff6f6";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_2d3033 } from "theme/setter/setColorsValues/setColor_2d3033";
import { setColor_9f3a38 } from "theme/setter/setColorsValues/setColor_9f3a38";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight200 } from "theme/setter/setFontWeightValues/setFontWeight200";

type SPaymentActionInputProps = {
  showError: boolean;
};

const showErrorSet = setProps<SPaymentActionInputProps>(({ showError }) => showError)(css`
  border: 1px solid ${color_e0b4b4};

  ${setColor_9f3a38};
  ${setBackgroundColor_fff6f6};
`);

export const SPaymentActionInput = styled.input<SPaymentActionInputProps & React.InputHTMLAttributes<HTMLInputElement>>`
  height: 26px;
  padding: 5px;
  outline: none;

  border: 1px solid ${color_a0b9d2};

  ${setFontSize_13px};
  ${setFontWeight200};
  ${setColor_2d3033};
  ${setBorderRadius_4px};
  ${showErrorSet};

  &:focus {
    border: 1px solid ${color_47a0ef};

    ${setBackgroundColor_f0fbff};
  }
`;
