import React from "react";
import { resetScrollbar, setFlexDFFDCF1, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SGroupTableBody = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow-x: hidden;
  overflow-y: scroll;

  ${setFlexDFFDCF1};
  ${resetScrollbar};
  ${setTextRightArabic()};
`;
