import React from "react";
import { css, setFlexDFAIC, setProps, styled } from "theme";

interface SFilterSelectorContainerProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFilterSelectorContainerProps>(({ isArabic }) => isArabic)(css`
  flex-direction: row-reverse;
`);

export const SFilterSelectorContainer = styled.div<SFilterSelectorContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFlexDFAIC};
  ${isArabicLang};
`;
