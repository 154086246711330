import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { userInfoPageService } from "service/shared/singletones/userInfoPageService/userInfoPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SChangePasswordSectionCapsLockErrorForm } from "./ChangePasswordSectionCapsLockErrorFormStyled/SChangePasswordSectionCapsLockErrorForm.styled";
import { SChangePasswordSectionCapsLockErrorFormTitle } from "./ChangePasswordSectionCapsLockErrorFormStyled/SChangePasswordSectionCapsLockErrorFormTitle.styled";

export const ChangePasswordSectionCapsLockErrorForm = createComponent("ChangePasswordSectionCapsLockErrorForm", () => {
  const { t } = useTranslation();
  const showCapsLockError = useDefaultObservableBoolean(userInfoPageService.state.showCapsLockError);

  const hideForm = !showCapsLockError;

  if (hideForm) {
    return null;
  }

  return (
    <SChangePasswordSectionCapsLockErrorForm>
      <SChangePasswordSectionCapsLockErrorFormTitle>{t("changePasswordSection.capsLockError")}</SChangePasswordSectionCapsLockErrorFormTitle>
    </SChangePasswordSectionCapsLockErrorForm>
  );
});
