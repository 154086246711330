import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { h2hPageService } from "service/shared/singletones/h2hPageService/h2hPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { getErrorMessage } from "./getErrorMessage";
import { SH2HErrorMessage } from "./H2HErrorMessageStyled/SH2HErrorMessage.styled";
import { SH2HErrorMessageContainer } from "./H2HErrorMessageStyled/SH2HErrorMessageContainer.styled";
import { SH2HErrorMessageTitle } from "./H2HErrorMessageStyled/SH2HErrorMessageTitle.styled";

export const H2HErrorMessage = createComponent("H2HErrorMessage", () => {
  const merchantError = useDefaultObservableBoolean(h2hPageService.merchantError);

  if (!merchantError) return null;

  const errorMessage = getErrorMessage(merchantError);

  return (
    <SH2HErrorMessage>
      <SH2HErrorMessageContainer>
        <SH2HErrorMessageTitle>{errorMessage}</SH2HErrorMessageTitle>
      </SH2HErrorMessageContainer>
    </SH2HErrorMessage>
  );
});
