import React from "react";
import { color_rgba_183_200_220_80, resetScrollbar, setBackgroundColor_ffffff, setBorderRadius_8px, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const STerminalsPage = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  overflow-y: scroll;
  width: 1000px;
  margin-bottom: 8px;
  margin-left: 15px;
  padding: 16px 20px 20px 16px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${resetScrollbar};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  ${setTextRightArabic()};
`;
