import { Outlet } from "packages/react-router-dom";
import { useTranslation } from "react-i18next";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { AppSwitchLang } from "../../App/AppSwitchLang.component";
import { SvgImage } from "../SvgImage/SvgImage.component";
import { SFormLayout } from "./FormLayoutStyled/SFormLayout.styled";
import { SFormLayoutFooter } from "./FormLayoutStyled/SFormLayoutFooter.styled";
import { SFormLayoutLogo } from "./FormLayoutStyled/SFormLayoutLogo.styled";

export const FormLayout = createComponent("FormLayout", () => {
  const { t } = useTranslation();
  const widthImage = isFinOn ? 344 : isQi ? 250 : 250;
  const heightImage = isFinOn ? 50 : isQi ? 250 : 80;
  return (
    <SFormLayout>
      <AppSwitchLang />
      <SFormLayoutLogo finOnWidth={`${widthImage}px`}>
        <SvgImage width={widthImage} height={heightImage} name={isFinOn ? "LogoFin" : isQi ? "LogoQi" : "Logo"} />
      </SFormLayoutLogo>
      <Outlet />
      <SFormLayoutFooter>
        {isFinOn || isQi ? t("formLayout.topTitle") : t("formLayoutSNGB.topTitle")}
        <br />
        {isFinOn || isQi ? t("formLayout.bottomTitle") : t("formLayoutSNGB.bottomTitle")}
      </SFormLayoutFooter>
    </SFormLayout>
  );
});
