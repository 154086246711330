export const translationEn = {
  loading: "Loading...",
  h2hPage: {
    errorMessage: "Fields are not entered ",
    errorMessageMerchant: "Merchant",
  },
  activateInvitePage: {
    activate: "Activate",
    activationCode: "OTP code from SMS",
    capsLockError: "Caps lock is switched on",
    password: "New password",
    redirectToSignIn: "Redirecting to login",
    repeatedPassword: "Repeat password",
    successMessage: "Success",
    title: "User activation",
  },
  activateInvitePageService: {
    tokenErrorTitle: "Activation error, please contact administrator",
  },
  amountFilterModel: {
    title: "Amount",
  },
  auditPageConfig: {
    created: "Creation date",
    details: "Details",
    executor: "Executor",
    requestType: "Request type",
    sourceIp: "IP address",
    successfully: "Status",
  },
  auditUserIdModel: {
    title: "User",
  },
  authCodeFilterModel: {
    title: "Authorisation code",
  },
  authIdFilterModel: {
    title: "Auth ID",
  },
  cardHolderFilterModel: {
    title: "Cardholder name",
  },
  cardNumberFilterModel: {
    title: "PAN",
  },
  changeEmailPhoneInputSection: {
    email: "Email",
    phone: "Phone number",
    title: "Change email and password",
  },
  changePasswordSection: {
    buttonTitle: "Change password",
    capsLockError: "Caps lock is switched on",
    currentPasswordTitle: "Current password",
    digitsError: "Password should have digits",
    lengthError: "Password should be from {{minLength}} to {{maxLength}} symbols length",
    lowercaseLettersError: "Password should have lowercase letters",
    newPasswordTitle: "New password",
    repeatedNewPasswordTitle: "Repeat new password",
    russianLetterError: "Password should have only latin letters",
    showCurrentPasswordEqualNewPasswordError: "New password should differ from an old one",
    showNewPasswordNotEqualRepeatedNewPasswordError: "New password do not match",
    specialSymbolsError: "Password should contain special symblos (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >)",
    title: "Change password",
    uppercaseLettersError: "Password should contain uppercase letters",
  },
  compareMonthService: {
    aprilTitle: "April",
    augustTitle: "August",
    decemberTitle: "December",
    februaryTitle: "February",
    januaryTitle: "January",
    julyTitle: "July",
    juneTitle: "June",
    marchTitle: "March",
    mayTitle: "May",
    novemberTitle: "November",
    octoberTitle: "October",
    septemberTitle: "September",
  },
  controlsSelect: {
    title: "Rows per page",
  },
  createUserFetchedSelectService: {
    initialOptionTitle: "Loading...",
  },
  createUserServiceFormService: {
    email: "Email",
    merchantGroupIdMessage: "Group is not chosen",
    merchantGroupIdTitle: "Choose a group",
    name: "Username",
    phoneNumber: "Phone number",
    rolesMessage: "Role is not chosen",
    rolesTitle: "Choose a role",
  },
  customDateSelect: {
    applyButtonTitle: "Apply",
    closeButtonTitle: "Cancel",
  },
  customMultipleSelect: {
    allOptionTitle: "All",
    buttonTitle: "Chosen: {{value}}",
  },
  dateFilterModel: {
    title: "Dates, interval",
  },
  dynamicPage: {
    applyButton: "Apply",
    compareParamTitle: "Comparison parameters",
    firstGroupTitle: "First group",
    graphTitle: "Metrics",
    monthInputTitle: "Month",
    notEnoughDataErrorMessageTitle: "Not enoght data to compare",
    pageTitle: "Dynamic",
    secondGroupTitle: "Second group",
    terminalInputTitle: "Terminals",
    xAxisTitle: "Day",
    yAxisTitle: "Transactions amount, AED",
    yearInputTitle: "Year",
  },
  filterFetchedSelectDataModel: {
    loadedTitle: "Loading...",
  },
  filters: {
    addFilterButton: "Add filter",
    applyButtonTitle: "Apply",
    filterButton: "Filters",
    filterSelectorSelectTitle: "Choose a filter",
    filterSelectorTitle: "Add filter",
    rangeInputTitle: "Dates, interval",
    resetButton: "Reset",
    downloadButtonTitle: "Export to file",
    downloadErrorMax: "You are trying to upload more than 5000 records",
    cvsNamePayments: "Payments",
    cvsNameInvoices: "Bills",
    cvsNameTransfers: "Transfers",
    cvsNameRefunds: "Refunds",
  },
  forgotPasswordFormService: {
    resetPasswordMessage: "Password recovery email was sent",
  },
  forgotPasswordPage: {
    backButton: "Back",
    emailPlaceholder: "Email",
    sendButtonTitle: "Send",
    title: "Password recovery",
  },
  formLayout: {
    bottomTitle: "United Arab Emirates, Dubai",
    topTitle: "© 2024 FinOn LLC",
  },
  formOpenButton: {
    title: "New invoce",
  },
  groupTableBodyService: {
    detailsTitle: "Details",
    emailTitle: "Email",
    groupTitle: "Group",
    nameTitle: "Name",
    phoneNumberTitle: "Phone number",
  },
  invoiceCreateForm: {
    cancelButtonTitle: "Cancel",
    createButtonTitle: "Create",
  },
  invoiceCreateFormService: {
    amountErrorMessage: "Incorrect amount",
    amountTitle: "Amount",
    oneStagePaymentTypeTitle: "Single stage",
    twoStagePaymentTypeTitle: "Two-stage",
    paymentTypeTitle: "Payment type",
    paymentTypeErrorMessage: "Payment type entered incorrectly",
    createInvoiceMessage: "Invoice was created",
    currencyErrorMessage: "Invalid currency",
    currencyTitle: "Currency",
    descriptionErrorMessage: "Invalid description",
    descriptionTitle: "Description",
    emailErrorMessage: "Invalid email",
    emailTitle: "Customer email",
    phoneErrorMessage: "Invalid phone number",
    phoneTitle: "Customer phone number",
    terminalErrorMessage: "Invalid terminal",
    terminalTitle: "Terminal",
    titleErrorMessage: "Incorrect title",
    titleTitle: "Title",
  },
  invoiceDetailsPage: {
    amount: "Invoice amount",
    copyButtonTitle: "Invoice link",
    creationDate: "Created",
    currency: "Currency",
    deleteButton: "Cancel",
    description: "Invoice description",
    email: "Customer email",
    headerTitle: "Invoices",
    phone: "Customer phone numbe",
    sendSmsButtonTitle: "Send SMS",
    sendEmailButtonTitle: "Send email",
    status: "Status",
    tableTitle: "Transaction",
    terminalId: "Terminal ID",
    title: "Invoices",
  },
  invoiceDetailsPageConfig: {
    details: "Details",
    paymentId: "Payment ID",
    status: "Status",
  },
  invoiceDetailsPageService: {
    linkCopied: "Invoice link copied",
    messageSendEmailTitle: "Message send by emai",
    messageSendSmsTitle: "Message send by SMS",
    paymentRemove: "Invoice deleted",
  },
  invoiceIdFilterModel: {
    title: "Invoice IDs",
  },
  invoicesPageConfig: {
    amount: "Amount",
    creationDate: "Creation date",
    details: "Details",
    email: "Customer email",
    invoiceId: "Invoice IDs",
    phone: "Customer phone number",
    status: "Status",
    terminalId: "Terminal ID",
  },
  mainLayout: {
    auditTitle: "Auditing",
    h2h: "H2H",
    logoutTitle: "Profile",
    profileTitle: "Logout",
    supportAdministration: "Support",
    terminalsTitle: "Terminals",
    title: "Merchant Portal UI v1.1.7",
    userTitle: "User management",
  },
  multipleTerminalFilterModel: {
    title: "Terminals",
  },
  navigationMenuService: {
    h2h: "H2H",
    dynamic: "Dynamic",
    invoices: "Invoices",
    payments: "Transactions",
    refunds: "Refunds",
    reward: "Settlement",
    statistic: "Statistics",
    support: "Support",
    transfers: "Transfers",
  },
  notFoundPage: {
    title: "Page not found",
  },
  operationStatusFilterModel: {
    title: "Transaction status",
    AUTHENTICATED: "Passed 3DS",
    AUTHENTICATION_FAILED: "Not successful 3DS",
    AUTHENTICATION_REQUIRED: "Requires 3DS",
    AUTHENTICATION_STARTED: "3DS started",
    CREATED: "Created",
    ERROR: "Error",
    EXPIRED: "Timeout exceeded",
    FORM_SHOWED: "Form shown",
    PROCESSING: "In processing",
    THREE_DS_METHOD_CALL_REQUIRED: "3DS method",
    FAILED: "Failed",
    SUCCESS: "Success",
  },
  operationTypeFilterModel: {
    MIR_PAY: "Mir Pay",
    APPLE_PAY: "Apple Pay",
    CARD: "Card",
    GOOGLE_PAY: "Google Pay",
    PAYMENT_TOKEN: "Payment token",
    SAMSUNG_PAY: "Samsung Pay",
    SBP: "SBP",
    title: "Transaction type",
  },
  panFilterModel: {
    title: "PAN",
  },
  paymentDetailsPage: {
    backButtonButton: "Back",
    cancelSwitchTitle: "Refunds",
    cancelTitle: "Cancel",
    confirmSwitchTitle: "Settlements",
    confirmTitle: "Confirm",
    enterCancelSumTitle: "Enter cancelation amont",
    enterConfirmSumTitle: "Enter settlement amont",
    enterRefundSumTitle: "Enter refund amount",
    headerTitle: "Transactions",
    partialActionButton: "Partial",
    paymentCancelMessage: "Transaction was canceled",
    paymentConfirmMessage: "Transaction was confirmed",
    paymentRefundMessage: "Transaction was refunded",
    refundSwitchTitle: "Refunds",
    refundTitle: "Refund",
    additionalInfoTitle: "Additional Information",
    selectCancelTitle: "Select cancelation type",
    selectConfirmTitle: "Select settlement type",
    selectRefundTitle: "Select refund type",
  },
  paymentDetailsPageCancelConfig: {
    amount: "Amount",
    creationDate: "Creation date",
    status: "Status",
  },
  paymentDetailsPageConfirmConfig: {
    amount: "Amount",
    creationDate: "Creation date",
    status: "Status",
  },
  paymentDetailsPageContent: {
    phoneInputPlaceholder: "Enter phone",
    actionInputPlaceholder: "Enter amount",
    additionalInfo: "Additional information",
    amount: "Transaction amount",
    trxId: "TRX_ID",
    authId: "Authorisation code",
    cancelButtonTitle: "Cancel",
    canceled: "Canceled",
    cardHolder: "Cardholder name",
    confirmButtonTitle: "Confirm",
    confirmedAmount: "Confirmed amount",
    creationDate: "Creation date time",
    currency: "Transaction currency",
    externalRrn: "External RRN",
    fee: "Fee",
    fullActionButtonTitle: "Full amount",
    maskedPan: "PAN",
    paymentSystem: "Payment scheme",
    refundButtonTitle: "Refund",
    refundsAmount: "Refunds amount",
    requestId: "Request ID",
    resultCode: "Response code",
    rrn: "RRN",
    status: "Status",
    terminalId: "Terminal ID",
    type: "Transaction type",
  },
  paymentDetailsPageRefundConfig: {
    amount: "Amount",
    creationDate: "Creation date",
    details: "Details",
    status: "Status",
  },
  paymentIdFilterModel: {
    title: "Transaction ID",
  },
  paymentRefundDetailsPage: {
    titlePayment: "Transactions",
    titleRefund: "Refunds",
  },
  paymentRefundDetailsPageContent: {
    amount: "Invoice amount",
    authId: "Authorisation code",
    creationDate: "Creation date time",
    currency: "Invoice currency",
    maskedPan: "PAN",
    paymentSystem: "Payment scheme",
    resultCode: "Response code",
    rrn: "RRN",
    externalId: "External ID",
    status: "Status",
    terminalId: "Terminal ID",
  },
  paymentSystemTypeFilterModel: {
    mastercard: "Mastercard",
    mir: "Mir",
    title: "Payment Scheme",
    visa: "Visa",
  },
  h2hPageConfig: {
    details: "Details",
    terminalTzOperationTime: "Terminal timezone",
    processingTzOperationTime: "Host timezone",
    merchantId: "Merchant ID",
    terminalId: "Terminal ID",
    amount: "Transaction amount",
    refundsAmount: "Refunds amount",
    messageCode: "Message code",
    responseCode: "Response code",
    maskedPan: "PAN",
    rrn: "RRN",
    transactionType: "Transaction type",
    authId: "Authorisation code",
    pointOfServiceEntryMode: "POS entry mode",
    eci: "ECI",
    mcc: "MCC",
    terminalName: "Terminal name",
    terminalCity: "City",
    terminalRegion: "Region",
    terminalCountry: "Country",
    currency: "Currency code",
    acquireId: "Acquirer ID",
    paymentSystem: "Payment scheme",
  },
  paymentsPageConfig: {
    amount: "Amount",
    creationDate: "Date and time",
    details: "Details",
    maskedPan: "PAN (masked)",
    paymentId: "Transaction ID",
    rrn: "RRN",
    status: "Status",
    terminalId: "Terminal ID",
  },
  refundDetailsPage: {
    headerTitle: "Refunds",
  },
  refundDetailsPageContent: {
    amount: "Refund amount",
    authId: "Authorisation code",
    creationDate: "Creation date time",
    currency: "Invoice currency",
    maskedPan: "PAN",
    paymentSystem: "Payment scheme",
    requestId: "Request ID",
    resultCode: "Response code",
    rrn: "RRN",
    successful: "Status",
    tableTitle: "Invoice",
    terminalId: "Terminal ID",
  },
  refundDetailsPageService: {
    details: "Details",
    paymentId: "Payment ID",
  },
  refundIdFilterModel: {
    title: "Refund ID",
  },
  refundPaymentFilterModel: {
    title: "Payment ID",
  },
  refundsPageConfig: {
    amount: "Amount",
    creationDate: "Date and time",
    details: "Details",
    maskedPan: "PAN (masked)",
    refundId: "Refund ID",
    rrn: "RRN",
    status: "Status",
    terminalId: "Terminal ID",
  },
  requestIdFilterModel: {
    title: "Request ID",
  },
  resetPasswordFormService: {
    changedPasswordTitle: "Password was changed successfully",
  },
  resetPasswordPage: {
    backButtonTitle: "Back",
    passwordPlaceholder: "Password",
    repeatPasswordPlaceholder: "Repeat password",
    sendButtonTitle: "Send",
    title: "Change password",
  },
  resultCodeFilterModel: {
    title: "Response code",
  },
  rewardDetailsPage: {
    informationContentFeeAmountTitle: "Fee",
    informationContentIncomeAmountTitle: "Amount",
    informationContentNotFound: "Data not found",
    informationContentSettlementAmountTitle: "Settlement",
  },
  rewardDetailsPageConfig: {
    amount: "Transaction amount",
    authId: "Authorisation code",
    fee: "Fee",
    operTime: "Transaction time",
    pan: "PAN",
    paymentId: "Invoice ID",
    rrn: "RRN",
    settlement: "Settlements",
    terminalId: "Terminal ID",
    terminalName: "Terminal name",
  },
  rewardsPageConfig: {
    date: "Settlement date",
    details: "Details",
    feeAmount: "Fee amount",
    incomeAmount: "Transaction amount",
    operationsNumber: "Transaction count",
    settlementAmount: "Settlement amount",
  },
  signInFormService: {
    accessDeniedTitle: "Access denied",
    systemUserAccessDenied: "System user access denied",
  },
  signInPage: {
    capsLockError: "Caps lock is switched on",
    digitsError: "Password should have digits",
    emailTitle: "Email",
    forgotPasswordButton: "Forgot your password?",
    lengthError: "Password should be from {{minLength}} to {{maxLength}} symbols length",
    lowercaseLettersError: "Password should have lowercase letters",
    passwordTitle: "Password",
    russianLetterError: "Password should have only latin letters",
    sendButton: "Login",
    showPasswordNotEqualRepeatedPasswordError: "New password do not match",
    specialSymbolsError: "Password should contain special symblos (@ # $ % ^ & ; : * _ - + = ! ? [ ] { } / \\ . , < >)",
    title: "Login to merchant portal",
    uppercaseLettersError: "Password should contain uppercase letters",
  },
  statisticPage: {
    conversionsTitle: "Transaction graph",
    errorMessage: "Required fields are empty",
    failTitle: "Failed",
    paymentSystemTitle: "Payment schemes",
    successTitle: "Successful",
  },
  statisticPageConfig: {
    datePart: "Date",
    failQuantity: "Failed",
    refundQuantity: "Refunds",
    successQuantity: "Successful",
    successSum: "Total",
    terminalId: "Terminal",
  },
  statisticStepFilterModel: {
    DAY: "Day",
    MONTH: "Month",
    YEAR: "Year",
    title: "Aggregation step",
  },
  statisticTypeFilterModel: {
    QUANTITY: "Quantity",
    SUM: "Amount",
    title: "Aggregation type",
  },
  succesfullyFilterModel: {
    FAIL: "FAILED",
    SUCCESS: "SUCCESS",
    PROCESSING: "PROCESSING",
    title: "Status",
  },
  supportAdministrationPage: {
    applyChangesButtonTitle: "Apply changes",
    articleFormApplyButtonTitle: "Create article",
    createArticleDescriptionInputPlaceholder: "Enter description",
    createArticleTypeInputPlaceholder: "Enter type",
    emailInputPlaceholder: "Enter email",
    feedbackTitle: "Feedback",
    openCreateSupportButtonTitle: "Create support article",
  },
  supportPage: {
    feedBackFormTextFieldPlaceholder: "Enter message",
    feedbackFormSelector: "Enter subject",
    feedbackFormSendButtonTitle: "Send",
    feedbackFormTitle: "Feedback",
    listTitle: "FAQ",
    pageTitle: "Support",
  },
  tableContent: {
    notDataTitle: "Support",
  },
  tableNavigation: {
    totalOperationsTitle: "Total transactions ",
    totalPageTitle: "Total pages",
  },
  terminalFilterModel: {
    title: "Terminal",
  },
  merchantFilterModel: {
    title: "Merchant",
  },
  rrnFilterModel: {
    title: "RRN",
  },
  terminalsPage: {
    countPostTitle: "records from found",
    countPreTitle: "Displayed",
    filterTitle: "Filter",
    terminalListTitle: "Terminals",
    terminalsApplyButtonTitle: "Apply",
    terminalsClearFilterButtonTitle: "Reset filters",
    terminalsFilterSelectTitle: "Filter",
    terminalsListTableHeaderMerchantName: "Merchant name",
    terminalsListTableHeaderTerminalId: "Terminal ID",
  },
  toastContainerService: {
    errorTitle: "Error",
    noInfoTitle: "No data found",
    successTitle: "Success",
  },
  transferDetailsPage: {
    cancelTransferButtonTitle: "Cancel transfer",
    title: "Transfers",
  },
  transferDetailsPageConfig: {
    creationDate: "Creation date",
    status: "Status",
  },
  transferDetailsPageContent: {
    amountTitle: "Amount",
    cancelButtonTitle: "Cancel transfer",
    canceledTitle: "Canceled",
    creationDateTitle: "Transfer date",
    currencyTitle: "Currency",
    destinationAuthId: "Authorisation code",
    destinationExternalRrn: "External RRN",
    destinationMaskedPan: "PAN",
    destinationPaymentSystem: "Payment scheme",
    destinationResultCode: "Response code",
    destinationRrn: "RRN",
    destinationTitle: "Destination",
    fee: "Fee",
    requestIdTitle: "Request ID",
    sourceAuthId: "Authorisation code",
    sourceCardHolder: "Cardholder",
    sourceExternalRrn: "External RRN",
    sourceMaskedPan: "PAN",
    sourcePaymentSystem: "Payment scheme",
    sourceResultCode: "Response code",
    sourceRrn: "RRN",
    sourceTitle: "Source",
    statusTitle: "Status",
    terminalIdTitle: "Terminal ID",
    typeTitle: "Transfer type",
  },
  transferDetailsPageService: {
    transferCancel: "Transfer was canceled",
  },
  transferIdFilterModel: {
    title: "Transfer ID",
  },
  transferTypeFilterModel: {
    AFT: "AFT",
    C2C: "C2C",
    OCT: "OCT",
    title: "Transaction type",
  },
  transfersPageConfig: {
    amount: "Amount",
    creationDate: "Creation date",
    details: "Details",
    requestId: "Request ID",
    status: "Status",
    terminalId: "Terminal ID",
    transferId: "Transfer ID",
    type: "Transaction type",
  },
  userInfoPage: {
    title: "User info",
  },
  userInfoPageContent: {
    email: "Email",
    id: "ID",
    name: "Username",
    phone: "Phone number",
    roles: "Roles",
  },
  userInfoPageService: {
    changedEmailPhoneTitle: "Phone number and Email were successfully changed",
    changedPasswordTitle: "Password was changed successfully",
    errorBadCredentialsTitle: "Session expired",
    errorEmailExistTitle: "Email is already registred",
    errorInvitationCodeTitle: "Invalid initation code",
    errorNotExistPaymentTitle: "Invoice not found",
    errorPasswordEqualTitle: "New password is same as previous one",
    errorPasswordExpireTitle: "Password expired",
    errorPasswordTitle: "Password was not changed",
    errorPasswordUnEqualTitle: "Current password is incorrect",
    errorPasswordWeakTitle: "New password does not fit security requirements",
    errorSessionExpiredTitle: "Session expired",
    errorTerminalsNotAvailableTitle: "Terminals are restricted for current user",
    errorUserWithEmailNotExistTitle: "Invalid email",
    errorNotSuccess: "Not successful",
  },
  usersChangeRowRolesService: {
    administratorDescription: "Access to all functionality for merchant or merchants group",
    rInvoiceDescription: "Access to read Invoices data",
    rOperationDescription: "Access to read Transactions data",
    rH2H: "Access to read H2H data",
    rRefundDescription: "Access to read Refunds data",
    rRewardDescription: "Access to read Settlements data",
    rStatisticsDescription: "Access to Dynamic and Statistics",
    rTransferDescription: "Access to read Transfers data",
    wInvoiceDescription: "Access to Invoices creation",
    xCancelDescription: "Access to Transactions cancelation",
    xConfirmDescription: "Access to Transactions confirmation",
    xInvoiceDescription: "Access to Invoices cancelation or sending",
    xRefundDescription: "Access to Refunds execution",
    xTransferDescription: "Access to Transfer execution",
  },
  usersDetailsPage: {
    blockUserButtonTitle: "Block user",
    blockedFlag: "Blocked",
    changePasswordButtonTitle: "Change password",
    changePasswordFormTitle: "Change password",
    email: "Email",
    enabled: "Active",
    id: "ID",
    merchantGroupName: "Merchant group",
    name: "Username",
    phone: "Phone number",
    resetPasswordApplyButton: "Apply",
    resetPasswordBackButton: "Back",
    resetPasswordButtonTitle: "Reset Password",
    roles: "Roles",
    rolesFormApplyButton: "Apply",
    rolesFormBackButton: "Back",
    rolesSelectFormTitle: "Roles",
    sessionDropButtonTitle: "Drop user sessions",
    unBlockUserButtonTitle: "Unblock user",
    userResetPasswordFormTitle: "Are you sure that you want to reset password?",
  },
  usersDetailsPageService: {
    currentPasswordTitle: "Current password",
    newPasswordTitle: "New password",
    passwordWasReset: "Password was reset",
    sessionIsNotExist: "No active session",
    sessionWasBreak: "Session interupted",
    title: "User info",
  },
  usersInfoPage: {
    createUserFormTitle: "Create user invitation",
    createUserInviteButtonTitle: "Create invitation",
    createUserSelectErrorMessage: "Please enter correct value",
    openUserFormButton: "Add",
    createMultipleSelectTitle: "Choose a role",
    createMultipleSelectContainer: "Selected: ",
    createMultipleSelectMessage: "Roles not entered",
  },
  customerEmailFilterModel: {
    title: "Email",
  },
  customerPhoneNumberFilterModel: {
    title: "Phone number",
  },
};
