import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

type SNavigationMenuListProps = {
  isArabic: boolean;
};
const isArabicLang = setProps<SNavigationMenuListProps>(({ isArabic }) => isArabic)(css`
  margin-right: 10px;
`);
const isNotArabicLang = setProps<SNavigationMenuListProps>(({ isArabic }) => !isArabic)(css`
  margin-left: 10px;
`);

export const SNavigationMenuList = styled.div<SNavigationMenuListProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  gap: 12px;

  ${setFlexDFFDC};
  ${isNotArabicLang};
  ${isArabicLang};
`;
