import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const paymentIdFilterModel = new FilterModel({
  id: 13,
  type: "text",
  title: t("paymentIdFilterModel.title"),
  name: "PAYMENT_ID",
  options: {
    maxLength: 36,
  },
  langPath: "paymentIdFilterModel.title",
});
