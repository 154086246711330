import { resetScrollbar } from "theme/default/resetScrollbar";
import { styled } from "theme/default/styled";
import { setFlexDFFDCF1 } from "theme/setter/setFlexValues/setFlexDFFDCF1";

export const SRolesSelectFormList = styled.div`
  overflow: auto;

  ${setFlexDFFDCF1};
  ${resetScrollbar};
`;
