import { InvoiceInputState } from "service/shared/others/InvoiceInputService/InvoiceInput.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class InvoiceInputController {
  public readonly state: InvoiceInputState;

  public constructor(state: InvoiceInputState) {
    this.state = state;
  }

  public readonly onFocus = (): void => {
    if (this.state.isPhone && this.state.value.value === "") {
      this.state.value.next("+964");
    }
  };

  public readonly onChange = onChangeHelper((nextValue: string): void => {
    if (this.state?.isDescription && nextValue.length > 50) return;
    if (this.state?.isTitle && nextValue.length > 50) return;
    this.state.value.next(nextValue);
  });

  public readonly resetField = (): void => {
    this.state.value.next("");
    this.state.valid.next(false);
    this.state.showError.next(false);
  };
}
