import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createArticleFormService } from "service/shared/singletones/createArticleFormService/createArticleForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ArticleFormApplyButton } from "./ArticleFormApplyButton/ArticleFormApplyButton.component";
import { CreateArticleDescriptionInput } from "./CreateArticleDescriptionInput/CreateArticleDescriptionInput.component";
import { CreateArticleEmailInput } from "./CreateArticleEmailInput/CreateArticleEmailInput.component";
import { CreateArticleTypeInput } from "./CreateArticleTypeInput/CreateArticleTypeInput.component";
import { SCreateSupportArticleForm } from "./CreateSupportArticleFormStyled/SCreateSupportArticleForm.styled";
import { SCreateSupportArticleFormHeader } from "./CreateSupportArticleFormStyled/SCreateSupportArticleFormHeader.styled";
import { SCreateSupportArticleFormPopUp } from "./CreateSupportArticleFormStyled/SCreateSupportArticleFormPopUp.styled";

export const CreateSupportArticleForm = createComponent("CreateSupportArticleForm", () => {
  const open = useDefaultObservableBoolean(createArticleFormService.state.open);

  if (!open) {
    return null;
  }

  return (
    <SCreateSupportArticleFormPopUp closeCallBack={createArticleFormService.controller.onCloseCallBack}>
      <SCreateSupportArticleForm>
        <SCreateSupportArticleFormHeader>
          <CreateArticleTypeInput />
          <CreateArticleEmailInput />
        </SCreateSupportArticleFormHeader>
        <CreateArticleDescriptionInput />
        <ArticleFormApplyButton />
      </SCreateSupportArticleForm>
    </SCreateSupportArticleFormPopUp>
  );
});
