import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_rgba_0_0_0_05 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_05";
import { color_rgba_0_0_0_10 } from "theme/setter/opacityColorsValues/color_rgba_0_0_0_10";
import { setBackgroundColor_16e527 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_16e527";
import { setBackgroundColor_1839d5 } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_1839d5";
import { setBackgroundColor_e74c3c } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_e74c3c";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";

type SToastContainerProps = {
  isInfo: boolean;
  isError: boolean;
  isSuccess: boolean;
};

const isInfoSet = setProps<SToastContainerProps>(({ isInfo }) => isInfo)(css`
  ${setBackgroundColor_1839d5};
`);

const isErrorSet = setProps<SToastContainerProps>(({ isError }) => isError)(css`
  ${setBackgroundColor_e74c3c};
`);

const isSuccessSet = setProps<SToastContainerProps>(({ isSuccess }) => isSuccess)(css`
  ${setBackgroundColor_16e527};
`);

export const SToastContainer = styled.div<SToastContainerProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  position: relative;
  width: 300px;
  min-height: 64px;
  margin-bottom: 14px;
  padding: 8px;

  box-shadow: 0 1px 10px ${color_rgba_0_0_0_10}, 0 2px 15px ${color_rgba_0_0_0_05};

  ${setFlexDFAIC};
  ${setBorderRadius_4px};
  ${isInfoSet};
  ${isErrorSet};
  ${isSuccessSet};
`;
