import moment from "packages/moment";
import Datetime from "packages/react-datetime";
import { useTranslation } from "react-i18next";
import { Children } from "types/commonExtend/Children";
import { formatDateWithoutTimeZone } from "utils/business/formatDateWithoutTimeZone";
import { createComponent } from "utils/libExtend/createComponent";
import { useState } from "utils/libReplace/useState";

import { CustomDateTimeProps } from "./CustomDateTime.props";
import { SCustomDateTimeButton } from "./CustomDateTimeStyled/SCustomDateTimeButton.styled";
import { SCustomDateTimeContainer } from "./CustomDateTimeStyled/SCustomDateTimeContainer.styled";
import { SCustomDateTimeValue } from "./CustomDateTimeStyled/SCustomDateTimeValue.styled";

import "moment/locale/ru";

export const CustomDateTime = createComponent<CustomDateTimeProps>("CustomDateTime", (props) => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const { className = "", dateFormat, onChange, timeFormat, value } = props;

  const [previousValue, setPreviousValue] = useState<Date>(value);

  const [open, setOpen] = useState<boolean>(false);

  const locale = isArabic ? "en" : i18n.language;

  const onOpen = (): void => setOpen(true);

  const onClickApply = (): void => {
    setPreviousValue(value);
    setOpen(false);
  };

  const onClickClose = (): void => {
    onChange(moment(previousValue));
    setOpen(false);
  };

  return (
    <Datetime
      locale={locale}
      className={className}
      dateFormat={dateFormat}
      onChange={onChange}
      timeFormat={timeFormat}
      value={value}
      open={open}
      renderInput={(): Children => {
        return <SCustomDateTimeValue onClick={onOpen}>{formatDateWithoutTimeZone(value)}</SCustomDateTimeValue>;
      }}
      renderView={(viewMode: string, renderCalendar: Function): Children => {
        return (
          <div>
            {renderCalendar()}
            <SCustomDateTimeContainer>
              <SCustomDateTimeButton onClick={onClickApply}>{t("customDateSelect.applyButtonTitle")}</SCustomDateTimeButton>
              <SCustomDateTimeButton onClick={onClickClose}>{t("customDateSelect.closeButtonTitle")}</SCustomDateTimeButton>
            </SCustomDateTimeContainer>
          </div>
        );
      }}
    />
  );
});
