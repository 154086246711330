import { ControlCellModel } from "model/ControlCell.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectControlCellModelArray } from "observables/ControlCellModelArrayObservable";
import { BehaviorSubjectControlCellModel } from "observables/ControlCellModelObservable";
import { BehaviorSubjectNumber } from "observables/NumberObservable";
import { ControlsSelectService } from "service/shared/others/ControlsSelectService/ControlsSelect.service";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

type ConstructorParams = {
  showCountElements?: boolean;
  initialArabic: boolean;
};

export class FiltersNavigationState {
  public readonly currentPage: BehaviorSubjectNumber;

  public readonly isArabic: BehaviorSubjectBoolean;

  public readonly totalPages: BehaviorSubjectNumber;

  public readonly totalElements: BehaviorSubjectNumber;

  public readonly controlsSelectService: ControlsSelectService;

  public readonly controlCellList: BehaviorSubjectControlCellModelArray;

  public readonly doubleLeftArrowIconActive: BehaviorSubjectBoolean;

  public readonly leftArrowIconActive: BehaviorSubjectBoolean;

  public readonly rightArrowIconActive: BehaviorSubjectBoolean;

  public readonly doubleRightArrowIconActive: BehaviorSubjectBoolean;

  public readonly showCountElements: boolean;

  private readonly activeCell: BehaviorSubjectControlCellModel;

  private readonly initialArabic: boolean;

  public constructor(params?: ConstructorParams) {
    this.showCountElements = params?.showCountElements ?? true;
    this.currentPage = new BehaviorSubjectNumber(0);
    this.totalPages = new BehaviorSubjectNumber(1);
    this.totalElements = new BehaviorSubjectNumber(0);
    this.initialArabic = params?.initialArabic ?? false;
    this.isArabic = new BehaviorSubjectBoolean(this.initialArabic);
    this.controlsSelectService = new ControlsSelectService();
    this.controlCellList = setCombinedBehaviorSubject(this.getControlCellList, this.totalPages, this.currentPage);
    this.activeCell = setCombinedBehaviorSubject(this.setActiveCell, this.controlCellList, this.currentPage);
    this.doubleLeftArrowIconActive = setCombinedBehaviorSubject(this.setDoubleLeftArrowIconActive, this.currentPage, this.totalPages);
    this.leftArrowIconActive = setCombinedBehaviorSubject(this.setLeftArrowIconActive, this.currentPage);
    this.rightArrowIconActive = setCombinedBehaviorSubject(this.setRightArrowIconActive, this.currentPage, this.totalPages);
    this.doubleRightArrowIconActive = setCombinedBehaviorSubject(this.setDoubleRightArrowIconActive, this.currentPage, this.totalPages);
  }

  private readonly setActiveCell = (controlCellList: ControlCellModel[], currentPage: number): ControlCellModel => {
    return controlCellList[currentPage];
  };

  private readonly setDoubleLeftArrowIconActive = (currentPage: number): boolean => {
    return currentPage > 10;
  };

  private readonly setLeftArrowIconActive = (currentPage: number): boolean => {
    return currentPage > 0;
  };

  private readonly setRightArrowIconActive = (currentPage: number, totalPages: number): boolean => {
    return currentPage < totalPages - 1;
  };

  private readonly setDoubleRightArrowIconActive = (currentPage: number, totalPages: number): boolean => {
    return currentPage < totalPages - 10;
  };

  private readonly getControlCellList = (totalPages: number, currentPage: number): ControlCellModel[] => {
    const showCount = 5;

    const isShowTotalPages = totalPages > showCount ? showCount : totalPages;

    const getRange = (): {
      start: number;
      end: number;
    } => {
      if (currentPage > 1) {
        if (currentPage < totalPages - 2) {
          return {
            start: currentPage - 2,
            end: currentPage + 3,
          };
        }
        return {
          start: totalPages - isShowTotalPages,
          end: totalPages,
        };
      }
      return {
        start: 0,
        end: isShowTotalPages,
      };
    };

    const { start, end } = getRange();

    const array: number[] = [];

    for (let index = start; index < end; index++) {
      array.push(index);
    }

    return array.map((index) => {
      const active = index === currentPage;
      return new ControlCellModel(index, `${index + 1}`, active);
    });
  };
}
