import { OptionModel } from "model/Option.model";
import { MultipleCustomSelectState } from "service/shared/others/MultipleCustomSelectService/MultipleCustomSelect.state";

export class MultipleCustomSelectController {
  public readonly state: MultipleCustomSelectState;

  public constructor(state: MultipleCustomSelectState) {
    this.state = state;
  }

  public readonly onClickEmitter = (): void => {
    this.state.isDropDownOpened.next(false);
  };

  public readonly onChangeHandler = (value: OptionModel) => (): void => {
    this.state.onChange(value);
    this.state.isDropDownOpened.next(false);
  };

  public readonly onClickOpenButton = (): void => {
    this.state.isDropDownOpened.next(!this.state.isDropDownOpened.value);
  };

  public readonly onClickAll = (): void => {
    this.state.onClickAll();
    this.state.isDropDownOpened.next(false);
  };
}
