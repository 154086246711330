import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const customerPhoneNumberFilterModel = new FilterModel({
  id: 20,
  type: "text",
  title: t("customerPhoneNumberFilterModel.title"),
  name: "CUSTOMER_PHONE_NUMBER",
  options: {},
  langPath: "customerPhoneNumberFilterModel.title",
});
