import React from "react";
import { color_rgba_183_200_220_80, css, setBackgroundColor_ffffff, setBorderRadius_8px, setProps, styled } from "theme";

interface Props {
  isArabic: boolean;
}

const isArabicLang = setProps<Props>(({ isArabic }) => isArabic)(css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`);

export const SRewardDetailsInformationContainer = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  padding: 12px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
  ${isArabicLang};
`;
