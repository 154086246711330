import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { RewardsDetailsCellProps } from "detailsCell/RewardsDetailsCell/RewardsDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

import { normalizeDate } from "./normalizeDate";

export const RewardsDetailsCell = createComponent<RewardsDetailsCellProps>("RewardsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.rewardDetails, {
    rewardDate: normalizeDate(cellData.date),
  });

  return <DetailsCell to={to} />;
});
