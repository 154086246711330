export const routerPaths = {
  terminals: "/terminals",
  supportAdministration: "/support-administration",
  dynamic: "/dynamic",
  statistic: "/statistic",
  support: "/support",

  signIn: "/sign-in",
  forgotPassword: "/password/reset",
  resetPassword: "/password/forgot",
  activateInvite: "/invite",
  userInfoPage: "/user-info",
  usersInfoPage: "/users-info",
  usersDetailsPage: "/users-info/:userId",
  auditPage: "/audit",
  terminalsPage: "/terminals",

  invoices: "/invoices",
  payments: "/payments",
  refunds: "/refunds",
  transfers: "/transfers",
  rewards: "/rewards",
  h2h: "/h2h",

  invoiceDetails: "/invoices/:invoiceId",
  invoiceOrderDetails: "/invoices/:invoiceId/orders/:paymentId",

  paymentDetails: "/payments/:paymentId",
  paymentRefundDetails: "/payments/:paymentId/refund/:refundId",

  refundDetails: "/refunds/:refundId",
  transferDetails: "/transfers/:transferId",

  rewardDetails: "/rewards/:rewardDate",
};
