import { CompareMonthController } from "service/shared/others/CompareMonthService/CompareMonth.controller";
import { CompareMonthState } from "service/shared/others/CompareMonthService/CompareMonth.state";

export class CompareMonthService {
  public readonly state: CompareMonthState;

  public readonly controller: CompareMonthController;

  public constructor() {
    this.state = new CompareMonthState();
    this.controller = new CompareMonthController(this.state);
  }
}
