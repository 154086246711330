import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { TerminalModel } from "model/Terminal.model";
import { terminalsPageState } from "service/shared/singletones/terminalsPageService/terminalsPage.state";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

class Controller {
  public readonly state = terminalsPageState;

  public readonly onChangeFilter = onChangeHelper((text: string): void => {
    this.state.filterValue.next(text);
  });

  public readonly onMount = async (): Promise<void> => {
    const fetchedTerminalsList = await this.fetchTerminalList();
    const producedTerminalsList = this.produceTerminalList(fetchedTerminalsList);
    this.state.terminalsList.next(producedTerminalsList);
    this.state.loaded.next(true);
  };

  public readonly onClickTerminalsClearFilterButton = (event: MouseCustomEvent): void => {
    event.preventDefault();

    this.state.filterValue.next("");
    this.state.appliedFilterValue.next(this.state.filterValue.value);
  };

  public readonly onClickApplyButton = (event: MouseCustomEvent): void => {
    event.preventDefault();

    this.state.appliedFilterValue.next(this.state.filterValue.value);
  };

  private readonly fetchTerminalList = async (): Promise<any> => {
    const { data } = await client.post(apiPaths.terminalList);

    return data;
  };

  private readonly produceTerminalList = (data: any): TerminalModel[] => {
    return data.map((terminal: any) => {
      return new TerminalModel(terminal);
    });
  };
}

export const terminalsPageController = new Controller();
