import { HeaderCellModel } from "model/HeaderCell.model";
import { TableState } from "service/shared/others/TableService/Table.state";
import { DragDivType } from "types/libExtend/DragDivType";
import { MutableRefObject } from "types/libReplace/MutableRefObject";

export class TableController {
  public readonly state: TableState;

  public constructor(state: TableState) {
    this.state = state;
  }

  public readonly onInitHeader = (headerCell: HeaderCellModel, headerCellRef: MutableRefObject<HTMLDivElement | null>): void => {
    if (headerCell.width && headerCell.initialWidth) {
      return;
    }

    const clientWidth = headerCellRef.current?.clientWidth;

    if (clientWidth) {
      headerCell.width = clientWidth;
      headerCell.initialWidth = clientWidth;
      this.state.headerCellList.next([...this.state.headerCellList.value]);
    }
  };

  public readonly onDrag = (event: DragDivType, headerCell: HeaderCellModel): void => {
    const { clientX } = event;

    if (headerCell.lastWidth) {
      if (headerCell.initialClientX) {
        if (clientX && headerCell.width && headerCell.initialWidth && headerCell.lastWidth) {
          const nextWidth = headerCell.lastWidth + clientX - headerCell.initialClientX;
          if (headerCell.initialWidth < nextWidth) {
            headerCell.width = nextWidth;
            this.state.headerCellList.next([...this.state.headerCellList.value]);
          }
        }
      } else {
        headerCell.initialClientX = clientX;
      }
    } else if (headerCell.width) {
      headerCell.lastWidth = headerCell.width;
    }
  };

  public readonly onDragEnd = (event: DragDivType, headerCell: HeaderCellModel): void => {
    delete headerCell.initialClientX;
    if (headerCell.width) {
      headerCell.lastWidth = headerCell.width;
    }
  };

  public readonly onChangeArabic = (value: boolean): void => {
    this.state.isArabic.next(value);
  };
}
