import { OptionModel } from "model/Option.model";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";
import { useEffect } from "utils/libReplace/useEffect";

import { CreateUserFetchedSelectProps } from "./CreateUserFetchedSelect.props";
import { SCreateUserFetchedSelect } from "./CreateUserFetchedSelectStyled/SCreateUserFetchedSelect.styled";
import { SCreateUserFetchedSelectMessage } from "./CreateUserFetchedSelectStyled/SCreateUserFetchedSelectMessage.styled";
import { SCreateUserFetchedSelectSelect } from "./CreateUserFetchedSelectStyled/SCreateUserFetchedSelectSelect.styled";
import { SCreateUserFetchedSelectTitle } from "./CreateUserFetchedSelectStyled/SCreateUserFetchedSelectTitle.styled";

export const CreateUserFetchedSelect = createComponent<CreateUserFetchedSelectProps>("CreateUserFetchedSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;

  const optionList = useDefaultObservable<OptionModel[]>(service.state.optionList);

  useEffect(async () => {
    await service.controller.onMount();
  }, []);

  const value = useDefaultObservable<OptionModel>(service.state.value);
  const showError = useDefaultObservable<boolean>(service.state.showError);

  return (
    <SCreateUserFetchedSelect>
      <SCreateUserFetchedSelectTitle>{t(service.state.title)}</SCreateUserFetchedSelectTitle>
      <SCreateUserFetchedSelectSelect onChange={service.controller.onChange} value={value} optionList={optionList} />

      {showError && <SCreateUserFetchedSelectMessage>{t(service.state.message)}</SCreateUserFetchedSelectMessage>}
    </SCreateUserFetchedSelect>
  );
});
