import { userService } from "api/userService";
import { PaymentDetailsModel } from "model/PaymentDetails.model";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservablePaymentDetailsModelNull } from "observables/PaymentDetailsModelNullObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { RenderFunctionType } from "types/commonExtend/RenderFunctionType";
import { createComponent } from "utils/libExtend/createComponent";

import { CancelButton } from "./CancelButton/CancelButton.component";
import { ConfirmButton } from "./ConfirmButton/ConfirmButton.component";
import { PaymentActionModal } from "./PaymentActionModal/PaymentActionModal.component";
import { SPaymentDetailsPageContent } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContent.styled";
import { SPaymentDetailsPageContentAdditionalInfo } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContentAdditionalInfo.styled";
import { SPaymentDetailsPageContentAdditionalInfoHeader } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContentAdditionalInfoHeader.styled";
import { SPaymentDetailsPageContentButtons } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContentButtons.styled";
import { SPaymentDetailsPageContentColumn } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContentColumn.styled";
import { SPaymentDetailsPageContentRow } from "./PaymentDetailsPageContentStyled/SPaymentDetailsPageContentRow.styled";
import { PaymentDetailsTable } from "./PaymentDetailsTable/PaymentDetailsTable.component";
import { PaymentRow } from "./PaymentRow/PaymentRow.component";
import { PaymentSwitchedRow } from "./PaymentSwitchedRow/PaymentSwitchedRow.component";
import { RefundButton } from "./RefundButton/RefundButton.component";

export const PaymentDetailsPageContent = createComponent("PaymentDetailsPageContent", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const payment: PaymentDetailsModel = useDefaultObservablePaymentDetailsModelNull(paymentDetailsPageService.state.payment)!;
  const canCancelPayment = userService.canCancelPayment();
  const canConfirmPayment = userService.canConfirmPayment();
  const canRefundPayment = userService.canRefundPayment();
  const modalIsOpen: boolean = useDefaultObservableBoolean(paymentDetailsPageService.state.modalIsOpen)!;

  const additionalInfoArray = Object.keys(payment.additionalInfo);

  const cancelsIsNotEmpty = payment.cancels.length !== 0;
  const confirmsIsNotEmpty = payment.confirms.length !== 0;
  const refundsIsNotEmpty = payment.refunds.length !== 0;

  const renderAdditionalInfo: RenderFunctionType<string> = (key, index: number) => {
    const value = payment.additionalInfo[key];
    return <PaymentRow title={key} value={value} key={index} />;
  };

  return (
    <SPaymentDetailsPageContent isArabic={isArabic}>
      <SPaymentDetailsPageContentRow>
        <SPaymentDetailsPageContentColumn>
          <PaymentRow title={t("paymentDetailsPageContent.amount")} value={payment.amount} />
          <PaymentRow title={t("paymentDetailsPageContent.currency")} value={payment.currency} />
          <PaymentRow title={t("paymentDetailsPageContent.creationDate")} value={payment.creationDate} />
          <PaymentRow title={t("paymentDetailsPageContent.status")} value={`${t(`operationStatusFilterModel.${payment.status}`)} (${payment.status})`} />
          <PaymentRow title={t("paymentDetailsPageContent.refundsAmount")} value={payment.refundsAmount} />
          {payment.confirmedAmount && <PaymentRow title={t("paymentDetailsPageContent.confirmedAmount")} value={payment.confirmedAmount} />}
          {payment.authId && <PaymentRow title={t("paymentDetailsPageContent.authId")} value={payment.authId} />}
          {payment.resultCode && <PaymentRow title={t("paymentDetailsPageContent.resultCode")} value={payment.resultCode} />}
          {payment.rrn && <PaymentRow title={t("paymentDetailsPageContent.rrn")} value={payment.rrn} />}
        </SPaymentDetailsPageContentColumn>

        <SPaymentDetailsPageContentColumn>
          <PaymentRow title={t("paymentDetailsPageContent.requestId")} value={payment.requestId} />
          <PaymentRow title={t("paymentDetailsPageContent.terminalId")} value={payment.terminalId} />
          <PaymentRow title={t("paymentDetailsPageContent.fee")} value={payment.fee} />
          <PaymentRow title={t("paymentDetailsPageContent.externalRrn")} value={payment.externalRrn} />
          <PaymentRow title={t("paymentDetailsPageContent.type")} value={payment.type} />
          <PaymentRow title={t("paymentDetailsPageContent.maskedPan")} value={payment.maskedPan} />
          <PaymentRow title={t("paymentDetailsPageContent.paymentSystem")} value={payment.paymentSystem} />
          <PaymentRow title={t("paymentDetailsPageContent.cardHolder")} value={payment.cardHolder} />
          <PaymentRow title={t("paymentDetailsPageContent.trxId")} value={payment.requisitesTrxId} />
          {payment.canceled !== null ? <PaymentSwitchedRow title={t("paymentDetailsPageContent.canceled")} value={payment.canceled} /> : null}
        </SPaymentDetailsPageContentColumn>
      </SPaymentDetailsPageContentRow>

      <SPaymentDetailsPageContentButtons>
        {canCancelPayment && payment.hasCancelAction ? <CancelButton /> : null}
        {canConfirmPayment && payment.hasConfirmAction ? <ConfirmButton /> : null}
        {canRefundPayment && payment.hasRefundAction ? <RefundButton /> : null}
      </SPaymentDetailsPageContentButtons>

      <SPaymentDetailsPageContentAdditionalInfo>
        <SPaymentDetailsPageContentAdditionalInfoHeader>{t("paymentDetailsPageContent.additionalInfo")}</SPaymentDetailsPageContentAdditionalInfoHeader>
        {additionalInfoArray.map(renderAdditionalInfo)}
      </SPaymentDetailsPageContentAdditionalInfo>

      {cancelsIsNotEmpty || confirmsIsNotEmpty || refundsIsNotEmpty ? (
        <PaymentDetailsTable cancels={payment.cancels} confirms={payment.confirms} refunds={payment.refunds} />
      ) : null}

      {modalIsOpen ? <PaymentActionModal /> : null}
    </SPaymentDetailsPageContent>
  );
});
