import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SConfirmButton } from "./ConfirmButtonStyled/SConfirmButton.styled";

export const ConfirmButton = createComponent("ConfirmButton", () => {
  const { t } = useTranslation();
  return <SConfirmButton onClick={paymentDetailsPageService.controller.onClickConfirm}>{t("paymentDetailsPageContent.confirmButtonTitle")}</SConfirmButton>;
});
