import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const paymentDetailsPageCancel = (): HeaderCellModel[] => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.creationDate, t("paymentDetailsPageCancelConfig.creationDate"), "paymentDetailsPageCancelConfig.creationDate", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.status, t("paymentDetailsPageCancelConfig.status"), "paymentDetailsPageCancelConfig.status", {
      width: tableWidths.width150,
    }),
    getInitialHeaderCell(initialHeaderCellKey.amount, t("paymentDetailsPageCancelConfig.amount"), "paymentDetailsPageCancelConfig.amount", {
      width: tableWidths.width150,
    }),
  ];
};
