import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { usersDetailsPageService } from "service/shared/singletones/usersDetailsPageService/usersDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ResetPasswordApplyButton } from "./ResetPasswordApplyButton/ResetPasswordApplyButton.component";
import { ResetPasswordBackButton } from "./ResetPasswordBackButton/ResetPasswordBackButton.component";
import { SUserResetPasswordForm } from "./UserResetPasswordFormStyled/SUserResetPasswordForm.styled";
import { SUserResetPasswordFormButtons } from "./UserResetPasswordFormStyled/SUserResetPasswordFormButtons.styled";
import { SUserResetPasswordFormPopUp } from "./UserResetPasswordFormStyled/SUserResetPasswordFormPopUp.styled";
import { SUserResetPasswordFormTitle } from "./UserResetPasswordFormStyled/SUserResetPasswordFormTitle.styled";

export const UserResetPasswordForm = createComponent("UserResetPasswordForm", () => {
  const { t } = useTranslation();
  const open = useDefaultObservableBoolean(usersDetailsPageService.state.open);

  if (!open) {
    return null;
  }

  return (
    <SUserResetPasswordFormPopUp closeCallBack={usersDetailsPageService.controller.closeCallBack}>
      <SUserResetPasswordForm>
        <SUserResetPasswordFormTitle>{t("usersDetailsPage.userResetPasswordFormTitle")}</SUserResetPasswordFormTitle>
        <SUserResetPasswordFormButtons>
          <ResetPasswordBackButton />
          <ResetPasswordApplyButton />
        </SUserResetPasswordFormButtons>
      </SUserResetPasswordForm>
    </SUserResetPasswordFormPopUp>
  );
});
