import { styled } from "theme/default/styled";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexFDC } from "theme/setter/setFlexValues/setFlexFDC";

export const SResetPasswordForm = styled.div`
  margin: auto 0;

  ${setFlexDFAIC};
  ${setFlexFDC};
`;
