import { t } from "i18n";
import { FilterModel } from "model/Filter.model";

export const panFilterModel = new FilterModel({
  id: 14,
  type: "text",
  title: t("panFilterModel.title"),
  name: "PAN",
  options: {},
  langPath: "panFilterModel.title",
});
