import { emailRegExp, phoneRegExp } from "appConstants/regexp";
import { CreateUserSelectState } from "service/shared/others/CreateUserSelectService/CreateUserSelect.state";
import { onChangeHelper } from "utils/commonExtend/onChangeHelper";

export class CreateUserSelectController {
  public readonly state: CreateUserSelectState;

  public constructor(state: CreateUserSelectState) {
    this.state = state;
  }

  private readonly validate = (nextValue: string): boolean => {
    if (this.state.isPhoneNumber) return phoneRegExp.test(nextValue);
    if (this.state.isEmail) return emailRegExp.test(nextValue);
    return nextValue.trim() !== "";
  };

  public readonly onChange = onChangeHelper((nextValue: string): void => {
    this.state.value.next(nextValue);
    this.state.valid.next(this.validate(nextValue));
  });

  public readonly onFocus = (): void => {
    if (this.state.isPhoneNumber && this.state.value.value === "") {
      this.state.value.next("+964");
    }
  };
}
