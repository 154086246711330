import { H2HDetailsCellProps } from "detailsCell/H2HDetailsCell/H2HDetailsCell.props";
import { createComponent } from "utils/libExtend/createComponent";

import { SvgImage } from "../../components/SvgImage/SvgImage.component";
import { useState } from "../../utils/libReplace/useState";
import { SDetailsCell } from "./H2HDetailsCellStyled/SDetailsCell.styled";
import { InformationPopUp } from "./InformationPopUp/InformationPopUp.component";

export const H2HDetailsCell = createComponent<H2HDetailsCellProps>("H2HDetailsCell", (props) => {
  const { cellData } = props;

  const [hovered, setHovered] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onMouseOut = (): void => {
    setHovered(false);
  };

  const onMouseOver = (): void => {
    setHovered(true);
  };

  const name = hovered ? "DetailsIconActive" : "DetailsIcon";

  const onClick = (): void => {
    setIsOpen(true);
  };

  const closeCallBack = (): void => {
    setIsOpen(false);
  };

  return (
    <>
      <SDetailsCell onClick={onClick} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
        <SvgImage name={name} />
      </SDetailsCell>
      {isOpen && <InformationPopUp closeCallBack={closeCallBack} cellData={cellData} />}
    </>
  );
});
