import { CustomSelect } from "components";
import { color_a0b9d2, setBorderRadius_4px, styled } from "theme";

type SFilterSelectSelectProps = {
  isTitle: boolean;
};

export const SFilterSelectSelect = styled(CustomSelect)<SFilterSelectSelectProps>`
  width: 180px;
  background-color: transparent;
  appearance: none;

  border: 1px solid ${color_a0b9d2};

  ${setBorderRadius_4px};

  & > option {
    color: initial;
  }
`;
