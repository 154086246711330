import { resetScrollbar } from "theme/default/resetScrollbar";
import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_ffffff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_ffffff";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";

export const SUsersInfoPageLayoutContent = styled.div`
  overflow-y: scroll;
  margin-top: 24px;
  padding-top: 16px;
  padding-bottom: 20px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${resetScrollbar};
  ${setBorderRadius_8px};
  ${setBackgroundColor_ffffff};
`;
