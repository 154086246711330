import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { createComponent } from "utils/libExtend/createComponent";

import { FilterList } from "../FilterList/FilterList.component";
import { AddFilterButton } from "./AddFilterButton/AddFilterButton.component";
import { ApplyButton } from "./ApplyButton/ApplyButton.component";
import { DownloadButton } from "./DownloadButton/DownloadButton.component";
import { SFilterMenu } from "./FilterMenuStyled/SFilterMenu.styled";
import { SFilterMenuButtons } from "./FilterMenuStyled/SFilterMenuButtons.styled";
import { ResetButton } from "./ResetButton/ResetButton.component";

export const FilterMenu = createComponent("FilterMenu", () => {
  const { service } = useFiltersContext();
  const isArabic = useDefaultObservableBoolean(service.state.isArabic);

  return (
    <SFilterMenu isArabic={isArabic}>
      <FilterList />
      <AddFilterButton />
      <SFilterMenuButtons isArabic={isArabic}>
        <ApplyButton />
        <DownloadButton />
        {service.state.showCloseIcon && <ResetButton />}
      </SFilterMenuButtons>
    </SFilterMenu>
  );
});
