import React from "react";
import { css, setFlexDFFDC, setProps, styled } from "theme";

interface SFiltersProps {
  isArabic: boolean;
}

const isArabicLang = setProps<SFiltersProps>(({ isArabic }) => isArabic)(css`
  align-items: flex-end;
`);

export const SFilters = styled.div<SFiltersProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin: 12px 8px 24px 12px;

  ${setFlexDFFDC};
  ${isArabicLang};
`;
