import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { TransfersDetailsCellProps } from "detailsCell/TransfersDetailsCell/TransfersDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

export const TransfersDetailsCell = createComponent<TransfersDetailsCellProps>("TransfersDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.transferDetails, {
    transferId: cellData.transferId,
  });

  return <DetailsCell to={to} />;
});
