import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { UserModel } from "model/User.model";
import { authenticatedService } from "service/common/authenticatedService/authenticated.service";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { ProducedStates } from "service/shared/singletones/userInfoPageService/types/ProducedStates";
import { userInfoPageState } from "service/shared/singletones/userInfoPageService/userInfoPage.state";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";

class Controller {
  public readonly state = userInfoPageState;

  public readonly mount = async (): Promise<void> => {
    const { data } = await client.get(apiPaths.userInfo);
    const user = this.getUserModel(data);
    this.state.user.next(user);
    this.state.loaded.next(true);
  };

  public readonly unMount = (): void => {
    this.state.loaded.next(false);
  };

  public readonly produceStates: () => ProducedStates = () => {
    const newPasswordDigitsState = this.state.newPassword.state.digitsState.value;
    const currentPasswordDigitsState = this.state.currentPassword.state.digitsState.value;
    const repeatedNewPasswordDigitsState = this.state.repeatedNewPassword.state.digitsState.value;
    const newPasswordLengthState = this.state.newPassword.state.lengthState.value;
    const currentPasswordLengthState = this.state.currentPassword.state.lengthState.value;
    const repeatedNewPasswordLengthState = this.state.repeatedNewPassword.state.lengthState.value;
    const newPasswordLowercaseLettersState = this.state.newPassword.state.lowercaseLettersState.value;
    const currentPasswordLowercaseLettersState = this.state.currentPassword.state.lowercaseLettersState.value;
    const repeatedNewPasswordLowercaseLettersState = this.state.repeatedNewPassword.state.lowercaseLettersState.value;
    const newPasswordRussianLetterState = this.state.newPassword.state.russianLetterState.value;
    const currentPasswordRussianLetterState = this.state.currentPassword.state.russianLetterState.value;
    const repeatedNewPasswordRussianLetterState = this.state.repeatedNewPassword.state.russianLetterState.value;
    const newPasswordSpecialSymbolsState = this.state.newPassword.state.specialSymbolsState.value;
    const currentPasswordSpecialSymbolsState = this.state.currentPassword.state.specialSymbolsState.value;
    const repeatedNewPasswordSpecialSymbolsState = this.state.repeatedNewPassword.state.specialSymbolsState.value;
    const newPasswordUppercaseLettersState = this.state.newPassword.state.uppercaseLettersState.value;
    const currentPasswordUppercaseLettersState = this.state.currentPassword.state.uppercaseLettersState.value;
    const repeatedNewPasswordUppercaseLettersState = this.state.repeatedNewPassword.state.uppercaseLettersState.value;

    return {
      formDigitsState: newPasswordDigitsState || currentPasswordDigitsState || repeatedNewPasswordDigitsState,
      formLengthState: newPasswordLengthState || currentPasswordLengthState || repeatedNewPasswordLengthState,
      formLowercaseLettersState: newPasswordLowercaseLettersState || currentPasswordLowercaseLettersState || repeatedNewPasswordLowercaseLettersState,
      formRussianLetterState: newPasswordRussianLetterState || currentPasswordRussianLetterState || repeatedNewPasswordRussianLetterState,
      formSpecialSymbolsState: newPasswordSpecialSymbolsState || currentPasswordSpecialSymbolsState || repeatedNewPasswordSpecialSymbolsState,
      formUppercaseLettersState: newPasswordUppercaseLettersState || currentPasswordUppercaseLettersState || repeatedNewPasswordUppercaseLettersState,
    };
  };

  public readonly produceShowError = async (): Promise<boolean> => {
    let flag = false;

    const currentPassword = this.state.currentPassword.state.value.value;
    const newPassword = this.state.newPassword.state.value.value;
    const repeatedNewPassword = this.state.repeatedNewPassword.state.value.value;

    const isCurrentPasswordEqualNewPasswordError = currentPassword === newPassword;
    const isNewPasswordNotEqualRepeatedNewPasswordError = newPassword !== repeatedNewPassword;

    const states = await this.produceStates();

    if (states.formDigitsState) flag = true;
    if (states.formLengthState) flag = true;
    if (states.formLowercaseLettersState) flag = true;
    if (states.formRussianLetterState) flag = true;
    if (states.formSpecialSymbolsState) flag = true;
    if (states.formUppercaseLettersState) flag = true;
    if (isCurrentPasswordEqualNewPasswordError) flag = true;
    if (isNewPasswordNotEqualRepeatedNewPasswordError) flag = true;

    this.state.showCurrentPasswordEqualNewPasswordError.next(isCurrentPasswordEqualNewPasswordError);
    this.state.showNewPasswordNotEqualRepeatedNewPasswordError.next(isNewPasswordNotEqualRepeatedNewPasswordError);

    this.state.showErrors.next(true);

    return flag;
  };

  public readonly changePasswordHandler = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    const currentPassword = this.state.currentPassword.state.value.value;
    const newPassword = this.state.newPassword.state.value.value;

    const hasError = await this.produceShowError();

    if (hasError) {
      this.state.currentPassword.state.showError.next(true);
      this.state.newPassword.state.showError.next(true);
      this.state.repeatedNewPassword.state.showError.next(true);
      return;
    }

    try {
      this.state.loaded.next(false);

      await client.post(apiPaths.userPasswordChange, {
        currentPassword,
        newPassword,
      });

      toastContainerService.controller.createSuccessToast(t("userInfoPageService.changedPasswordTitle"));
      await authenticatedService.controller.logout();
    } catch (error: any) {
      console.log("error", error);
    } finally {
      this.state.loaded.next(true);
      this.resetForm();
    }
  };

  private readonly resetForm = (): void => {
    this.state.currentPassword.controller.resetInput();
    this.state.newPassword.controller.resetInput();
    this.state.repeatedNewPassword.controller.resetInput();
  };

  private readonly getUserModel = (data: any): UserModel => {
    return new UserModel(data);
  };
}

export const userInfoPageController = new Controller();
