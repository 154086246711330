import { t } from "i18n";

export const getSource = (dateArray: string[], successQuantityArray: number[], failQuantityArray: number[]): any[] => {
  const source: any = [["product", t("statisticPage.successTitle"), t("statisticPage.failTitle")]];

  for (let i = 0; i < dateArray.length; i++) {
    const columnArray = [];

    columnArray.push(dateArray[i]);
    columnArray.push(successQuantityArray[i]);
    columnArray.push(failQuantityArray[i]);

    source.push(columnArray);
  }

  return source;
};
