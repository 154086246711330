export const userRolesConfig = {
  SYSTEM: "SYSTEM",
  ADMINISTRATOR: "ADMINISTRATOR",
  R_H2H: "R_H2H",
  R_OPERATION: "R_OPERATION",
  R_INVOICE: "R_INVOICE",
  R_REWARD: "R_REWARD",
  R_REFUND: "R_REFUND",
  R_TRANSFER: "R_TRANSFER",
  X_CANCEL: "X_CANCEL",
  X_REFUND: "X_REFUND",
  X_CONFIRM: "X_CONFIRM",
  W_INVOICE: "W_INVOICE",
  X_INVOICE: "X_INVOICE",
  X_TRANSFER: "X_TRANSFER",
  R_STATISTICS: "R_STATISTICS",
};
