import { routerPaths } from "appConstants/routerPaths";
import { DetailsCell } from "detailsCell/DetailsCell/DetailsCell.component";
import { PaymentsDetailsCellProps } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.props";
import { replacePath } from "utils/commonExtend/replacePath";
import { createComponent } from "utils/libExtend/createComponent";

export const PaymentsDetailsCell = createComponent<PaymentsDetailsCellProps>("PaymentsDetailsCell", (props) => {
  const { cellData } = props;

  const to = replacePath(routerPaths.paymentDetails, {
    paymentId: cellData.paymentId,
  });

  return <DetailsCell to={to} />;
});
