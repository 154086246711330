import { signInFormController } from "service/shared/singletones/signInFormService/signInForm.controller";
import { signInFormState } from "service/shared/singletones/signInFormService/signInForm.state";

class Service {
  public readonly state = signInFormState;

  public readonly controller = signInFormController;
}

export const signInFormService = new Service();
