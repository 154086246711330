import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { useTranslation } from "react-i18next";
import { paymentDetailsTableService } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SPaymentCancelTable } from "./PaymentCancelTableContentStyled/SPaymentCancelTable.styled";
import { SPaymentCancelTableButtons } from "./PaymentCancelTableContentStyled/SPaymentCancelTableButtons.styled";
import { SPaymentCancelTableTable } from "./PaymentCancelTableContentStyled/SPaymentCancelTableTable.styled";
import { PaymentTableSwitch } from "./PaymentTableSwitch/PaymentTableSwitch.component";

export const PaymentCancelTableContent = createComponent("PaymentCancelTableContent", () => {
  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";
  const tableService = useDefaultObservableTableService(paymentDetailsTableService.state.tableService)!;
  const cancelsIsAvailable = useDefaultObservableBoolean(paymentDetailsTableService.state.cancelsIsAvailable);
  const confirmsIsAvailable = useDefaultObservableBoolean(paymentDetailsTableService.state.confirmsIsAvailable);
  const refundsIsAvailable = useDefaultObservableBoolean(paymentDetailsTableService.state.refundsIsAvailable);

  return (
    <SPaymentCancelTable isArabic={isArabic}>
      <SPaymentCancelTableButtons isArabic={isArabic}>
        {cancelsIsAvailable ? <PaymentTableSwitch title={t("paymentDetailsPage.cancelSwitchTitle")} dataType="cancel" /> : null}
        {confirmsIsAvailable ? <PaymentTableSwitch title={t("paymentDetailsPage.confirmSwitchTitle")} dataType="confirm" /> : null}
        {refundsIsAvailable ? <PaymentTableSwitch title={t("paymentDetailsPage.refundSwitchTitle")} dataType="refund" /> : null}
      </SPaymentCancelTableButtons>
      <SPaymentCancelTableTable isArabic={isArabic}>
        <Table key={Math.random()} service={tableService} />
      </SPaymentCancelTableTable>
    </SPaymentCancelTable>
  );
});
