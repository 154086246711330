import { t } from "i18n";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectUserModelNull } from "observables/UserModelNullObservable";
import { UsersChangePasswordInputService } from "service/shared/others/UsersChangePasswordInputService/UsersChangePasswordInput.service";
import { UsersChangeRowGroupService } from "service/shared/others/UsersChangeRowGroupService/UsersChangeRowGroup.service";
import { UsersChangeRowRolesService } from "service/shared/others/UsersChangeRowRolesService/usersChangeRowRoles.service";
import { UsersChangeRowService } from "service/shared/others/UsersChangeRowService/UsersChangeRow.service";

class State {
  public readonly open: BehaviorSubjectBoolean;

  public userId!: string;

  public readonly user: BehaviorSubjectUserModelNull;

  public readonly loaded: BehaviorSubjectBoolean;

  public readonly showUserForm: BehaviorSubjectBoolean;

  public nameService!: UsersChangeRowService;

  public merchantGroupNameService!: UsersChangeRowGroupService;

  public rolesService!: UsersChangeRowRolesService;

  public emailService!: UsersChangeRowService;

  public phoneNumberService!: UsersChangeRowService;

  public readonly currentPasswordService: UsersChangePasswordInputService;

  public readonly newPasswordService: UsersChangePasswordInputService;

  public constructor() {
    this.open = new BehaviorSubjectBoolean(false);
    this.user = new BehaviorSubjectUserModelNull(null);
    this.loaded = new BehaviorSubjectBoolean(false);
    this.showUserForm = new BehaviorSubjectBoolean(false);
    this.currentPasswordService = new UsersChangePasswordInputService(t("usersDetailsPageService.currentPasswordTitle"));
    this.newPasswordService = new UsersChangePasswordInputService(t("usersDetailsPageService.newPasswordTitle"));
  }
}

export const usersDetailsPageState = new State();
