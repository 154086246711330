import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_0083f5 } from "theme/setter/setColorsValues/setColor_0083f5";
import { setFontSize_13px } from "theme/setter/setFontSizeValues/setFontSize_13px";
import { setFontWeight600 } from "theme/setter/setFontWeightValues/setFontWeight600";

type SResetButtonProps = {
  filterListIsEmpty: boolean;
};

const filterListIsEmptySet = setProps<SResetButtonProps>(({ filterListIsEmpty }) => filterListIsEmpty)(css`
  opacity: 0.45;
`);

export const SResetButton = styled.button<SResetButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${setFontSize_13px};
  ${setFontWeight600};
  ${setColor_0083f5};
  ${filterListIsEmptySet};
`;
