import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { rewardsPageService } from "service/shared/singletones/rewardsPageService/rewardsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { RewardsHeader } from "./RewardsHeader/RewardsHeader.component";
import { SRewardsPage } from "./RewardsPageStyled/SRewardsPage.styled";
import { RewardsTable } from "./RewardsTable/RewardsTable.component";

export const RewardsPage = createComponent("RewardsPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    rewardsPageService.controller.mounted();

    return (): void => {
      rewardsPageService.controller.unMounted();
    };
  }, []);

  return (
    <SRewardsPage isArabic={i18n.language === "ar"}>
      <RewardsHeader />
      <RewardsTable />
      <TableNavigation service={rewardsPageService.state.filterNavigationService} />
    </SRewardsPage>
  );
});
