import React from "react";
import { styled } from "theme/default/styled";
import { setBackgroundColor_rgba_0_0_0_05 } from "theme/setter/setBackgroundOpacityColorsValues/setBackgroundColor_rgba_0_0_0_05";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";
import { setColor_rgba_0_0_0_95 } from "theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_95";

export const SHeaderMenuContainer = styled.button.attrs((props) => ({
  type: "submit",
})) <React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 153px;
  height: 36px;

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setBorderRadius_4px};

  &:hover {
    ${setColor_rgba_0_0_0_95};
    ${setBackgroundColor_rgba_0_0_0_05};
  }
`;
