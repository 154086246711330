import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { HeaderCellModel } from "model/HeaderCell.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectCurrentDataType } from "observables/CurrentDataTypeObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectPaymentDataArray } from "observables/PaymentDataArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { CurrentDataType } from "types/business/CurrentDataType";
import { PaymentCancel } from "types/business/PaymentCancel";
import { PaymentConfirm } from "types/business/PaymentConfirm";
import { PaymentRefund } from "types/business/PaymentRefund";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class State {
  public currentDataType: BehaviorSubjectCurrentDataType;

  public cancelsIsAvailable!: BehaviorSubjectBoolean;

  public confirmsIsAvailable!: BehaviorSubjectBoolean;

  public refundsIsAvailable!: BehaviorSubjectBoolean;

  public readonly loaded: BehaviorSubjectBoolean;

  public tableService!: BehaviorSubjectTableService;

  public paymentData!: BehaviorSubjectPaymentDataArray;

  public cancels!: PaymentCancel[];

  public confirms!: PaymentConfirm[];

  public refunds!: PaymentRefund[];

  public readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  public constructor() {
    this.currentDataType = new BehaviorSubjectCurrentDataType("none");
    this.loaded = new BehaviorSubjectBoolean(false);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.setInitialHeaderCellList, this.currentDataType);
  }

  private readonly setInitialHeaderCellList = (currentDataType: CurrentDataType): HeaderCellModel[] => {
    if (currentDataType === "cancel") {
      return tableHeaderInitialCell.paymentDetailsPageCancel();
    }
    if (currentDataType === "confirm") {
      return tableHeaderInitialCell.paymentDetailsPageConfirm();
    }
    if (currentDataType === "refund") {
      return tableHeaderInitialCell.paymentDetailsPageRefund();
    }
    return [];
  };
}

export const paymentDetailsTableState = new State();
