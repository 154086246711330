import { routerPaths } from "appConstants/routerPaths";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SSignInForgotPasswordButton } from "./SignInForgotPasswordButtonStyled/SSignInForgotPasswordButton.styled";

export const SignInForgotPasswordButton = createComponent("SignInForgotPasswordButton", () => {
  const { t } = useTranslation();
  return (
    <SSignInForgotPasswordButton data-test-id="forgot-password" to={routerPaths.forgotPassword}>
      {t("signInPage.forgotPasswordButton")}
    </SSignInForgotPasswordButton>
  );
});
