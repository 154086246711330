import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { InvoiceDetailsModel } from "model/InvoiceDetails.model";
import { invoiceDetailsPageState } from "service/shared/singletones/invoiceDetailsPageService/invoiceDetailsPage.state";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { replacePath } from "utils/commonExtend/replacePath";

class Controller {
  public readonly state = invoiceDetailsPageState;

  public readonly getInvoice = async (): Promise<void> => {
    this.state.loaded.next(false);
    const data: any[] = await this.fetchInvoice();
    const produceData = this.produceInvoice(data);
    if (produceData.status === "SENDING") {
      setTimeout(() => {
        this.getInvoice();
      }, this.state.refreshTime);
    } else {
      this.state.invoice.next(produceData);
      this.state.loaded.next(true);
    }
  };

  public readonly onClickCopyButton = async (): Promise<void> => {
    toastContainerService.controller.createInfoToast(t("invoiceDetailsPageService.linkCopied"));
  };

  public readonly onClickDeleteButton = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);
      const { data } = await client.post(
        replacePath(apiPaths.invoiceCancel, {
          invoiceId: this.state.invoiceId,
        }),
        {}
      );
      const invoiceData = this.produceInvoice(data);
      this.state.invoice.next(invoiceData);
      toastContainerService.controller.createSuccessToast(t("invoiceDetailsPageService.paymentRemove"));
      this.state.loaded.next(true);
    } catch (error: any) {
      const { message } = error.response.data;
      toastContainerService.controller.createErrorToast(message);
      this.state.loaded.next(true);
    }
  };

  public readonly onClickSendEmailButton = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);

      const { data } = await client.post(
        replacePath(apiPaths.invoiceMail, {
          invoiceId: this.state.invoiceId,
        }),
        {}
      );

      const produceData = this.produceInvoice(data);

      if (produceData.status === "SENDING") {
        setTimeout(() => {
          this.getInvoice();
        }, this.state.refreshTime);
      } else {
        this.state.invoice.next(produceData);

        toastContainerService.controller.createSuccessToast(t("invoiceDetailsPageService.messageSendEmailTitle"));
        this.state.loaded.next(true);
      }
    } catch (error: any) {
      const { message } = error.response.data;
      toastContainerService.controller.createErrorToast(message);
      this.state.loaded.next(true);
    }
  };

  public readonly onClickSendSmsButton = async (): Promise<void> => {
    try {
      this.state.loaded.next(false);

      const { data } = await client.post(
        replacePath(apiPaths.invoiceSms, {
          invoiceId: this.state.invoiceId,
        }),
        {}
      );

      const produceData = this.produceInvoice(data);

      if (produceData.status === "SENDING") {
        setTimeout(() => {
          this.getInvoice();
        }, this.state.refreshTime);
      } else {
        this.state.invoice.next(produceData);

        toastContainerService.controller.createSuccessToast(t("invoiceDetailsPageService.messageSendSmsTitle"));
        this.state.loaded.next(true);
      }
    } catch (error: any) {
      const { message } = error.response.data;
      toastContainerService.controller.createErrorToast(message);
      this.state.loaded.next(true);
    }
  };

  public readonly setInvoiceId = (invoiceId: string): void => {
    this.state.invoiceId = invoiceId;
  };

  public readonly unMounted = (): void => {
    this.state.invoice.next(null);
    this.state.loaded.next(false);
  };

  private readonly produceInvoice = (dataItem: any): InvoiceDetailsModel => {
    return new InvoiceDetailsModel(dataItem);
  };

  private readonly fetchInvoice = async (): Promise<any> => {
    const { data } = await client.get(
      replacePath(apiPaths.invoiceInfoId, {
        invoiceId: this.state.invoiceId,
      })
    );
    return data;
  };
}

export const invoiceDetailsPageController = new Controller();
