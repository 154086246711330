import React from "react";
import { setFlexDFAIC, setRowReverse, setTextRightArabic, styled } from "theme";

type Props = {
  isArabic: boolean;
};

export const SUsersChangeRowRolesInput = styled.div<Props & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-bottom: 10px;

  ${setFlexDFAIC};
  ${setTextRightArabic()};
  ${setRowReverse()};
`;
