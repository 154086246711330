import { OptionModel } from "model/Option.model";
import { InvoiceSelectController } from "service/shared/others/InvoiceSelectService/InvoiceSelect.controller";
import { InvoiceSelectState } from "service/shared/others/InvoiceSelectService/InvoiceSelect.state";

export class InvoiceSelectService {
  public readonly state: InvoiceSelectState;

  public readonly controller: InvoiceSelectController;

  public constructor(
    id: number,
    title: string,
    errorMessage: string,
    name: string,
    langPath: string,
    langPathError: string,
    options: OptionModel[],
    needUpdate?: boolean
  ) {
    this.state = new InvoiceSelectState(id, title, errorMessage, name, langPath, langPathError, options, needUpdate);
    this.controller = new InvoiceSelectController(this.state);
  }
}
