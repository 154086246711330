/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const Warning = createIcon("Warning", (props) => {
  const { width = 24, height = 24, color = "#ffffff" } = props;

  return (
    <svg height={height} viewBox="0 0 24 24" width={width} fill={color}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </svg>
  );
});
