import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { refundsPageService } from "service/shared/singletones/refundsPageService/refundsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRefundsTable } from "./RefundsTableStyled/SRefundsTable.styled";
import { SRefundsTableContainer } from "./RefundsTableStyled/SRefundsTableContainer.styled";

export const RefundsTable = createComponent("RefundsTable", () => {
  const loaded = useDefaultObservableBoolean(refundsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(refundsPageService.tableService);

  return (
    <SRefundsTable>
      <SRefundsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRefundsTableContainer>
    </SRefundsTable>
  );
});
