import { useRolesFormContext } from "context/RolesForm.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SRolesFormApplyButton } from "./RolesFormApplyButtonStyled/SRolesFormApplyButton.styled";

export const RolesFormApplyButton = createComponent("RolesFormApplyButton", () => {
  const { t } = useTranslation();
  const { service } = useRolesFormContext();

  return <SRolesFormApplyButton onClick={service.controller.onClickApplyButton}>{t("usersDetailsPage.rolesFormApplyButton")}</SRolesFormApplyButton>;
});
