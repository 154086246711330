import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setFlexDFAICJCSB } from "theme/setter/setFlexValues/setFlexDFAICJCSB";

type SSwitchedRowProps = {
  isMedium: boolean;
  isBig: boolean;
};

const isMediumSet = setProps<SSwitchedRowProps>(({ isMedium }) => isMedium)(css`
  width: 440px;
`);

const isBigSet = setProps<SSwitchedRowProps>(({ isBig }) => isBig)(css`
  width: 500px;
`);

export const SSwitchedRow = styled.div<SSwitchedRowProps & React.HTMLAttributes<HTMLDivElement>>`
  margin-bottom: 10px;

  ${setFlexDFAICJCSB};
  ${isMediumSet};
  ${isBigSet};
`;
