import { useTranslation } from "react-i18next";
import { createUserRolesSelectFormService } from "service/shared/singletones/createUserRolesSelectFormService/createUserRolesSelectForm.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRolesFormApplyButton } from "./RolesFormApplyButtonStyled/SRolesFormApplyButton.styled";

export const RolesFormApplyButton = createComponent("RolesFormApplyButton", () => {
  const { t } = useTranslation();
  return (
    <SRolesFormApplyButton onClick={createUserRolesSelectFormService.controller.onClickApplyButton}>
      {t("usersDetailsPage.rolesFormApplyButton")}
    </SRolesFormApplyButton>
  );
});
