import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectRefundDetailsModelNull } from "observables/RefundDetailsModelNullObservable";

class State {
  public readonly loaded: BehaviorSubjectBoolean;

  public readonly refund: BehaviorSubjectRefundDetailsModelNull;

  public refundId!: string;

  public constructor() {
    this.loaded = new BehaviorSubjectBoolean(false);
    this.refund = new BehaviorSubjectRefundDetailsModelNull(null);
  }
}

export const paymentRefundDetailsPageState = new State();
