import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { t } from "i18n";
import { forgotPasswordFormState } from "service/shared/singletones/forgotPasswordFormService/forgotPasswordForm.state";
import { toastContainerService } from "service/shared/singletones/toastContainerService/toastContainer.service";
import { ResetForgotPasswordDto } from "types/business/ResetForgotPasswordDto";
import { MouseCustomEvent } from "types/libExtend/MouseCustomEvent";

class Controller {
  public readonly state = forgotPasswordFormState;

  public readonly sendForm = async (event: MouseCustomEvent): Promise<void> => {
    event.preventDefault();

    this.state.isLoaded.next(true);

    const emailValue = this.state.emailService.state.value.value;
    const emailValid = this.state.emailService.state.valid.value;

    if (emailValid) {
      const resetPasswordDto: ResetForgotPasswordDto = new ResetForgotPasswordDto(emailValue);

      try {
        await client.post(apiPaths.createUser, resetPasswordDto);

        toastContainerService.controller.createSuccessToast(t("forgotPasswordFormService.resetPasswordMessage"));
      } catch (e) {
        console.log("e", e);
        this.clearForm();
      }
    } else {
      this.state.emailService.controller.setShowError(true);
    }

    this.state.isLoaded.next(false);
  };

  private readonly clearForm = (): void => {
    this.state.emailService.controller.clearForm();
  };
}

export const forgotPasswordFormController = new Controller();
