import { BehaviorSubjectConfigModelArray } from "observables/ConfigModelArrayObservable";
import { initialConfigList } from "service/shared/singletones/groupTableBodyService/initialConfigList";

class State {
  public configList: BehaviorSubjectConfigModelArray;

  public constructor() {
    this.configList = new BehaviorSubjectConfigModelArray(initialConfigList);
  }
}

export const groupTableBodyState = new State();
