import { paymentDetailsTableController } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.controller";
import { paymentDetailsTableState } from "service/shared/singletones/paymentDetailsTableService/paymentDetailsTable.state";

class Service {
  public readonly state = paymentDetailsTableState;

  public readonly controller = paymentDetailsTableController;
}

export const paymentDetailsTableService = new Service();
