import { navigationMenuController } from "service/shared/singletones/navigationMenuService/navigationMenu.controller";
import { navigationMenuState } from "service/shared/singletones/navigationMenuService/navigationMenu.state";

class Service {
  public readonly state = navigationMenuState;

  public readonly controller = navigationMenuController;
}

export const navigationMenuService = new Service();
