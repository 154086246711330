import { styled } from "../../../../../../../../theme/default/styled";
import { setFontSize_14px } from "../../../../../../../../theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight500 } from "../../../../../../../../theme/setter/setFontWeightValues/setFontWeight500";
import { setColor_rgba_0_0_0_95 } from "../../../../../../../../theme/setter/setOpacityColorsValues/setColor_rgba_0_0_0_95";

export const SBankSelectTitle = styled.span`
  ${setFontSize_14px};
  ${setFontWeight500};
  ${setColor_rgba_0_0_0_95};
  margin-bottom: 8px;
`;
