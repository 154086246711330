import { SvgNames } from "types/business/SvgNames";

export class MenuIconModel {
  public readonly id: number;

  public readonly name: SvgNames;

  public readonly title: string;

  public readonly link: string;

  public readonly langPath: string;

  public constructor(id: number, name: SvgNames, title: string, link: string, langPath: string) {
    this.id = id;
    this.name = name;
    this.title = title;
    this.link = link;
    this.langPath = langPath;
  }
}
