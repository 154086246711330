import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useFiltersContext } from "context/Filter.context";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { FetchedFilterSelectProps } from "./FetchedFilterSelect.props";
import { SFetchedFilterSelect } from "./FetchedFilterSelectStyled/SFetchedFilterSelect.styled";
import { SFetchedFilterSelectClear } from "./FetchedFilterSelectStyled/SFetchedFilterSelectClear.styled";
import { SFetchedFilterSelectContainer } from "./FetchedFilterSelectStyled/SFetchedFilterSelectContainer.styled";
import { SFetchedFilterSelectDelete } from "./FetchedFilterSelectStyled/SFetchedFilterSelectDelete.styled";
import { SFetchedFilterSelectSelect } from "./FetchedFilterSelectStyled/SFetchedFilterSelectSelect.styled";
import { SFetchedFilterSelectSelectContainer } from "./FetchedFilterSelectStyled/SFetchedFilterSelectSelectContainer.styled";
import { SFetchedFilterSelectTitle } from "./FetchedFilterSelectStyled/SFetchedFilterSelectTitle.styled";

export const FetchedFilterSelect = createComponent<FetchedFilterSelectProps>("FetchedFilterSelect", (props) => {
  const { t } = useTranslation();
  const { service } = props;
  const { service: filterService } = useFiltersContext();
  const isArabic = useDefaultObservableBoolean(filterService.state.isArabic);

  useEffect(async () => {
    await service.onMount();
  }, []);

  const optionList = useDefaultObservableOptionModelArray(service.optionList);
  const value = useDefaultObservableOptionModel(service.value);

  return (
    <SFetchedFilterSelect>
      <SFetchedFilterSelectTitle isArabic={isArabic}>{t(service.filterModel.langPath)}</SFetchedFilterSelectTitle>

      <SFetchedFilterSelectContainer isArabic={isArabic}>
        <SFetchedFilterSelectSelectContainer>
          <SFetchedFilterSelectSelect
            isTitle={value.isTitle}
            ref={service.inputRef}
            selectTitleValue={service.filterModel.selectTitle}
            selectTitle={service.filterModel.selectTitle}
            optionList={optionList}
            value={value}
            onChange={service.onChange}
          />

          <SFetchedFilterSelectClear isArabic={isArabic} onClick={service.onClickClear}>
            <SvgImage width={20} height={20} name="Close" />
          </SFetchedFilterSelectClear>
        </SFetchedFilterSelectSelectContainer>

        {filterService.state.showCloseIcon && (
          <SFetchedFilterSelectDelete isArabic={isArabic} onClick={service.onClickTrash}>
            <SvgImage width={20} height={20} name="DeleteFilter" />
          </SFetchedFilterSelectDelete>
        )}
      </SFetchedFilterSelectContainer>
    </SFetchedFilterSelect>
  );
});
