/* eslint-disable max-len */

import { colorsValues } from "theme/values/colorsValues";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const DetailsIconActive = createIcon("DetailsIconActive", (props) => {
  const { width = 20, height = 20, color = props.isFinOn ? colorsValuesFin.main.icon_fill : colorsValues.main.icon_fill } = props;

  return (
    <svg width={width} height={height} viewBox="0 -2 18 18" fill="none">
      <g>
        <path
          stroke={color}
          d="M3.51792 9.73114C3.62392 9.98634 3.82676 10.1891 4.08201 10.2951C4.20759 10.3486 4.34245 10.377 4.47895 10.3787L9.70192 10.3787L9.70192 8.28988L6.99642 8.28988L12.3579 2.92867C12.3579 2.92867 13.1266 2.15972 12.4469 1.36804C12.3498 1.27015 12.2343 1.19245 12.107 1.13943C11.9797 1.08641 11.8432 1.05911 11.7053 1.05911C11.5674 1.05911 11.4308 1.08641 11.3035 1.13943C11.1763 1.19245 11.0607 1.27015 10.9636 1.36804L5.52354 6.81729L5.52354 4.11231L3.43435 4.11231L3.43435 9.33427C3.436 9.47075 3.4644 9.60558 3.51792 9.73114Z"
        />
        <path
          stroke={color}
          d="M6.80177 1.05908L2.19282 1.05908C1.84357 1.05908 1.50863 1.19782 1.26167 1.44478C1.01471 1.69173 0.875977 2.02668 0.875977 2.37592L0.875977 11.5938C0.875977 11.9431 1.01471 12.278 1.26167 12.525C1.50863 12.7719 1.84357 12.9107 2.19282 12.9107H11.4107C11.76 12.9107 12.0949 12.7719 12.3419 12.525C12.5888 12.278 12.7276 11.9431 12.7276 11.5938V6.98487"
        />
      </g>
    </svg>
  );
});
