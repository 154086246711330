import { SvgNames } from "types/business/SvgNames";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { IconProps } from "./IconProps";
import { icons } from "./icons";

interface SvgImageProps extends IconProps {
  name: SvgNames;
}

export const SvgImage = createComponent<SvgImageProps>("SvgImage", (props) => {
  const { name, ...rest } = props;

  const Image = icons[name];

  return <Image {...rest} isFinOn={isFinOn} isQi={isQi} />;
});
