import { useDefaultObservableOptionModelArray } from "observables/OptionModelArrayObservable";
import { useDefaultObservableOptionModel } from "observables/OptionModelObservable";
import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SFeedbackFormSelector } from "./FeedbackFormSelectorStyled/SFeedbackFormSelector.styled";
import { SFeedbackFormSelectorSelect } from "./FeedbackFormSelectorStyled/SFeedbackFormSelectorSelect.styled";

export const FeedbackFormSelector = createComponent("FeedbackFormSelector", () => {
  const { t, i18n } = useTranslation();
  const selectValue = useDefaultObservableOptionModel(supportPageService.state.selectValue);
  const selectOptionList = useDefaultObservableOptionModelArray(supportPageService.state.selectOptionList);
  const isArabic = i18n.language === "ar";

  return (
    <SFeedbackFormSelector isArabic={isArabic}>
      <div>{t("supportPage.feedbackFormSelector")}</div>
      <SFeedbackFormSelectorSelect value={selectValue} onChange={supportPageService.controller.onChangeSelectValue} optionList={selectOptionList} />
    </SFeedbackFormSelector>
  );
});
