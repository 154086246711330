export class UserModel {
  public readonly id: number;

  public readonly name: string;

  public readonly merchantGroupId: string;

  public readonly email: string;

  public readonly phoneNumber: string;

  public readonly blockedFlag: string;

  public readonly enabled: boolean;

  public readonly merchantGroupName: string;

  public readonly roles: string[];

  public constructor(data: any) {
    this.id = data.id || 0;
    this.name = data.name || "";
    this.merchantGroupId = data.merchantGroupId || "";
    this.email = data.email || "";
    this.phoneNumber = data.phoneNumber || "";
    this.roles = data.roles || [""];
    this.blockedFlag = data.blockedFlag || "";
    this.enabled = data.enabled || true;
    this.merchantGroupName = data.merchantGroupName || "";
  }
}
