import { TableNavigation } from "components/TableNavigation/TableNavigation.component";
import { useTranslation } from "react-i18next";
import { paymentsPageService } from "service/shared/singletones/paymentsPageService/paymentsPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useEffect } from "utils/libReplace/useEffect";

import { PaymentsPageFilters } from "./PaymentsPageFilters/PaymentsPageFilters.component";
import { SPaymentsPage } from "./PaymentsPageStyled/SPaymentsPage.styled";
import { PaymentsTable } from "./PaymentsTable/PaymentsTable.component";

export const PaymentsPage = createComponent("PaymentsPage", () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    paymentsPageService.controller.mounted();

    return (): void => {
      paymentsPageService.controller.unMounted();
    };
  }, []);

  return (
    <SPaymentsPage isArabic={i18n.language === "ar"}>
      <PaymentsPageFilters />
      <PaymentsTable />
      <TableNavigation service={paymentsPageService.state.filterNavigationService} />
    </SPaymentsPage>
  );
});
