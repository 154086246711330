import { setZero } from "./setZero";

export const convertRewardDateToUtc0 = (value: string): string => {
  const [first, second] = value.split("_").map((el) => {
    const date = new Date(el);

    const values = {
      year: setZero(date.getUTCFullYear()),
      month: setZero(date.getUTCMonth() + 1),
      date: setZero(date.getUTCDate()),
      hour: setZero(date.getUTCHours()),
      minute: setZero(date.getUTCMinutes()),
      second: setZero(date.getUTCSeconds()),
    };

    return `${values.year}-${values.month}-${values.date}T${values.hour}:${values.minute}:${values.second}z`;
  });

  return `${first}_${second}`;
};
