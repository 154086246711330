import { useMultipleCustomSelectOptionContext } from "context/MultipleCustomSelectOption.context";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { SMultipleCustomSelectOptionCommon } from "./MultipleCustomSelectOptionCommonStyled/SMultipleCustomSelectOptionCommon.styled";

export const MultipleCustomSelectOptionCommon = createComponent("MultipleCustomSelectOptionCommon", () => {
  const { isLast = false, option, service, value } = useMultipleCustomSelectOptionContext();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const componentOption = option!;
  const componentValue = value!;

  const isActive = !!componentValue.find((optionValue) => optionValue.value === componentOption.value);

  return (
    <SMultipleCustomSelectOptionCommon isArabic={isArabic} isLast={isLast} isActive={isActive} onClick={service.controller.onChangeHandler(componentOption)}>
      {componentOption.title}
    </SMultipleCustomSelectOptionCommon>
  );
});
