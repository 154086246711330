import { SupportArticleContext } from "context/SuportArticle.context";
import { ArticleDescriptionInputService } from "service/shared/others/ArticleDescriptionInputService/ArticleDescriptionInput.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SupportArticleProps } from "./SupportArticle.props";
import { SupportArticleContent } from "./SupportArticleContent/SupportArticleContent.component";

export const SupportArticle = createComponent<SupportArticleProps>("SupportArticle", (props) => {
  const { supportArticle } = props;

  return (
    <SupportArticleContext.Provider
      value={{
        service: new ArticleDescriptionInputService(supportArticle.description, supportArticle.email, supportArticle.type),
      }}
    >
      <SupportArticleContent />
    </SupportArticleContext.Provider>
  );
});
