import { BehaviorSubjectToastServiceArray } from "observables/ToastServiceArrayObservable";

class State {
  public readonly toasts: BehaviorSubjectToastServiceArray;

  public nextId: number;

  public constructor() {
    this.nextId = 0;
    this.toasts = new BehaviorSubjectToastServiceArray([]);
  }
}

export const toastContainerState = new State();
