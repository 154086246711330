/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Refunds = createIcon("Refunds", (props) => {
  const { width = 25, height = 14, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#82B8E7" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 25 14" fill="none">
      <path
        d="M7.22852 2.33337C7.22852 1.78109 7.67623 1.33337 8.22852 1.33337H22.9308C23.4831 1.33337 23.9308 1.78109 23.9308 2.33337V11.3077C23.9308 11.86 23.4831 12.3077 22.9308 12.3077H8.22851C7.67623 12.3077 7.22852 11.86 7.22852 11.3077V2.33337Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47852 2.33337C6.47852 1.36688 7.26202 0.583374 8.22852 0.583374H22.9308C23.8973 0.583374 24.6808 1.36688 24.6808 2.33337V11.3077C24.6808 12.2742 23.8973 13.0577 22.9308 13.0577H8.22851C7.26201 13.0577 6.47852 12.2742 6.47852 11.3077V2.33337ZM8.22852 2.08337C8.09044 2.08337 7.97852 2.1953 7.97852 2.33337V11.3077C7.97852 11.4458 8.09044 11.5577 8.22851 11.5577H22.9308C23.0689 11.5577 23.1808 11.4458 23.1808 11.3077V2.33337C23.1808 2.1953 23.0689 2.08337 22.9308 2.08337H8.22852Z"
        fill={color}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.9307 9.39856H7.05811V8.39856H23.9307V9.39856Z" fill={color} />
      <path
        d="M9.15674 4.44019C9.15674 4.16404 9.3806 3.94019 9.65674 3.94019H12.3031C12.5792 3.94019 12.8031 4.16404 12.8031 4.44019V6.07029C12.8031 6.34643 12.5792 6.57029 12.3031 6.57029H9.65674C9.3806 6.57029 9.15674 6.34643 9.15674 6.07029V4.44019Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.26319 6.72846C1.15967 6.64839 1.15967 6.49211 1.26319 6.41204L4.35161 4.02349C4.48306 3.92182 4.67396 4.01552 4.67396 4.18169V5.65998H10.229V7.48054H4.67396V8.95881C4.67396 9.12498 4.48306 9.21868 4.35161 9.11701L1.26319 6.72846Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.651414 7.5195C0.0302559 7.0391 0.0302598 6.10143 0.651414 5.62103L3.73983 3.23247C4.52853 2.6225 5.67396 3.18465 5.67396 4.18171V4.66H11.229V8.48056H5.67396V8.95882C5.67396 9.95588 4.52853 10.518 3.73983 9.90806L0.651414 7.5195ZM10.229 5.66V7.48056H4.67396V8.95882C4.67396 9.125 4.48306 9.21869 4.35161 9.11703L1.87214 7.19942L1.26319 6.72847C1.15967 6.64841 1.15967 6.49213 1.26319 6.41206L1.87213 5.94111L1.87592 5.93818L4.35161 4.0235C4.48306 3.92184 4.67396 4.01553 4.67396 4.18171V5.66H10.229ZM1.87497 5.93744C1.87529 5.93769 1.8756 5.93793 1.87592 5.93818Z"
        fill="white"
      />
    </svg>
  );
});
