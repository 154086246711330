import { Filters } from "components/Filters/Filters.component";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardDetailsHeader } from "./RewardDetailsHeaderStyled/SRewardDetailsHeader.styled";
import { SRewardDetailsHeaderFilter } from "./RewardDetailsHeaderStyled/SRewardDetailsHeaderFilter.styled";

export const RewardDetailsHeader = createComponent("RewardDetailsHeader", () => {
  return (
    <SRewardDetailsHeader>
      <SRewardDetailsHeaderFilter>
        <Filters service={rewardDetailsPageService.filterService} />
      </SRewardDetailsHeaderFilter>
    </SRewardDetailsHeader>
  );
});
