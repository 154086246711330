/* eslint-disable max-len */

import { createIcon } from "utils/libExtend/createIcon";

export const LogoFin = createIcon("LogoFin", (props) => {
  const { width = 244, height = 50, color = "#00346E" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 344 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* <rect x="0.955078" y="0.732897" width="488.248" height="229.359" fill="#F7FAFB" /> */}
      <svg width={width} height={height} viewBox="0 0 344 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.64733 74.2952C4.25942 74.2952 3.09232 73.8221 2.14601 72.8758C1.19971 71.9295 0.726562 70.7624 0.726562 69.3745V5.30989C0.726562 3.8589 1.19971 2.69179 2.14601 1.80858C3.09232 0.86228 4.25942 0.38913 5.64733 0.38913H48.4201C49.8711 0.38913 51.0382 0.830736 51.9215 1.71395C52.8047 2.53408 53.2463 3.63809 53.2463 5.026C53.2463 6.35082 52.8047 7.4233 51.9215 8.24343C51.0382 9.06355 49.8711 9.47361 48.4201 9.47361H10.5681V32.4687H37.7269C39.1779 32.4687 40.345 32.9103 41.2283 33.7935C42.1115 34.6137 42.5531 35.6861 42.5531 37.011C42.5531 38.3358 42.1115 39.4398 41.2283 40.323C40.345 41.1431 39.1779 41.5532 37.7269 41.5532H10.5681V69.3745C10.5681 70.7624 10.0949 71.9295 9.14864 72.8758C8.20234 73.8221 7.03524 74.2952 5.64733 74.2952Z"
          fill="#2C3246"
        />
        <path
          d="M71.226 74.2952C69.8381 74.2952 68.671 73.8221 67.7247 72.8758C66.7784 71.9295 66.3052 70.7624 66.3052 69.3745V5.30989C66.3052 3.8589 66.7784 2.69179 67.7247 1.80858C68.671 0.86228 69.8381 0.38913 71.226 0.38913C72.677 0.38913 73.8441 0.86228 74.7273 1.80858C75.6736 2.69179 76.1468 3.8589 76.1468 5.30989V69.3745C76.1468 70.7624 75.6736 71.9295 74.7273 72.8758C73.8441 73.8221 72.677 74.2952 71.226 74.2952Z"
          fill="#2C3246"
        />
        <path
          d="M93.8817 74.2952C92.4938 74.2952 91.3267 73.8221 90.3804 72.8758C89.4972 71.9295 89.0556 70.7624 89.0556 69.3745V5.30989C89.0556 3.8589 89.4972 2.69179 90.3804 1.80858C91.3267 0.86228 92.4938 0.38913 93.8817 0.38913C95.3958 0.38913 96.6576 0.988453 97.667 2.1871L138.926 57.0725V5.30989C138.926 3.8589 139.399 2.69179 140.345 1.80858C141.291 0.86228 142.459 0.38913 143.846 0.38913C145.297 0.38913 146.465 0.86228 147.348 1.80858C148.231 2.69179 148.673 3.8589 148.673 5.30989V69.3745C148.673 70.7624 148.231 71.9295 147.348 72.8758C146.465 73.8221 145.297 74.2952 143.846 74.2952C143.216 74.2952 142.522 74.169 141.765 73.9167C141.071 73.6013 140.503 73.1597 140.061 72.5919L98.8025 17.7064V69.3745C98.8025 70.7624 98.3294 71.9295 97.3831 72.8758C96.4998 73.8221 95.3327 74.2952 93.8817 74.2952Z"
          fill="#2C3246"
        />
        <path
          d="M288.238 74.2952C286.85 74.2952 285.683 73.8221 284.737 72.8758C283.854 71.9295 283.412 70.7624 283.412 69.3745V5.30989C283.412 3.8589 283.854 2.69179 284.737 1.80858C285.683 0.86228 286.85 0.38913 288.238 0.38913C289.752 0.38913 291.014 0.988453 292.023 2.1871L333.282 57.0726V5.30989C333.282 3.8589 333.755 2.69179 334.702 1.80858C335.648 0.86228 336.815 0.38913 338.203 0.38913C339.654 0.38913 340.821 0.86228 341.704 1.80858C342.587 2.69179 343.029 3.8589 343.029 5.30989V69.3745C343.029 70.7624 342.587 71.9295 341.704 72.8758C340.821 73.8221 339.654 74.2952 338.203 74.2952C337.572 74.2952 336.878 74.1691 336.121 73.9167C335.427 73.6013 334.859 73.1597 334.418 72.5919L293.159 17.7064V69.3745C293.159 70.7624 292.686 71.9295 291.74 72.8758C290.856 73.8221 289.689 74.2952 288.238 74.2952Z"
          fill="#2C3246"
        />
        <path
          d="M250.984 37.413C250.984 47.0817 243.146 54.9197 233.477 54.9197C223.809 54.9197 215.971 47.0817 215.971 37.413C215.971 27.7442 223.809 19.9062 233.477 19.9062C243.146 19.9062 250.984 27.7442 250.984 37.413Z"
          fill="#2AC77B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.48 10.147H198.467C183.409 10.147 171.202 22.3538 171.202 37.4116C171.202 52.4695 183.409 64.6762 198.467 64.6762H233.48C248.538 64.6762 260.745 52.4694 260.745 37.4116C260.745 22.3538 248.538 10.147 233.48 10.147ZM198.467 0.38913C178.02 0.38913 161.444 16.9647 161.444 37.4116C161.444 57.8586 178.02 74.4341 198.467 74.4341H233.48C253.927 74.4341 270.503 57.8586 270.503 37.4116C270.503 16.9647 253.927 0.38913 233.48 0.38913H198.467Z"
          fill="#2C3246"
        />
      </svg>
    </svg>
  );
});
