import { useDefaultObservableString } from "observables/StringObservable";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { FullActionButton } from "./FullActionButton/FullActionButton.component";
import { PartialActionButton } from "./PartialActionButton/PartialActionButton.component";
import { SUnSelectedActionStepButtons } from "./UnSelectedActionStepStyled/SUnSelectedActionStepButtons.styled";
import { SUnSelectedActionStepTitle } from "./UnSelectedActionStepStyled/SUnSelectedActionStepTitle.styled";

export const UnSelectedActionStep = createComponent("UnSelectedActionStep", () => {
  const stageUnSelectedTitle = useDefaultObservableString(paymentDetailsPageService.state.stageUnSelectedTitle);

  return (
    <>
      <SUnSelectedActionStepTitle>{stageUnSelectedTitle}</SUnSelectedActionStepTitle>
      <SUnSelectedActionStepButtons>
        <FullActionButton />
        <PartialActionButton />
      </SUnSelectedActionStepButtons>
    </>
  );
});
