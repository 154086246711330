import { ActivateInviteInputController } from "service/shared/others/ActivateInviteInputService/ActivateInviteInput.controller";
import { ActivateInviteInputState } from "service/shared/others/ActivateInviteInputService/ActivateInviteInput.state";

type InputServiceParams = {
  required?: true;
};

export class ActivateInviteInputService {
  public readonly state: ActivateInviteInputState;

  public readonly controller: ActivateInviteInputController;

  public constructor(params?: InputServiceParams) {
    this.state = new ActivateInviteInputState(params);
    this.controller = new ActivateInviteInputController(this.state);
  }
}
