import { useTranslation } from "react-i18next";
import { supportPageService } from "service/shared/singletones/supportPageService/supportPage.service";
import { createComponent } from "utils/libExtend/createComponent";
import { useDefaultObservable } from "utils/libExtend/useDefaultObservable";

import { SFeedbackFormSendButton } from "./FeedbackFormSendButtonStyled/SFeedbackFormSendButton.styled";

export const FeedbackFormSendButton = createComponent("FeedbackFormSendButton", () => {
  const { t } = useTranslation();
  const sendButtonValid = useDefaultObservable<boolean>(supportPageService.state.sendButtonValid);

  return (
    <SFeedbackFormSendButton sendButtonValid={sendButtonValid} disabled={!sendButtonValid} onClick={supportPageService.controller.onClickSendButton}>
      {t("supportPage.feedbackFormSendButtonTitle")}
    </SFeedbackFormSendButton>
  );
});
