import { initialHeaderCellKey } from "appConstants/initialHeaderCellKey";
import { tableWidths } from "appConstants/tableWidths";
import { H2HDetailsCell } from "detailsCell/H2HDetailsCell/H2HDetailsCell.component";
import { t } from "i18n";
import { SetPageTableHandlerType } from "types/business/SetPageTableHandlerType";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const h2hPage: SetPageTableHandlerType = () => {
  return [
    getInitialHeaderCell(initialHeaderCellKey.details, t("h2hPageConfig.details"), "h2hPageConfig.details", {
      isDetails: true,
      Element: H2HDetailsCell,
      width: tableWidths.width120,
    }),
    getInitialHeaderCell(initialHeaderCellKey.terminalTzOperationTime, t("h2hPageConfig.terminalTzOperationTime"), "h2hPageConfig.terminalTzOperationTime", {
      width: tableWidths.width350,
    }),
    getInitialHeaderCell(
      initialHeaderCellKey.processingTzOperationTime,
      t("h2hPageConfig.processingTzOperationTime"),
      "h2hPageConfig.processingTzOperationTime",
      { width: tableWidths.width300 }
    ),
    getInitialHeaderCell(initialHeaderCellKey.merchantId, t("h2hPageConfig.merchantId"), "h2hPageConfig.merchantId", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalId, t("h2hPageConfig.terminalId"), "h2hPageConfig.terminalId", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.amount, t("h2hPageConfig.amount"), "h2hPageConfig.amount", { width: tableWidths.width200 }),
    getInitialHeaderCell(initialHeaderCellKey.refundsAmount, t("h2hPageConfig.refundsAmount"), "h2hPageConfig.refundsAmount", { width: tableWidths.width300 }),
    getInitialHeaderCell(initialHeaderCellKey.messageCode, t("h2hPageConfig.messageCode"), "h2hPageConfig.messageCode", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.responseCode, t("h2hPageConfig.responseCode"), "h2hPageConfig.responseCode", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.maskedPan, t("h2hPageConfig.maskedPan"), "h2hPageConfig.maskedPan", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.rrn, t("h2hPageConfig.rrn"), "h2hPageConfig.rrn", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.transactionType, t("h2hPageConfig.transactionType"), "h2hPageConfig.transactionType", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.authId, t("h2hPageConfig.authId"), "h2hPageConfig.authId", { width: tableWidths.width200 }),
    getInitialHeaderCell(initialHeaderCellKey.pointOfServiceEntryMode, t("h2hPageConfig.pointOfServiceEntryMode"), "h2hPageConfig.pointOfServiceEntryMode", {
      width: tableWidths.width200,
    }),
    getInitialHeaderCell(initialHeaderCellKey.eci, t("h2hPageConfig.eci"), "h2hPageConfig.eci", { width: tableWidths.width100 }),
    getInitialHeaderCell(initialHeaderCellKey.mcc, t("h2hPageConfig.mcc"), "h2hPageConfig.mcc", { width: tableWidths.width100 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalName, t("h2hPageConfig.terminalName"), "h2hPageConfig.terminalName", { width: tableWidths.width200 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalCity, t("h2hPageConfig.terminalCity"), "h2hPageConfig.terminalCity", { width: tableWidths.width100 }),
    getInitialHeaderCell(initialHeaderCellKey.terminalRegion, t("h2hPageConfig.terminalRegion"), "h2hPageConfig.terminalRegion", {
      width: tableWidths.width100,
    }),
    getInitialHeaderCell(initialHeaderCellKey.terminalCountry, t("h2hPageConfig.terminalCountry"), "h2hPageConfig.terminalCountry", {
      width: tableWidths.width100,
    }),
    getInitialHeaderCell(initialHeaderCellKey.currency, t("h2hPageConfig.currency"), "h2hPageConfig.currency", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.acquireId, t("h2hPageConfig.acquireId"), "h2hPageConfig.acquireId", { width: tableWidths.width150 }),
    getInitialHeaderCell(initialHeaderCellKey.paymentSystem, t("h2hPageConfig.paymentSystem"), "h2hPageConfig.paymentSystem", { width: tableWidths.width200 }),
  ];
};
