import { OptionModel } from "model/Option.model";
import { CompareYearState } from "service/shared/others/CompareYearService/CompareYear.state";

export class CompareYearController {
  public readonly state: CompareYearState;

  public constructor(state: CompareYearState) {
    this.state = state;
  }

  public readonly onChange = (value: OptionModel): void => {
    this.state.value.next(value);
  };
}
