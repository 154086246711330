import { menuController } from "service/shared/singletones/menuService/menu.controller";
import { menuState } from "service/shared/singletones/menuService/menu.state";

class Service {
  public readonly state = menuState;

  public readonly controller = menuController;
}

export const menuService = new Service();
