import { Observable } from "packages/rxjs";
import { useEffect } from "utils/libReplace/useEffect";
import { useState } from "utils/libReplace/useState";

export const useObservable = <T>(observable: Observable<T>, initialState: T): T => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const subscription = observable.subscribe(setState);
    return (): void => {
      subscription.unsubscribe();
    };
  }, [observable]);

  return state;
};
