import { useTranslation } from "react-i18next";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SBackButtonButton } from "./BackButtonButtonStyled/SBackButtonButton.styled";

export const BackButtonButton = createComponent("BackButtonButton", () => {
  const { t } = useTranslation();
  return (
    <SBackButtonButton onClick={paymentDetailsPageService.controller.closesAdditionalInfoForm}>{t("paymentDetailsPage.backButtonButton")}</SBackButtonButton>
  );
});
