import { amountFilterModel } from "config/filterModels/amountFilterModel";
import { authCodeFilterModel } from "config/filterModels/authCodeFilterModel";
import { cardHolderFilterModel } from "config/filterModels/cardHolderFilterModel";
import { cardNumberFilterModel } from "config/filterModels/cardNumberFilterModel";
import { dateFilterModel } from "config/filterModels/dateFilterModel";
import { merchantFilterModel } from "config/filterModels/merchantFilterModel";
import { operationStatusFilterModel } from "config/filterModels/operationStatusFilterModel";
import { operationTypeFilterModel } from "config/filterModels/operationTypeFilterModel";
import { paymentIdFilterModel } from "config/filterModels/paymentIdFilterModel";
import { paymentSystemTypeFilterModel } from "config/filterModels/paymentSystemTypeFilterModel";
import { requestIdFilterModel } from "config/filterModels/requestIdFilterModel";
import { resultCodeFilterModel } from "config/filterModels/resultCodeFilterModel";
import { rrnFilterModel } from "config/filterModels/rrnFilterModel";
import { terminalFilterModel } from "config/filterModels/terminalFilterModel";
import { FilterModel } from "model/Filter.model";

export const paymentsFilterConfig: FilterModel[] = [
  dateFilterModel,
  requestIdFilterModel,
  rrnFilterModel,
  paymentIdFilterModel,
  cardHolderFilterModel,
  cardNumberFilterModel,
  operationStatusFilterModel,
  terminalFilterModel,
  merchantFilterModel,
  paymentSystemTypeFilterModel,
  operationTypeFilterModel,
  amountFilterModel,
  resultCodeFilterModel,
  authCodeFilterModel,
];
