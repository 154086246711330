import { styled } from "theme/default/styled";
import { setFlexDFF1 } from "theme/setter/setFlexValues/setFlexDFF1";

export const SAuditTable = styled.div`
  overflow: hidden;
  margin-top: -8px;
  margin-left: 2px;
  padding-right: 16px;
  padding-bottom: 8px;

  ${setFlexDFF1};
`;
