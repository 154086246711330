import { SvgImage } from "components/SvgImage/SvgImage.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useTranslation } from "react-i18next";
import { navigationMenuService } from "service/shared/singletones/navigationMenuService/navigationMenu.service";
import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { colorsValuesQi } from "theme/valuesQi/colorsValuesQi";
import { isFinOn, isQi } from "utils/business/environment";
import { createComponent } from "utils/libExtend/createComponent";

import { SNavigationMenuToggler } from "./TogglerStyled/SNavigationMenuToggler.styled";
import { SNavigationMenuTogglerArrow } from "./TogglerStyled/SNavigationMenuTogglerArrow.styled";

export const Toggler = createComponent("Toggler", () => {
  const { i18n } = useTranslation();
  const open = useDefaultObservableBoolean(navigationMenuService.state.open);

  const isArabic = i18n.language === "ar";

  const name = open ? "ArrowLeft" : "ArrowRight";
  const nameArabic = open ? "ArrowRight" : "ArrowLeft";

  return (
    <SNavigationMenuToggler onClick={navigationMenuService.controller.toggle} isArabic={isArabic}>
      <SNavigationMenuTogglerArrow isArabic={isArabic}>
        <SvgImage name={isArabic ? nameArabic : name} color={isFinOn ? colorsValuesFin.main.icon_fill : isQi ? colorsValuesQi.main.icon_fill : undefined} />
      </SNavigationMenuTogglerArrow>
    </SNavigationMenuToggler>
  );
});
