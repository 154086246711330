import { Table } from "components/Table/Table.component";
import { useDefaultObservableBoolean } from "observables/BooleanObservable";
import { useDefaultObservableTableService } from "observables/TableServiceObservable";
import { rewardDetailsPageService } from "service/shared/singletones/rewardDetailsPageService/rewardDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { SRewardDetailsTable } from "./RewardDetailsTableStyled/SRewardDetailsTable.styled";
import { SRewardDetailsTableContainer } from "./RewardDetailsTableStyled/SRewardDetailsTableContainer.styled";

export const RewardDetailsTable = createComponent("RewardDetailsTable", () => {
  const loaded = useDefaultObservableBoolean(rewardDetailsPageService.state.loaded);
  const tableService = useDefaultObservableTableService(rewardDetailsPageService.tableService);

  return (
    <SRewardDetailsTable>
      <SRewardDetailsTableContainer>
        <Table loaded={loaded} service={tableService} />
      </SRewardDetailsTableContainer>
    </SRewardDetailsTable>
  );
});
