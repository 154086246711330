import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { BehaviorSubjectString } from "observables/StringObservable";
import { initialTypeOptionList } from "service/shared/others/ArticleDescriptionInputService/initialTypeOptionList";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class ArticleDescriptionInputState {
  public readonly value: BehaviorSubjectString;

  public readonly valid: BehaviorSubjectBoolean;

  public readonly descriptionValue: BehaviorSubjectString;

  public readonly emailValue: BehaviorSubjectString;

  public readonly typeValue: BehaviorSubjectOptionModel;

  public readonly typeOptionList: OptionModel[];

  public readonly formIsChanged: BehaviorSubjectBoolean;

  private readonly initialDescription: string;

  private readonly initialEmail: string;

  private readonly initialType: string;

  private readonly showError: BehaviorSubjectBoolean;

  public constructor(initialDescription: string, initialEmail: string, initialType: string) {
    this.initialDescription = initialDescription;
    this.initialEmail = initialEmail;
    this.initialType = initialType;

    this.descriptionValue = new BehaviorSubjectString(this.initialDescription);
    this.emailValue = new BehaviorSubjectString(this.initialEmail);
    this.typeValue = new BehaviorSubjectOptionModel(new OptionModel(this.initialType, this.initialType));

    this.value = new BehaviorSubjectString("");
    this.valid = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);

    this.typeOptionList = initialTypeOptionList;

    this.formIsChanged = setCombinedBehaviorSubject(this.setFormIsChanged, this.descriptionValue, this.emailValue, this.typeValue);
  }

  private readonly setFormIsChanged = (descriptionValue: string, emailValue: string, typeValue: string): boolean => {
    const notEqualDescription = descriptionValue !== this.initialDescription;
    const notEqualEmail = emailValue !== this.initialEmail;
    const notEqualType = typeValue !== this.initialType;

    return notEqualDescription || notEqualEmail || notEqualType;
  };
}
