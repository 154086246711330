import { Filters } from "components/Filters/Filters.component";
import { transfersPageService } from "service/shared/singletones/transfersPageService/transfersPage.service";
import { createComponent } from "utils/libExtend/createComponent";

export const TransfersPageFilters = createComponent("TransfersPageFilters", () => {
  return (
    <Filters
      totalElements={transfersPageService.state.filterNavigationService.state.totalElements}
      service={transfersPageService.filterService}
      csvCallback={transfersPageService.getTotalData}
      csvName={transfersPageService.csvName}
    />
  );
});
