import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBorderRadius_8px } from "theme/setter/setBorderRadiusValues/setBorderRadius_8px";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const SRewardsTableContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  margin-left: 10px;

  box-shadow: 0 0 7px ${color_rgba_183_200_220_80};

  ${setFlexDF};
  ${setBorderRadius_8px};
`;
