import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { color_3a8fda } from "theme/setter/colorsValues/color_3a8fda";
import { setBackgroundColor_3a8fda } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_3a8fda";
import { setBorderRadius_4px } from "theme/setter/setBorderRadiusValues/setBorderRadius_4px";
import { setColor_2b3e50 } from "theme/setter/setColorsValues/setColor_2b3e50";
import { setColor_ffffff } from "theme/setter/setColorsValues/setColor_ffffff";
import { setFlexDFAIC } from "theme/setter/setFlexValues/setFlexDFAIC";
import { setFlexJCC } from "theme/setter/setFlexValues/setFlexJCC";
import { setFontSize_12px } from "theme/setter/setFontSizeValues/setFontSize_12px";
import { setFontWeight500 } from "theme/setter/setFontWeightValues/setFontWeight500";

type SControlCellProps = {
  active: boolean;
};

const activeSet = setProps<SControlCellProps>(({ active }) => active)(css`
  ${setFontSize_12px};
  ${setFontWeight500};
  ${setColor_ffffff};
  ${setBackgroundColor_3a8fda};
`);

export const SControlCell = styled.div<SControlCellProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  width: 25px;
  height: 25px;

  border: 1px solid ${color_3a8fda};

  ${setFlexDFAIC};
  ${setFlexJCC};
  ${setFontSize_12px};
  ${setFontWeight500};
  ${setColor_2b3e50};
  ${setBorderRadius_4px};
  ${activeSet};
`;
