import { useDefaultObservableString } from "observables/StringObservable";
import { paymentDetailsPageService } from "service/shared/singletones/paymentDetailsPageService/paymentDetailsPage.service";
import { createComponent } from "utils/libExtend/createComponent";

import { ApplyButton } from "./ApplyButton/ApplyButton.component";
import { BackButtonButton } from "./BackButtonButton/BackButtonButton.component";
import { PaymentActionInput } from "./PaymentActionInput/PaymentActionInput.component";
import { SSelectedActionStepButtons } from "./SelectedActionStepStyled/SSelectedActionStepButtons.styled";
import { SSelectedActionStepTitle } from "./SelectedActionStepStyled/SSelectedActionStepTitle.styled";

export const SelectedActionStep = createComponent("SelectedActionStep", () => {
  const stageSelectedTitle = useDefaultObservableString(paymentDetailsPageService.state.stageSelectedTitle);

  return (
    <>
      <SSelectedActionStepTitle>{stageSelectedTitle}</SSelectedActionStepTitle>
      <PaymentActionInput />
      <SSelectedActionStepButtons>
        <ApplyButton />
        <BackButtonButton />
      </SSelectedActionStepButtons>
    </>
  );
});
