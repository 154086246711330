/* eslint-disable max-len */

import { colorsValuesFin } from "theme/valuesFin/colorsValuesFin";
import { createIcon } from "utils/libExtend/createIcon";

export const Dynamic = createIcon("Dynamic", (props) => {
  const { width = 22, height = 22, color = props.isFinOn ? colorsValuesFin.color_0083f5 : "#90C5F5" } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0549 5.88912C18.233 5.96308 18.3745 6.1046 18.4484 6.2827C18.4858 6.37032 18.5056 6.46441 18.5067 6.55965V10.2039C18.5067 10.3972 18.4299 10.5826 18.2933 10.7192C18.1566 10.8559 17.9713 10.9327 17.778 10.9327C17.5848 10.9327 17.3994 10.8559 17.2627 10.7192C17.1261 10.5826 17.0493 10.3972 17.0493 10.2039L17.0493 8.31617L12.8314 12.5343C12.8314 12.5343 12.6312 12.7344 12.3496 12.7821C12.1172 12.8225 11.8695 12.753 11.6901 12.5736L10.0831 10.9666L4.50177 15.9905C4.19391 16.2676 3.71969 16.2427 3.44257 15.9348C3.16546 15.627 3.19038 15.1527 3.49824 14.8756L9.60599 9.37784C9.76578 9.2325 9.97315 9.1685 10.1748 9.18583C10.2585 9.19302 10.3411 9.21419 10.419 9.24934C10.5014 9.28648 10.5782 9.33915 10.6449 9.40709L12.2713 11.0334L16.0218 7.2885L14.1345 7.2885C13.9412 7.2885 13.7559 7.21171 13.6192 7.07502C13.4826 6.93834 13.4058 6.75295 13.4058 6.55965C13.4058 6.36635 13.4826 6.18097 13.6192 6.04428C13.7559 5.9076 13.9412 5.83081 14.1345 5.83081H17.778C17.8732 5.83196 17.9673 5.85177 18.0549 5.88912Z"
        fill={color}
      />
    </svg>
  );
});
