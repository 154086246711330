import { t } from "i18n";
import { FilterModel } from "model/Filter.model";
import { setEndInitialDate } from "utils/business/setEndInitialDate";
import { setStartInitialDate } from "utils/business/setStartInitialDate";

export const dateFilterModel = new FilterModel({
  id: 19,
  type: "range",
  title: t("dateFilterModel.title"),
  name: "CREATION_DATE",
  options: {
    dateFormat: "DD/MM/YYYY",
    timeFormat: "HH:mm",
    initialStartDateValue: setStartInitialDate(),
    initialEndDateValue: setEndInitialDate(),
  },
  langPath: "dateFilterModel.title",
});
