import React from "react";
import { css } from "theme/default/css";
import { setProps } from "theme/default/setProps";
import { styled } from "theme/default/styled";
import { setColor_458cce } from "theme/setter/setColorsValues/setColor_458cce";
import { setColor_69809a } from "theme/setter/setColorsValues/setColor_69809a";
import { setFontSize_14px } from "theme/setter/setFontSizeValues/setFontSize_14px";
import { setFontWeight400 } from "theme/setter/setFontWeightValues/setFontWeight400";

type SNavigationMenuIconTitleProps = {
  active: boolean;
};

const activeSet = setProps<SNavigationMenuIconTitleProps>(({ active }) => active)(css`
  ${setColor_458cce};
`);

export const SNavigationMenuIconTitle = styled.div<SNavigationMenuIconTitleProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-left: 8px;

  ${setFontSize_14px};
  ${setFontWeight400};
  ${setColor_69809a};
  ${activeSet};
`;
