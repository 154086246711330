import { OptionModel } from "model/Option.model";
import { BehaviorSubjectBoolean } from "observables/BooleanObservable";
import { BehaviorSubjectOptionModelArray } from "observables/OptionModelArrayObservable";
import { BehaviorSubjectOptionModel } from "observables/OptionModelObservable";
import { BehaviorSubjectSvgNames } from "observables/SvgNamesObservable";
import { SvgNames } from "types/business/SvgNames";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

export class UsersChangeRowGroupState {
  public readonly initialValue: BehaviorSubjectOptionModel;

  public readonly value: BehaviorSubjectOptionModel;

  public readonly active: BehaviorSubjectBoolean;

  public readonly showError: BehaviorSubjectBoolean;

  public readonly valueIsEqual: BehaviorSubjectBoolean;

  public readonly icon: BehaviorSubjectSvgNames;

  public readonly optionList: BehaviorSubjectOptionModelArray;

  public readonly updateUserCallBack: () => void;

  private readonly valid: BehaviorSubjectBoolean;

  public constructor(initialValue: OptionModel, updateUserCallBack: () => void) {
    this.updateUserCallBack = updateUserCallBack;
    this.optionList = new BehaviorSubjectOptionModelArray([]);
    this.initialValue = new BehaviorSubjectOptionModel(initialValue);
    this.value = new BehaviorSubjectOptionModel(this.initialValue.value);
    this.active = new BehaviorSubjectBoolean(false);
    this.showError = new BehaviorSubjectBoolean(false);
    this.valueIsEqual = setCombinedBehaviorSubject(this.setValueIsEqual, this.value, this.initialValue);
    this.icon = setCombinedBehaviorSubject(this.setIcon, this.active, this.valueIsEqual);

    this.valid = setCombinedBehaviorSubject(this.setValid, this.value);
  }

  private readonly validate = (value: string): boolean => {
    const newValue = value.toString();

    return newValue.trim() !== "";
  };

  private readonly setValid = (value: string): boolean => {
    return this.validate(value);
  };

  private readonly setValueIsEqual = (value: OptionModel, initialValue: OptionModel): boolean => {
    return value === initialValue;
  };

  private readonly setIcon = (active: boolean, valueIsEqual: boolean): SvgNames => {
    if (active) {
      if (valueIsEqual) {
        return "Close";
      }

      return "Check";
    }

    return "EditIcon";
  };
}
