import { userService } from "api/userService";
import { tableWidths } from "appConstants/tableWidths";
import { PaymentsDetailsCell } from "detailsCell/PaymentsDetailsCell/PaymentsDetailsCell.component";
import { t } from "i18n";
import { HeaderCellModel } from "model/HeaderCell.model";
import { getInitialHeaderCell } from "utils/business/getInitialHeaderCell";

export const getInitialHeaderCellList = (): HeaderCellModel[] => {
  const initialHeaderCellList = [
    getInitialHeaderCell("paymentId", t("refundDetailsPageService.paymentId"), "refundDetailsPageService.paymentId", {
      width: tableWidths.width400,
    }),
  ];

  const canGetPaymentInfo = userService.canGetPaymentInfo();

  if (canGetPaymentInfo) {
    initialHeaderCellList.unshift(
      getInitialHeaderCell("details", t("refundDetailsPageService.details"), "refundDetailsPageService.details", {
        isDetails: true,
        Element: PaymentsDetailsCell,
      })
    );
  }

  return initialHeaderCellList;
};
