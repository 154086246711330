import { client } from "api/client";
import { userService } from "api/userService";
import { apiPaths } from "appConstants/apiPaths";
import { authenticatedState } from "service/common/authenticatedService/authenticated.state";

class Controller {
  public readonly state = authenticatedState;

  public readonly logout = async (): Promise<void> => {
    this.state.userIsAuthenticated.next(false);
    userService.removeToken();
    await client.post(apiPaths.logout);
  };

  public readonly authenticate = (): void => {
    this.state.userIsAuthenticated.next(true);
  };
}

export const authenticatedController = new Controller();
