import { OptionModel } from "model/Option.model";

export const initialTypeOptionList = [
  new OptionModel("000000000000", "000000000000"),
  new OptionModel("111111111111", "111111111111"),
  new OptionModel("222222222222", "222222222222"),
  new OptionModel("333333333333", "333333333333"),
  new OptionModel("444444444444", "444444444444"),
  new OptionModel("555555555555", "555555555555"),
  new OptionModel("666666666666", "666666666666"),
];
