import { styled } from "theme/default/styled";
import { color_rgba_183_200_220_80 } from "theme/setter/opacityColorsValues/color_rgba_183_200_220_80";
import { setBackgroundColor_f7faff } from "theme/setter/setBackgroundColorsValues/setBackgroundColor_f7faff";
import { setBorderRadius_5px } from "theme/setter/setBorderRadiusValues/setBorderRadius_5px";
import { setFlexDF } from "theme/setter/setFlexValues/setFlexDF";

export const STableHeader = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 10px;

  box-shadow: 0 0 5px ${color_rgba_183_200_220_80};

  ${setFlexDF};
  ${setBorderRadius_5px};
  ${setBackgroundColor_f7faff};
`;
