import { TableContext } from "context/Table.context";
import { useEffect } from "packages/react";
import { useTranslation } from "react-i18next";
import { createComponent } from "utils/libExtend/createComponent";

import { TableProps } from "./Table.props";
import { TableContent } from "./TableContent/TableContent.component";

export const Table = createComponent<TableProps>("Table", (props) => {
  const { i18n } = useTranslation();
  const { loaded = true, service } = props;
  useEffect(() => {
    service.controller.onChangeArabic(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <TableContext.Provider value={{ loaded, service }}>
      <TableContent />
    </TableContext.Provider>
  );
});
