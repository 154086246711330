export const colorsValuesQi = {
  color_0083f5: "#4f758b",
  color_1e70bf: "#4f758b",
  color_47a0ef: "#4f758b",
  color_00346e: "#354e5d",
  color_8bc3f5: "#354e5d",
  color_2b3e50: "#354e5d",
  color_458cce: "#354e5d",
  color_69809a: "#4f758b",
  color_3a8fda: "#4f758b",
  color_25476d: "#d0b000",
  color_4183c4: "#d0b000",
  color_1839d5: "#d0b000",
  color_a0b9d2: "#d0b000",
  color_9f3a38: "#9f3a38",
  color_c4edff: "#F2CD00",
  color_16e527: "#F2CD00",
  color_7b7b7b: "#7b7b7b",
  color_fff6f6: "#fff6f6",
  color_4d4d4d: "#4d4d4d",
  color_f7faff: "#F7FAFB",
  color_93a5b8: "#93a5b8",
  color_ffffff: "#ffffff",
  color_2d3033: "#2d3033",
  color_f0fbff: "#f0fbff",
  color_e74c3c: "#e74c3c",
  color_b7c8dc: "#b7c8dc",
  color_f6b57a: "#f6b57a",
  color_e7ecf2: "#e7ecf2",
  color_e0b4b4: "#e0b4b4",
  color_f5f5f5: "#f5f5f5",
  color_e8e8e8: "#e8e8e8",
  main: {
    menu_text: "#4f758b",
    icon_fill: "#4f758b",
    container_text: "#d0b000",
  },
};
