import { client } from "api/client";
import { apiPaths } from "appConstants/apiPaths";
import { tableHeaderInitialCell } from "config/filterConfig/tableHeaderInitialCell";
import { transfersFilterConfig } from "config/filterConfig/transfersFilterConfig";
import { t } from "i18n";
import { BehaviorSubjectAnyArray } from "observables/AnyArrayObservable";
import { BehaviorSubjectHeaderCellModelArray } from "observables/HeaderCellModelArrayObservable";
import { BehaviorSubjectTableService } from "observables/TableServiceObservable";
import { BehaviorSubjectTransferTypeArray } from "observables/TransferTypeArrayObservable";
import { combineLatestWith } from "packages/rxjs";
import { FiltersService } from "service/shared/others/FiltersService/Filters.service";
import { TableEntityService } from "service/shared/others/TableEntityService/TableEntity.service";
import { TransferType } from "types/business/TransferType";
import { clearQueriesField } from "utils/business/clearQueriesField";
import { convertFetchedDateToTableShowDate } from "utils/business/convertFetchedDateToTableShowDate";
import { setCombinedBehaviorSubject } from "utils/libExtend/setCombinedBehaviorSubject";

class Service extends TableEntityService<TransferType> {
  public readonly filterService: FiltersService;

  public readonly tableService: BehaviorSubjectTableService;

  private readonly initialHeaderCellList: BehaviorSubjectHeaderCellModelArray;

  private readonly transfers: BehaviorSubjectTransferTypeArray;

  public readonly csvData: BehaviorSubjectAnyArray;

  public readonly csvName: string;

  public constructor() {
    super(tableHeaderInitialCell.transfersPage);
    this.csvName = "filters.cvsNameTransfers";
    this.transfers = new BehaviorSubjectTransferTypeArray([]);
    this.csvData = new BehaviorSubjectAnyArray([]);
    this.filterService = new FiltersService(transfersFilterConfig, this.applyFilter);
    this.initialHeaderCellList = setCombinedBehaviorSubject(this.controller.setInitialHeaderCellList, this.state.sort, this.state.desc);
    this.tableService = setCombinedBehaviorSubject(this.controller.setTableService, this.transfers, this.initialHeaderCellList);

    this.state.isMounted
      .pipe(
        combineLatestWith(
          this.state.filterNavigationService.state.currentPage,
          this.state.filterNavigationService.state.controlsSelectService.state.activeOption,
          this.state.sort
        )
      )
      .subscribe(([isMounted, currentPage, sizeOption, sort]) => {
        if (isMounted) {
          const isMountedNotEqual = this.state.prevIsMounted !== isMounted;
          const currentPageNotEqual = this.state.prevCurrentPage !== currentPage;
          const sizeOptionNotEqual = this.state.prevSizeOption !== +sizeOption.value;
          const sortNotEqual = this.state.prevSort !== sort;

          if (isMountedNotEqual || currentPageNotEqual || sizeOptionNotEqual || sortNotEqual) {
            this.state.loaded.next(false);

            this.state.prevIsMounted = isMounted;
            this.state.prevSizeOption = +sizeOption.value;
            this.state.prevSort = sort;

            this.getTransfers().then(() => {
              this.state.prevCurrentPage = currentPage;
            });
          }
        }
      });
  }

  private readonly produceTransfer = (dataItem: any): TransferType => {
    return {
      requestId: dataItem.requestId || "",
      transferId: dataItem.transferId || "",
      status: dataItem.status || "",
      terminalId: dataItem.terminalId || "",
      amount: dataItem.amount || "",
      type: dataItem.type || "",
      creationDate: convertFetchedDateToTableShowDate(dataItem.creationDate) || "",
    };
  };

  private readonly produceTransferData = (data: any[]): TransferType[] => {
    return data.map(this.produceTransfer);
  };

  private readonly fetchTransfers = async (): Promise<any> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const size = this.state.filterNavigationService.state.controlsSelectService.state.activeOption.value.value;
    const page = this.state.filterNavigationService.state.currentPage.value;
    const sort = this.state.sort.value;

    const query = { page, queries, size, sort };

    const { data } = await client.post(apiPaths.transferInfo, query);

    if (page === this.state.prevCurrentPage) {
      this.state.filterNavigationService.state.currentPage.next(0);
    }

    this.state.filterNavigationService.state.totalPages.next(data.totalPages);
    this.state.filterNavigationService.state.totalElements.next(data.totalElements);

    return data.content;
  };

  public readonly getCsvData = (data: any[]): any[] => {
    const names = {
      type: "transfersPageConfig.type",
      requestId: "transfersPageConfig.requestId",
      transferId: "transfersPageConfig.transferId",
      amount: "transfersPageConfig.amount",
      creationDate: "transfersPageConfig.creationDate",
      status: "transfersPageConfig.status",
      terminalId: "transfersPageConfig.terminalId",
    };

    return data.map((dataCell) => {
      return {
        [t(names.terminalId)]: dataCell.terminalId,
        [t(names.type)]: dataCell.type,
        [t(names.requestId)]: dataCell.requestId,
        [t(names.transferId)]: dataCell.transferId,
        [t(names.amount)]: dataCell.amount,
        [t(names.creationDate)]: dataCell.creationDate,
        [t(names.status)]: dataCell.status,
      };
    });
  };

  private readonly getTransfers = async (): Promise<void> => {
    this.state.loaded.next(false);
    try {
      const data: any[] = await this.fetchTransfers();
      const produceData = this.produceTransferData(data);
      const produceCsvData = this.getCsvData(produceData);
      this.csvData.next(produceCsvData);
      this.transfers.next(produceData);
    } catch (e) {
      console.log("e", e);
    } finally {
      this.state.loaded.next(true);
    }
  };

  private readonly applyFilter = async (): Promise<void> => {
    this.state.loaded.next(false);
    await this.getTransfers();
  };

  public readonly fetchTotalData = async (): Promise<any[]> => {
    const queries = clearQueriesField(this.filterService.state.getProduceFilterData());
    const sort = this.state.sort.value;
    const query = { page: 0, queries, size: this.state.filterNavigationService.state.totalElements.value, sort };
    const { data } = await client.post(apiPaths.transferInfo, query);
    return data.content;
  };

  public readonly getTotalData = async (): Promise<any[]> => {
    const data = await this.fetchTotalData();
    const produceData = this.produceTransferData(data);
    const csvData = this.getCsvData(produceData);
    return csvData;
  };
}

export const transfersPageService = new Service();
